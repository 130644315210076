import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ComprasService } from '../../../../services/compras.service';
import { ClientesService } from '../../../../services/clientes.service';
import { PromocionesService } from '../../../../services/promociones.service';

@Component({
	selector: 'app-compras',
	templateUrl: './compras.component.html',
	styles: []
})
export class ComprasComponent implements OnInit {

	constructor(
				private comprasService:ComprasService,
				private clientesService:ClientesService,
				private promocionesService:PromocionesService,
				private modalService: NgbModal) {
		this.cargarCompras();
	}

	ngOnInit(): void {}



	/*=================================
	=            VARIABLES            =
	=================================*/
	compras:string[] = [];
	cargandoCompras:boolean = true;

	/*========================================
	=            OBTENER DEPORTES            =
	========================================*/
	cargarCompras(){
		this.cargandoCompras = true;
		this.comprasService.getCompras().subscribe( (res:any) =>{
			this.cargandoCompras = false;
			if(!res.error){
				this.compras = res.compras
			}
		}, (err:any) => {
			this.cargandoCompras = false;
			console.error(err.error);
		})
	}



	/*=======================================
	=            AGREGAR DEPORTE            =
	=======================================*/
	modalAgregar:any;

	compraAdd = {
		'codigoCliente': "0",
		'codigoPromocion': "0",
		'fechaCompra': "",
		'montoCompra': ""
	}

	compraClass = {
		clienteClass: ['form-control'],
		promocionClass: ['form-control'],
		fechaClass: ['form-control'],
		montoClass: ['form-control']
	}

	openModalAgregar(agregarA) {
		this.cargarClientes();
		this.cargarPromociones();
		this.modalAgregar = this.modalService.open(agregarA, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {
			// console.log(`Closed with: ${result}`);
		}, (reason) => {
			// console.log(`Dismissed ${this.getDismissReason(reason)}`);
		});
	}

	cerrarModalAgregar(){
		this.modalAgregar.close('cerrar');
		this.compraAdd = {
			'codigoCliente': "0",
			'codigoPromocion': "0",
			'fechaCompra': "",
			'montoCompra': ""
		}
		this.compraClass = {
			clienteClass: ['form-control'],
			promocionClass: ['form-control'],
			fechaClass: ['form-control'],
			montoClass: ['form-control']
		}
	}

	agregarCompra(){

		let data = this.prepararData(this.compraAdd, 'add');


		let enabled_add:boolean = this.validarData(data, 'add');

		if(enabled_add){

			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.comprasService.postCompras(data).subscribe( (res:any) =>{
				Swal.close();
				if(!res.error){
					Swal.fire('Agregado', 'Compra agregada', 'success').then( () => {
						this.compraAdd = {
							'codigoCliente': "0",
							'codigoPromocion': "0",
							'fechaCompra': "",
							'montoCompra': ""
						}
						this.cerrarModalAgregar();
						this.cargarCompras();
					});
				}else{
					Swal.fire('Error', res.msg, 'error');
				}
			}, (err:any) =>{
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error');
			})
		}else{
			console.error('error en formulario')
		}
	}

	/*======================================
	=            EDITAR DEPORTE            =
	=======================================*/
	modalEditar:any;

	compraActual = {
		'codigoCompra': "",
		'codigoCliente': "0",
		'codigoPromocion': "0",
		'fechaCompra': "",
		'montoCompra': ""
	}

	compraEditar = {
		'codigoCliente': "0",
		'codigoPromocion': "0",
		'fechaCompra': "",
		'montoCompra': ""
	}
	

	openModalEditar(editarA, actual) {

		this.compraActual.codigoCompra = actual.codigoCompra;
		this.compraActual.codigoCliente = actual.codigoCliente;
		this.compraActual.codigoPromocion = actual.codigoPromocion;
		this.compraActual.fechaCompra = actual.fechaCompra;
		this.compraActual.montoCompra = actual.montoCompra;

		this.compraEditar.codigoCliente = actual.codigoCliente;
		this.compraEditar.codigoPromocion = actual.codigoPromocion;
		this.compraEditar.fechaCompra = actual.fechaCompra;
		this.compraEditar.montoCompra = actual.montoCompra;

		this.cargarClientes();
		this.cargarPromociones();

		this.modalEditar = this.modalService.open(editarA, {backdrop:'static', size: 'lg'});
		this.modalEditar.result.then((result) => {
			// console.log(`Closed with: ${result}`);
		}, (reason) => {
			// console.log(`Dismissed ${this.getDismissReason(reason)}`);
		});
	}

	cerrarModalEditar(){
		this.modalEditar.close('cerrar');
		this.compraEditar = {
			'codigoCliente': "0",
			'codigoPromocion': "0",
			'fechaCompra': "",
			'montoCompra': ""
		}
		this.compraClass = {
			clienteClass: ['form-control'],
			promocionClass: ['form-control'],
			fechaClass: ['form-control'],
			montoClass: ['form-control']
		}
	}

	actualizarCompra(){

		const data = this.prepararData(this.compraEditar, 'add');
		let enabled_act:boolean = this.validarData(data, 'act');

		if(enabled_act){

			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.comprasService.putCompras(this.compraActual.codigoCompra, data).subscribe( (res:any) => {
				Swal.close();
				if(!res.error){
					Swal.fire('Actualizado', 'Compra actualizada', 'success').then( () => {
						this.cerrarModalEditar();
						this.cargarCompras();
					});
				}else{
					Swal.fire('Error', res.msg, 'error')
				}
			}, (err:any) =>{
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error')
			})

		}else{
			console.error('error en formulario');
		}

	}

	/*========================================
	=            ELIMINAR DEPORTE            =
	========================================*/
	eliminarCompra(id){

		Swal.fire({
			title: '¿Quieres eliminar la compra?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.comprasService.deleteCompras(id).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Compra eliminada', 'success').then( () => {
							this.cargarCompras();
						})
					}else{
						Swal.fire('Error', res.msg, 'error');

					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}


	/*================================
	=            OPCIONES            =
	================================*/
	cargandoClientes:boolean = true;
	cargandoPromociones:boolean = true;
	clientes:string[] = [];
	promociones:string[] = [];

	cargarClientes(){
		this.cargandoClientes = true;
		this.clientesService.getClientes().subscribe( (res:any) => {
			this.cargandoClientes = false;
			this.clientes = res.clientes;
		}, (err:any) => {
			this.cargandoClientes = false;
			console.error(err.error);
		})
	}

	cargarPromociones(){
		this.cargandoPromociones = true;
		this.promocionesService.getPromociones().subscribe( (res:any) => {
			this.cargandoPromociones = false;
			this.promociones = res.promociones;
		}, (err:any) => {
			this.cargandoPromociones = false;
			console.error(err.error);
		})
	}
	
	
	

	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	quitarInvalidTag(tipo){

		switch (tipo) {
			case "cliente":
				this.removeItemFromArr(this.compraClass.clienteClass, 'is-invalid');
				break;
			case "promocion":
				this.removeItemFromArr(this.compraClass.promocionClass, 'is-invalid');
				break;
			case "fecha":
				this.removeItemFromArr(this.compraClass.fechaClass, 'is-invalid');
				break;
			case "monto":
				this.removeItemFromArr(this.compraClass.montoClass, 'is-invalid');
				break;
			default:
				console.warn('tipo tag no existe');
				break;
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	prepararData(data, tipo){
		let datos = data;
		if(tipo == 'add'){
			datos.codigoCliente =  datos.codigoCliente.trim();
			datos.codigoPromocion =  datos.codigoPromocion.trim();
			datos.fechaCompra =  datos.fechaCompra.trim();
			datos.montoCompra =  datos.montoCompra.trim();
		}
		return datos;
	}

	validarData(data, tipo):boolean{
		let datos = data;
		let a:number;
		let cambios:boolean = false;

		if(tipo == 'add'){
			a = 4;
			if(!datos.codigoCliente || datos.codigoCliente == '0'){
				a--;
				this.compraClass.clienteClass.push('is-invalid');
			}

			if(!datos.codigoPromocion || datos.codigoPromocion == '0'){
				a--;
				this.compraClass.promocionClass.push('is-invalid');
			}

			if(!datos.montoCompra || datos.montoCompra == '0'){
				a--;
				this.compraClass.montoClass.push('is-invalid');
			}

			if(!datos.fechaCompra){
				a--;
				this.compraClass.fechaClass.push('is-invalid');
			}

			if(a==4){
				return true;
			}else{
				return false;
			}
		}

		if(tipo == 'act'){
			a = 4;
			if(!datos.codigoCliente || datos.codigoCliente == '0'){
				a--;
				this.compraClass.clienteClass.push('is-invalid');
			}

			if(!datos.codigoPromocion || datos.codigoPromocion == '0'){
				a--;
				this.compraClass.promocionClass.push('is-invalid');
			}

			if(!datos.montoCompra || datos.montoCompra == '0'){
				a--;
				this.compraClass.montoClass.push('is-invalid');
			}

			if(!datos.fechaCompra){
				a--;
				this.compraClass.fechaClass.push('is-invalid');
			}

			if(
				datos.codigoCliente != this.compraActual.codigoCliente ||
				datos.codigoPromocion != this.compraActual.codigoPromocion ||
				datos.montoCompra != this.compraActual.montoCompra ||
				datos.fechaCompra != this.compraActual.fechaCompra
				){
				cambios = true;
			}else{
				cambios = false;
				Swal.fire('Alerta', 'No hay cambios para realizar', 'info');
			}

			if(a==4 && cambios){
				return true;
			}else{
				return false;
			}
		}

	}

}
