<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<h3>LIGAS</h3>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-app bg-success ml-0" (click)="openModalAgregar(agregar)">
				<i class="fas fa-plus"></i>
				Agregar
			</button>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">

			<div class="card card-dark">
				<div class="card-header">
					<h3>Listado de ligas</h3>
				</div>


				<div class="card-body" *ngIf="cargandoLigas">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>


				<div class="card-body table-responsive" *ngIf="!cargandoLigas">
          <div *ngIf="ligas.length == 0" class="alert"><i class="fas fa-exclamation-triangle"></i>No hay información para mostrar!</div>
					<table *ngIf="ligas.length > 0" class="table table-striped">
						<thead>
							<tr class="bg-secondary">
								<th>#</th>
								<th>LIGA</th>
								<!-- <th>ASOCIACIÓN</th> -->
								<th>OPCIONES</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let a of ligas; let i = index">
								<th>{{i + 1}}</th>
								<th>{{a.nombreLiga}}</th>
								<!-- <th>{{a.nombreAsociacion}}</th> -->
								<th>
									<button class="btn btn-primary btn-sm mr-2" title="editar" (click)="openModalEditar(editar, a)"><i class="fas fa-edit"></i></button>
									<button class="btn btn-danger btn-sm mr-2" title="eliminar" (click)="eliminarLiga(a.codigoLiga)"><i class="fas fa-times"></i></button>
								</th>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-footer"></div>
			</div>

		</div>
	</div>
</div>





<!--============================
=            MODALS            =
=============================-->
<ng-template #agregar let-modalAgregar>
   <div class="modal-header bg-dark">
      <h5 class="modal-title" id="agregarPromocion_modalLabel">AGREGAR LIGA</h5>
      <button type="button" class="close" (click)="cerrarModalAgregar()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Nombre liga *  <small>(Max 50 caracteres)</small></label>
            <input type="text" [(ngModel)]="ligaAdd.nombre" [ngClass]='nombreClass' placeholder="Nombre" maxlength="50" (keyup)="quitarInvalidTag('nombre')">
          </div>
        </div>

        <!-- <div class="col-md-12">
          <div class="form-group">
            <label>Asociación *</label>
            <select [ngClass]='asociacionClass' style="width: 100%;" [(ngModel)]="ligaAdd.asociacion" (change)="quitarInvalidTag('asociacion')">
              <option selected value="0">-- Selecciona una asociacion --</option>
              <option *ngFor="let a of asociaciones" [value]="a.codigoAsociacion">{{a.nombreAsociacion}}</option>
            </select>
          </div>
        </div> -->


      </div>     

    </div>


    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalAgregar()">Cerrar</button>
      <button type="button" class="btn btn-success" (click)="agregarLiga()"><i class="fas fa-save"></i> Guardar</button>
    </div>

</ng-template>



<ng-template #editar let-modalEditar>

   <div class="modal-header bg-dark">
      <h5 class="modal-title" id="agregarPromocion_modalLabel">EDITAR LIGA</h5>
      <button type="button" class="close" (click)="cerrarModalEditar()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Nombre liga * <small>(Max 50 caracteres)</small></label>
            <input type="text" [(ngModel)]="ligaEditar.nombre" [ngClass]='nombreClass' placeholder="Nombre" maxlength="50"  (keyup)="quitarInvalidTag('nombre')">
          </div>
        </div>
        <!-- <div class="col-md-12">
          <div class="form-group">
            <label>Asociación *</label>
            <select [ngClass]='asociacionClass' style="width: 100%;" [(ngModel)]="ligaEditar.asociacion" (change)="quitarInvalidTag('asociacion')">
              <option *ngFor="let a of asociaciones" [value]="a.codigoAsociacion">{{a.nombreAsociacion}}</option>
            </select>
          </div>
        </div> -->
      </div>     
    </div>
    
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalEditar()">Cerrar</button>
      <button type="button" class="btn btn-primary" (click)="actualizarLiga()"><i class="fas fa-edit"></i> Actualizar</button>
    </div>

</ng-template>



