import { Component } from '@angular/core';

@Component({
	selector: 'app-categorias',
	template: '<router-outlet></router-outlet>',
	styles: []
})
export class CategoriasComponent {

	constructor() {
	}

}
