<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <h2><small>DETALLES CLIENTE</small></h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <button class="btn btn-primary" [routerLink]="['/main/clientes']"><i class="fas fa-long-arrow-alt-left"></i> Regresar</button>
    </div>
    <div class="col-md-4">
      
    </div>
    <div class="col-md-4">
      <button class="btn bg-danger btn-app float-right" (click)="eliminarCliente()"><i class="fas fa-times"></i> Eliminar</button>
      <button class="btn bg-primary btn-app float-right" (click)="openModalNotificaciones(notificaciones)"><i class="fas fa-paper-plane"></i> Notificación</button>
    </div>
  </div>

  <div class="row" *ngIf="!cargandoCliente && clienteEditar.estadoCliente != '1'">
    <div class="col-md-12">
      <div class="alert alert-warning"><i class="fas fa-exclamation-triangle"></i> El cliente está marcado como <b>inactivo</b> y no podrá iniciar sesión en la app móvil!</div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="callout callout-info">
        <h5>Información!</h5>
        <p>Los campos marcados con <b>*</b> son obligatorios.</p>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12">
      <div class="card card-dark">
        <div class="card-header">DATOS GENERALES</div>

        <div class="card-body" *ngIf="cargandoCliente">
          <div class="text-center">
                <br> <br>
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <br><br>
            </div>
        </div>


        <div class="card-body" *ngIf="!cargandoCliente">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>ID * </label>
                <input type="text" [value]="idcliente" [ngClass]='classEdit.nombreClass' placeholder="ID" disabled>
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col-md-6">
              <div class="form-group">
                <label>Estado *</label>
                <select [ngClass]='classEdit.estadoClass' style="width: 100%;" [(ngModel)]="clienteEditar.estadoCliente">
                  <option selected value="1"> Activo </option>
                  <option selected value="0"> Inactivo </option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Membresía *</label>
                <div *ngIf="cargandoMembresias" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <select *ngIf="!cargandoMembresias" [ngClass]='classEdit.membresiaClass' style="width: 100%;" [(ngModel)]="clienteEditar.codigoMembresia" (change)="quitarInvalidTag('membresia')">
                   <option selected value="0">-- Selecciona una membresía --</option>
                  <option *ngFor="let a of membresias" [value]="a.codigoMembresia">{{a.nombreMembresia}}</option>
                  <hr>
                  <option *ngFor="let a of membresiasDisabled" disabled [value]="a.codigoMembresia">{{a.nombreMembresia}}</option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Sede *</label>
                <div *ngIf="cargandoSedes" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <select *ngIf="!cargandoSedes" [ngClass]='classEdit.sedeClass' style="width: 100%;" [(ngModel)]="clienteEditar.codigoSede" (change)="quitarInvalidTag('sede')">
                  <option selected value="0">-- Selecciona una sede --</option>
                  <option *ngFor="let a of sedes" [value]="a.codigoSede">{{a.nombreSede}}</option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Deporte *</label>
                <div *ngIf="cargandoDeportes" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <select *ngIf="!cargandoDeportes" [ngClass]='classEdit.deporteClass' style="width: 100%;" [(ngModel)]="clienteEditar.codigoDeporte" (change)="quitarInvalidTag('deporte')">
                  <option selected value="0">-- Selecciona un deporte --</option>
                  <option *ngFor="let a of deportes" [value]="a.codigoDeporte">{{a.nombreDeporte}}</option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Institución *</label>
                <div *ngIf="cargandoInstituciones" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <select *ngIf="!cargandoInstituciones" [ngClass]='classEdit.institucionClass' style="width: 100%;" [(ngModel)]="clienteEditar.codigoInstitucion" (change)="quitarInvalidTag('institucion')">
                  <option selected value="0">-- Selecciona una institución --</option>
                  <option *ngFor="let a of instituciones" [value]="a.codigoInstitucion">{{a.nombreInstitucion}}</option>
                </select>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Liga *</label>
                <div *ngIf="cargandoLigas" class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <select *ngIf="!cargandoLigas" [ngClass]='classEdit.ligaClass' style="width: 100%;" [(ngModel)]="clienteEditar.codigoLiga" (change)="quitarInvalidTag('liga')">
                  <option selected value="0">-- Selecciona una liga --</option>
                  <option *ngFor="let a of ligas" [value]="a.codigoLiga">{{a.nombreLiga}}</option>
                </select>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label>Nombres *  <small>(Max 50 caracteres)</small></label>
                <input type="text" [(ngModel)]="clienteEditar.nombresCliente" [ngClass]='classEdit.nombreClass' placeholder="Nombres" maxlength="50" (keyup)="quitarInvalidTag('nombre')">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Apellidos *  <small>(Max 50 caracteres)</small></label>
                <input type="text" [(ngModel)]="clienteEditar.apellidosCliente" [ngClass]='classEdit.apellidoClass' placeholder="Apellidos" maxlength="50" (keyup)="quitarInvalidTag('apellido')">
              </div>
            </div>

            <div class="col-md-8">
              <div class="form-group">
                <label>Email *  <small>(Max 50 caracteres)</small></label>
                <input type="email" [(ngModel)]="clienteEditar.emailCliente" [ngClass]='classEdit.emailClass' placeholder="Email" maxlength="50" (keyup)="quitarInvalidTag('email')">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Password *  <small>(5 caracteres)</small></label>
                <input type="text" [(ngModel)]="clienteEditar.passwordCliente" [ngClass]='classEdit.passClass' placeholder="Password" maxlength="5" (keyup)="quitarInvalidTag('password')">
              </div>
            </div>

           
     
          </div>  
          
        </div>
        <div class="card-footer">
          <button class="btn btn-primary float-right" (click)="actualizarCliente()">Actualizar</button>
        </div>
      </div>
    </div>




    <div class="col-md-6">
      <div class="card">
        <div class="card-header bg-dark">
          DATOS MULTIMEDIA
        </div>
        <div class="card-body" *ngIf="cargandoCliente">
          <div class="text-center">
            <br><br>
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <br><br>
          </div>
        </div>
        <div class="card-body" *ngIf="!cargandoCliente">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <input class="custom-file-input" #file id="file" type="file" accept='image/*' (change)="handleFileInput(file.files)" />
                <label class="custom-file-label" for="file">Choose file</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label for="">Selección</label>
                <img [src]="imgURL" width="100%" *ngIf="imgURL">
              </div>
            <div class="col-md-6">
              <label for="">Imagen actual</label>
              <img *ngIf="multimediaChanged.imagen" [src]="multimediaChanged.imagen" alt="" width="100%">
              <img *ngIf="!multimediaChanged.imagen" src="assets/img/noimage.jpg" alt="" width="100%">
            </div>
          </div>
        </div>
        <div class="card-footer" *ngIf="!cargandoCliente">
          <button class="btn btn-primary float-right" (click)="uploadImagen()">Actualizar</button>
        </div>
      </div>
    </div>



  </div>

</div>


<!-- MODAL -->

<ng-template #notificaciones let-notificaciones>

   <div class="modal-header bg-dark">
      <h5 class="modal-title">ENVIAR NOTIFICACIÓN CLIENTES</h5>
      <button type="button" class="close" (click)="cerrarModalNotificaciones()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>


    <div class="modal-body">

      <div class="row">

        <div class="col-md-12">
          <div class="form-group">
            <label>Título *  <small>(Max 30 caracteres)</small></label>
            <input type="text" [(ngModel)]="notificacion.subtitulo" [ngClass]='notificacionClass.subtitulo' placeholder="Contenido" maxlength="50" (keyup)="quitarInvalidTag('titulonoti')">
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>contenido *  <small>(Max 200 caracteres)</small></label>
            <textarea [(ngModel)]="notificacion.contenido" [ngClass]='notificacionClass.contenido' placeholder="Contenido" maxlength="200" rows="4" (keyup)="quitarInvalidTag('contenidonoti')"></textarea>
          </div>
        </div>
 
      </div>     

    </div>


    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalNotificaciones()">Cerrar</button>
      <button type="button" class="btn btn-success" (click)="enviarNotificacion()"><i class="fas fa-paper-plane"></i> Enviar</button>
    </div>
</ng-template>