<div class="continer-fluid">
    <div class="row">
        <div class="col-md-12">
            <h2>NOTICIAS</h2>
        </div>
    </div>
    <button type="button" class="btn btn-app bg-success ml-0" (click)="openModal(content)">
        <i class="fas fa-plus"></i>Agregar
    </button>
    <button type="button" class="btn btn-app bg-warning float-right" (click)="deshabilitarPromociones()" title="Validar promociones por fechas">
        <i class="fas fa-ban"></i> Validar
    </button>
    <br>
    <br>


    <div class="card">

        <div class="card-header bg-dark"><h5 class="card-title bg-dark">LISTADO DE NOTICIAS</h5></div>

        <div class="card-body table-responsive">
            <div *ngIf="cargando" class="text-center">
                <br><br>
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <br><br>
            </div>

            <div>

                <div *ngIf="!cargando" class="text-center">

                    <div class="alert text-left" *ngIf="promociones.length == 0"> <i class="fas fa-exclamation-triangle"></i> No hay información para mostrar.</div>


                    <div *ngIf="promociones.length > 0">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-secondary" (click)="filtroPromociones('todas')">Todas</button>
                            <button type="button" class="btn btn-secondary" (click)="filtroPromociones('pendientes')">Pendientes</button>
                            <button type="button" class="btn btn-secondary" (click)="filtroPromociones('publicadas')">Publicadas</button>
                            <button type="button" class="btn btn-secondary" (click)="filtroPromociones('vencidas')">Vencidas</button>
                        </div>

                        <div class="text-left mt-4">
                            <h3>{{filterName}}</h3>
                        </div>


                        
                        <table class="table table-striped mt-2" *ngIf="promociones.length > 0">
                            <thead>
                                <tr class="bg-secondary">
                                    <th>#</th>
                                    <th>NOTICIA</th>
                                    <th>SOCIO</th>
                                    <th>SEDE</th>
                                    <th>CATEGORÍA</th>
                                    <th>INICIO</th>
                                    <th>FIN</th>
                                    <th>ESTADO</th>
                                    <th>DESHABILITADO</th>
                                    <th>OPCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let p of promociones_filter, let i = index">
                                    <td>{{i + 1}}</td>
                                    <td>{{p.promocion}}</td>
                                    <td>{{p.proveedor}}</td>
                                    <td>{{p.sede}}</td>
                                    <td>{{p.categoria}}</td>
                                    <td>{{p.finicio | date:'shortDate'}}</td>
                                    <td>{{p.ffin | date:'shortDate'}}</td>
                                    <td class="text-center">
                                        <i *ngIf="p.estado == 1"class="fas fa-circle text-success"></i>
                                        <i *ngIf="p.estado != 1"class="fas fa-circle text-danger"></i>
                                    </td>
                                    <td class="text-center">
                                        <i *ngIf="p.deshabilitado == 1"class="fas fa-circle text-success"></i>
                                        <i *ngIf="p.deshabilitado != 1"class="fas fa-circle text-danger"></i>
                                    </td>
                                    <td><button class="btn btn-primary btn-sm" [routerLink]="[p.id]">Ver</button></td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>

                   



                </div>
            </div>
        </div>
    </div>
</div>



<!--============================
=            MODALS            =
=============================-->

<ng-template #content let-modal>
   <div class="modal-header bg-dark">
      <h5 class="modal-title" id="agregarPromocion_modalLabel">AGREGAR PROMOCIÓN</h5>
      <button type="button" class="close" (click)="cerrarModal()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>
    <div class="modal-body"  *ngIf="cargandoProveedores || cargandoProductos">
      <div class="text-center">
        <br>
        <br>
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <br>
        <br>
      </div>
    </div>
    <div class="modal-body"  *ngIf="!cargandoProveedores && !cargandoProductos">

      <div class="row">
        <div class="col-md-12">
          <div class="callout callout-info">
            <h5>Información!</h5>
            <p>Los campos marcados con <b>*</b> son obligatorios.</p>
          </div>
        </div>
      </div>



      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Nombre noticia *</label>
            <input type="text" [(ngModel)]="promocionN.nombre" [ngClass]='nombreClass' placeholder="Nombre" maxlength="50" (change)="validarProveedor('nombre', promocionN.nombre, 'maxlength:50,minlength:1')">
            <div [ngClass]='alertClass'>{{alertName}}</div>
          </div>
        </div>
      </div>     
      <div class="row">
        <div class="col-md-6">
           <div class="form-group">
            <label>Socio *</label>
            <select [ngClass]='proveedorClass' style="width: 100%;" [(ngModel)]="promocionN.proveedor" (change)="obtenerSedesProveedor_agregarPromocion(promocionN.proveedor)">
              <option selected value="0">-- Selecciona un socio --</option>
              <option *ngFor="let p of proveedores" [value]="p.idproveedor">{{p.proveedor}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Producto *</label>
            <select [ngClass]='productoClass' style="width: 100%;" [(ngModel)]="promocionN.producto" (change)="validarProveedor('producto', promocionN.producto, 'isint')">
              <option selected="selected" value="0">-- Selecciona un producto --</option>
              <option *ngFor="let p of productos" [value]="p.codigoProducto">{{p.nombreProducto}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Sede *</label>
            <br>
            <div class="spinner-grow spinner-grow-sm " role="status" *ngIf="cargandoSedesProveedores">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm" role="status" *ngIf="cargandoSedesProveedores">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm" role="status" *ngIf="cargandoSedesProveedores">
              <span class="sr-only">Loading...</span>
            </div>
            <select [ngClass]='sedeClass' style="width: 100%;" [(ngModel)]="promocionN.sede" *ngIf="!cargandoSedesProveedores" (change)="validarProveedor('sede', promocionN.sede, 'isint')">
              <option value="0">-- Selecciona una sede del proveedor --</option>
              <option *ngFor="let s of sedesProveedor" [value]="s.idsede">{{s.sede}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" *ngIf="!cargandoProveedores && !cargandoProductos">
      <button type="button" class="btn btn-secondary" (click)="cerrarModal()">Cerrar</button>
      <button type="button" class="btn btn-success" (click)="guardarProveedor()"><i class="fas fa-save"></i> Guardar</button>
    </div>
</ng-template>




