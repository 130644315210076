<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<h3>CARACTERÍSTICAS</h3>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-app bg-success ml-0" (click)="openModalAgregar(agregar)">
				<i class="fas fa-plus"></i>
				Agregar
			</button>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">

			<div class="card card-dark">
				<div class="card-header">
					<h3>Listado de características</h3>
				</div>


				<div class="card-body" *ngIf="cargandoFiltros">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>


				<div class="card-body table-responsive" *ngIf="!cargandoFiltros">
					<div *ngIf="filtros.length == 0" class="alert"><i class="fas fa-exclamation-triangle"></i> No hay información para mostrar!</div>
					<table *ngIf="filtros.length > 0" class="table table-striped">
						<thead>
							<tr class="bg-secondary">
								<th>#</th>
								<th>CARACTERÍSTICA</th>
								<th>OPCIONES</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let a of filtros; let i = index">
								<th>{{i + 1}}</th>
								<th>{{a.nombreFiltro}}</th>
								<th>
									<button class="btn btn-primary btn-sm mr-2" title="editar" (click)="openModalEditar(editar, a)"><i class="fas fa-edit"></i></button>
									<button class="btn btn-danger btn-sm mr-2" title="eliminar" (click)="eliminarFiltro(a.codigoFiltro)"><i class="fas fa-times"></i></button>
								</th>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-footer"></div>
			</div>

		</div>
	</div>

</div>




<!--============================
=            MODALS            =
=============================-->
<ng-template #agregar let-modalAgregar>

   <div class="modal-header bg-dark">
      <h5 class="modal-title">AGREGAR CARACTERÍSTICA</h5>
      <button type="button" class="close" (click)="cerrarModalAgregar()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>


    <div class="modal-body">

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Nombre característica *  <small>(Max 100 caracteres)</small></label>
            <input type="text" [(ngModel)]="nombreAdd" [ngClass]='nombreClass' placeholder="Nombre" maxlength="100" (keyup)="quitarInvalidName()">
          </div>
        </div>
      </div>     

    </div>


    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalAgregar()">Cerrar</button>
      <button type="button" class="btn btn-success" (click)="agregarFiltro()"><i class="fas fa-save"></i> Guardar</button>
    </div>
</ng-template>

<ng-template #editar let-modalEditar>

   <div class="modal-header bg-dark">
      <h5 class="modal-title">EDITAR CARACTERÍSTICA</h5>
      <button type="button" class="close" (click)="cerrarModalEditar()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>


    <div class="modal-body">

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Nombre característica * <small>(Max 100 caracteres)</small></label>
            <input type="text" [(ngModel)]="filtroEditar.nombreFiltro" [ngClass]='nombreClass' placeholder="Nombre" maxlength="100"  (keyup)="quitarInvalidName()">
          </div>
        </div>
      </div>     

    </div>


    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalEditar()">Cerrar</button>
      <button type="button" class="btn btn-primary" (click)="actualizarFiltro()"><i class="fas fa-edit"></i> Actualizar</button>
    </div>
</ng-template>



