import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {NgbDateStruct, NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

/*services*/
import { PromocionesService } from '../../../../services/promociones.service';
import { ProductosService } from '../../../../services/productos.service';
import { ProveedoresService } from '../../../../services/proveedores.service';
import { ValidacionesService } from '../../../../services/validaciones.service';
import { NotificacionesService } from '../../../../services/notificaciones.service';


/*interfaces*/
import { Promocion, Promociones, PromocionNueva, PromocionClass } from '../../../../models/promocion.interface';




@Component({
	selector: 'app-promocion',
	templateUrl: './promocion.component.html',
	styles: [
	]
})
export class PromocionComponent implements OnInit {

	/*----------  variables de carga de opciones  ----------*/
	cargandoProductos:boolean = true;
	cargandoProveedores:boolean = true;
	cargandoSedes:boolean = true;
	cargandoPromocionG:boolean = true;
	cargandoPromocionD:boolean = true;
	cargandoPromocionM:boolean = true;
	

	proveedores = [];
	proveedoresDisabled = [];
	sedes = [];
	productos = [];
	idpromocion:string = "";
	deshabilitado:string = "";

	otro:Promocion = {
		idpromocion: null,
		promocion: null,
		idproveedor: null,
		idsede: null,
		codigoProducto: null,
		estado: null,
		categoria: null,
		fcreacion:null
	};

	promocionChanged:Promocion = {
		idpromocion: null,
		promocion: null,
		idproveedor: null,
		idsede: null,
		codigoProducto: null,
		estado: null,
		categoria: null,
		fcreacion:null,
		tipo: 'general'
	};

	detallesActuales = {
		finicio: null,
		ffin: null,
		detalles: null
	};
	detallesChanged = {
		finicio: null,
		ffin: null,
		detalles: null,
		tipo: 'detalle'
	};

	multimediaChanged = {
		imagen: null
	};

	fileToUpload: File = null;




	/*----------  variables para clases  ----------*/
	classNombre:string[] = ['form-control'];
	classId:string[] = ['form-control'];
	classProducto:string[] = ['form-control'];
	classProveedor:string[] = ['form-control'];
	classSede:string[] = ['form-control'];
	classFechaf:string[] = ['form-control'];
	classFechai:string[] = ['form-control'];

	constructor(private rutaActiva: ActivatedRoute,
				private router: Router,
				private promocionesService: PromocionesService,
				private proveedoresService: ProveedoresService,
				private productossService: ProductosService,
				private notificacionesService: NotificacionesService,
				private vService:ValidacionesService) {

				this.cargarProductos();
				this.cargarProveedores();

	}

	ngOnInit(): void {
		this.idpromocion = this.rutaActiva.snapshot.params.id;

		if( parseInt(this.idpromocion) % 1 != 0){
			this.router.navigateByUrl('main/promociones');
		}
		this.promocionChanged.idpromocion = this.rutaActiva.snapshot.params.id;

		/*obtener datos GENERALES de promociones*/
		this.cargarPromociones_general();
		/*obtener datos DETALLES de promociones*/
		this.cargarPromociones_detalle();
		/*obtener datos MULTIMEDIA de promociones*/
		this.cargarPromociones_multimedia();
	}

	



	/*============================================
	=            ACTUALIAR PROVEEEDOR            =
	============================================*/
	actualizarProveedorG(){
		this.enabled_act = false;

		Swal.fire({allowOutsideClick: false});
		Swal.showLoading();

		if(this.val_nombre && this.val_proveedor && this.val_sede && this.val_producto){
			if(
				this.promocionChanged.idpromocion != this.otro.idpromocion || 
				this.promocionChanged.promocion != this.otro.promocion || 
				this.promocionChanged.idproveedor != this.otro.idproveedor || 
				this.promocionChanged.idsede != this.otro.idsede || 
				this.promocionChanged.codigoProducto != this.otro.codigoProducto || 
				this.promocionChanged.estado != this.otro.estado
				){
				this.enabled_act = true;
			}else{
				this.enabled_act = false;
				Swal.close();
				Swal.fire('Error', 'No hay cambios para realizar', 'info');
			}
		}else{
			Swal.close();
			Swal.fire('Error', 'Hay campos erróneos', 'error');
		}
		
		if(this.enabled_act){
			this.promocionChanged.tipo = 'general';
			this.promocionesService.putPromocion(this.promocionChanged, this.promocionChanged.idpromocion).subscribe( (res:any) =>{
				Swal.close();
				if(!res.error){
					Swal.fire('Actaulizado', 'Cambios realizados', 'success').then( res =>{
						this.cargarPromociones_general();
					} );
				}else{
					Swal.fire('Error', 'No se realizaron los cambios', 'error');
				}
			}, (err:any) =>{
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg , 'error');
			})

		}else{
			this.mostrarErroresProveedorNuevo();
		}
	}
	actualizarProveedorD(){
		this.enabled_act = false;
		Swal.fire({allowOutsideClick: false});
		Swal.showLoading();

		if(this.val_ffin && this.val_finicio && this.val_detalle){
			if(
				this.detallesChanged.finicio != this.detallesActuales.finicio || 
				this.detallesChanged.ffin != this.detallesActuales.ffin || 
				this.detallesChanged.detalles != this.detallesActuales.detalles
				){
				this.enabled_act_d = true;
			}else{
				this.enabled_act_d = false;
				Swal.close();
				Swal.fire('Error', 'No hay cambios para realizar', 'info');
			}
		}else{
			Swal.close();
			Swal.fire('Error', 'Hay campos erróneos', 'error');
		}
		
		if(this.enabled_act_d){
			Swal.close();
			this.detallesChanged.tipo = 'detalle';
			this.promocionesService.putPromocion(this.detallesChanged, this.promocionChanged.idpromocion).subscribe( (res:any) =>{
				Swal.close();
				if(!res.error){
					Swal.fire('Actualizado', 'Actualizado', 'success').then( () =>{
						this.cargarPromociones_detalle();
					});
				}else{
					Swal.fire('Error', 'No se actualizó', 'error');
				}
			}, (err:any) =>{
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error');
			})

		}else{
			this.mostrarErroresProveedorNuevo();
		}
	}

	public imagePath;
  	imgURL: any;
  	public message: string;

	handleFileInput(files: FileList) {
		/*verificar si hay un archivo*/
		if (files.length === 0)
			return;
		/*validar que sea imagen*/
		var mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.message = "Only images are supported.";
			return;
		}
		var reader = new FileReader();
		// this.imagePath = files;
		reader.readAsDataURL(files[0]); 
		reader.onload = (_event) => { 
			this.imgURL = reader.result; 
		}
		this.fileToUpload = files[0];
	}

	uploadImagen() {

		if(this.fileToUpload){
			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();

			this.promocionesService.putImagen(this.fileToUpload, this.promocionChanged.idpromocion, this.multimediaChanged.imagen).subscribe( (res:any) => {
				if(!res.error){
					Swal.close();
					Swal.fire('Actualizado', 'La imagen se actualizó', 'success').then(()=>{
						this.imgURL = "";
						this.fileToUpload = null;
						this.cargarPromociones_multimedia();
					})
				}else{
					Swal.close();
					Swal.fire('Error', 'No se actualizó', 'error');
				}
			}, err => {
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error');
				
			});
		}else{
			console.error('no hay archivo');
		}
	}

	publicarPromocion(){
		const datos = {
			tipo: 'publicar'
		}

		Swal.fire({
			title: '¿Quieres publicar la noticia?',
			text: "La noticia ya no podrá ser editada!",
			icon: 'question',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-success',
				cancelButton: 'bg-danger'
			},
			confirmButtonText: 'Publicar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.promocionesService.publicarPromocion(this.idpromocion, datos).subscribe( (res:any) =>{
					Swal.close();
					if(!res.error){
						Swal.fire('Actualizado', 'Promocion publicada', 'success').then( () =>{


							this.otro.estado = 1;

							Swal.fire({
								allowOutsideClick: false,
								text: 'Enviando notificación!',
							})
							Swal.showLoading();


							const data = {
								"titulo": 'Privato', 
								"subtitulo": 'Nueva noticia',
								"contenido": this.otro.promocion,
								"path": 'promociones/' + this.idpromocion
							}

							this.notificacionesService.sendNotification(data).subscribe( (res:any) => {
								Swal.close();
								Swal.fire('Enviado', 'Notificación enviada a: <b>' + res.recipients + ' cliente(s)</b>', 'success');
							}, (err:any) => {
								Swal.close();
								console.error(err);
								Swal.fire('Alerta', 'La noticia se publicó, pero no se pudo enviar la notificación', 'info');
							})


						});
					}else{
						Swal.fire('Error', 'No se publicó', 'error');
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err.error);
					Swal.fire('Error', err.error.msg, 'error');
				})
			}
		})

	}

	deshabilitarPromocion(){
		const datos = {
			tipo: 'deshabilitar'
		}

		Swal.fire({
			title: '¿Quieres deshabilitar la promoción?',
			text: "La promoción ya no aparecerá en la app móvil!",
			icon: 'question',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-success',
				cancelButton: 'bg-danger'
			},
			confirmButtonText: 'Deshabilitar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.promocionesService.publicarPromocion(this.idpromocion, datos).subscribe( (res:any) =>{
					Swal.close();
					if(!res.error){
						Swal.fire('Actualizado', 'Promocion deshabilitada', 'success').then( () =>{
							this.deshabilitado = '1';
						});
					}else{
						Swal.fire('Error', 'No se deshabilitó', 'error');
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err.error);
					Swal.fire('Error', err.error.msg, 'error');
				})
			}
		})

	}

	eliminarPromocion(){
		const datos = {
			tipo: 'eliminar'
		}

		Swal.fire({
			title: '¿Quieres eliminar la promoción?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.promocionesService.deletePromocion(this.idpromocion).subscribe( (res:any) => {
					Swal.close();

					if(!res.error){
						Swal.fire('Eliminado', 'Promoción eliminada correctamente', 'success').then(()=>{
							this.router.navigateByUrl('/main/noticias');
						});
					}else{
						Swal.fire('Error', res.msg, 'error');
					}





				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})
			}
		})

	}



	/*====================================
	=            VALIDACIONES            =
	====================================*/
	validacion:any = {};
	enabled_act:boolean = false;
	enabled_act_d:boolean = false;
	val_nombre:boolean = true;
	val_proveedor:boolean = true;
	val_producto:boolean = true;
	val_sede:boolean = true;
	val_ffin:boolean = true;
	val_finicio:boolean = true;
	val_detalle:boolean = true;


	validarProveedor(tipo, valor, rules){
		this.validacion = {};

		valor = valor.trim();
		switch (tipo) {
			case "nombre":
				this.val_nombre = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.promocionChanged.promocion = valor;
					this.val_nombre = this.validacion.response;
					this.removeItemFromArr(this.classNombre, 'is-invalid');
				}else{
					this.val_nombre = this.validacion.response;
				}
				break;
			case "proveedor":
				this.val_proveedor = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.promocionChanged.idproveedor = valor;
					this.val_proveedor = this.validacion.response;
					this.removeItemFromArr(this.classProveedor, 'is-invalid');
				}else{
					this.val_proveedor = this.validacion.response;
				}
				break;
			case "producto":
				this.val_producto = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.promocionChanged.codigoProducto = valor;
					this.val_producto = this.validacion.response;
					this.removeItemFromArr(this.classProducto, 'is-invalid');
				}else{
					this.val_producto = this.validacion.response;
				}
				break;
			case "sede":
				this.val_sede = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.promocionChanged.idsede = valor;
					this.val_sede = this.validacion.response;
					this.removeItemFromArr(this.classSede, 'is-invalid');
				}else{
					this.val_sede = this.validacion.response;
				}
				break;
			case "fechaf":
				this.val_ffin = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.detallesChanged.ffin = valor;
					this.val_ffin = this.validacion.response;
					this.removeItemFromArr(this.classFechaf, 'is-invalid');
				}else{
					this.val_ffin = this.validacion.response;
				}
				break;
			case "fechai":
				this.val_finicio = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.detallesChanged.finicio = valor;
					this.val_finicio = this.validacion.response;
					this.removeItemFromArr(this.classFechai, 'is-invalid');
				}else{
					this.val_finicio = this.validacion.response;
				}
				break;
			case "detalle":
				this.val_detalle = true;
				break;
			
			default:
				console.error('Tipo no existe');
				break;
		}
	}

	mostrarErroresProveedorNuevo(){

		if(!this.val_nombre){
			this.classNombre.push('is-invalid')
		}
		if(!this.val_proveedor){
			this.classProveedor.push('is-invalid')
		}
		if(!this.val_sede){
			this.classSede.push('is-invalid')
		}
		if(!this.val_producto){
			this.classProducto.push('is-invalid')
		}

		if(!this.val_ffin){
			this.classFechaf.push('is-invalid')
		}

		if(!this.val_finicio){
			this.classFechai.push('is-invalid')
		}
	}
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}



	/*=====================================================
	=            CARGAR DAATOS DE LA PROMOCIÓN            =
	=====================================================*/
	cargarPromociones_general(){
		/*obtener datos GENERALES de promociones*/
		this.cargandoPromocionG = true;
		this.promocionesService.getPromociones(this.idpromocion).subscribe( (res:any) =>{
			this.cargandoPromocionG = false;
			this.otro.idpromocion = res.promocion.idpromocion;
			this.otro.promocion = res.promocion.promocion;
			this.otro.idproveedor = res.promocion.idproveedor;
			this.otro.idsede = res.promocion.idsede;
			this.otro.codigoProducto = res.promocion.idproducto;
			this.otro.estado = res.promocion.estado;
			this.otro.categoria = res.promocion.categoria;
			this.otro.fcreacion = res.promocion.fcreacion;
			this.deshabilitado = res.promocion.deshabilitado;

			this.promocionChanged.idpromocion = res.promocion.idpromocion;
			this.promocionChanged.promocion = res.promocion.promocion;
			this.promocionChanged.idproveedor = res.promocion.idproveedor;
			this.promocionChanged.idsede = res.promocion.idsede;
			this.promocionChanged.codigoProducto = res.promocion.idproducto;
			this.promocionChanged.estado = res.promocion.estado;
			this.promocionChanged.categoria = res.promocion.categoria;
			this.promocionChanged.fcreacion = res.promocion.fcreacion;

			// this.promocionChanged = res.promocion;
			/*ya se obtuvo el proveedor de la promoción actual, obtener las sedes*/
			this.obtenerSedesProveedor(this.promocionChanged.idproveedor);
		}, err => {
			this.cargandoPromocionG = false;
			console.error(err);
			if(err.error.msg == 'NOT_CONTENT'){
				this.router.navigateByUrl('main/configuraciones/categorias');
			}
		});
	}

	cargarPromociones_detalle(){
		this.cargandoPromocionD = true;
		this.promocionesService.getPromociones(this.idpromocion, 'detalles').subscribe( (res:any) =>{
			this.cargandoPromocionD = false;
			this.detallesChanged = res.promocion;
			this.detallesActuales.finicio = res.promocion.finicio;
			this.detallesActuales.ffin = res.promocion.ffin;
			this.detallesActuales.detalles = res.promocion.detalles;
		}, err => {
			this.cargandoPromocionD = false;
			console.error(err);
		});
	}

	cargarPromociones_multimedia(){
		this.cargandoPromocionM = true;
		this.promocionesService.getPromociones(this.idpromocion, 'multimedia').subscribe( (res:any) =>{
			this.cargandoPromocionM = false;
			this.multimediaChanged = res.promocion;
		}, err => {
			this.cargandoPromocionM = false;
			console.error(err);
		})
	}

	/*=======================================
	=            CARGAR OPCIONES            =
	=======================================*/
	cargarProductos(){
		this.cargandoProductos = true;
		this.productossService.getProductos().subscribe( (res:any) =>{
			this.cargandoProductos = false;
			this.productos = res.productos;
		}, err =>{
			this.cargandoProductos = false;
			console.error(err);
		})
	}

	cargarProveedores(){
		this.cargandoProveedores = true;
		this.proveedoresService.getProveedores().subscribe( (res:any) =>{
			this.cargandoProveedores = false;
			let data = res.proveedores;
			for (var i = 0; i < data.length; i++) {
				if(data[i].estado == '1'){
					this.proveedores.push(data[i]);
				}else{
					this.proveedoresDisabled.push(data[i]);
				}
			}
			// this.proveedores = res.proveedores;
		}, err =>{
			this.cargandoProveedores = false;
			console.error(err);
		})
	}

	obtenerSedesProveedor(proveedor){
		this.sedes = [];
		this.cargandoSedes = true;
		this.proveedoresService.getProveedorSedes(proveedor).subscribe( (res:any) =>{
			this.cargandoSedes = false;
			this.sedes = res.sedes;
		}, err =>{
			this.cargandoSedes = false;
			console.error(err);
		})
	}

	obtenerSedes_actProveedor(proveedor){
		this.promocionChanged.idsede = 0;
		this.val_sede = false;
		this.validarProveedor('proveedor', proveedor, 'isint');
		this.proveedoresService.getProveedorSedes(proveedor).subscribe( (res:any) =>{
			this.cargandoSedes = false;
			this.sedes = res.sedes;
		}, err =>{
			this.cargandoSedes = false;
			console.error(err); 
		})
	}
	
	
	
	
	
	
	
	
	

}
