<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<h2>COMPRAS</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-app bg-success ml-0" (click)="openModalAgregar(agregarA)">
				<i class="fas fa-plus"></i>
				Agregar
			</button>
		</div>
	</div>


	<div class="row">
		<div class="col-md-12">
			<div class="card card-dark">
				<div class="card-header">
					<h3>Listado de compras</h3>
				</div>
				<div class="card-body" *ngIf="cargandoCompras">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>

				<div class="card-body table-responsive" *ngIf="!cargandoCompras">
					<div class="alert" *ngIf="compras.length == 0"><i class="fas fa-exclamation-triangle"></i> No hay información pafa mostrar!</div>
					<table class="table table-striped" *ngIf="compras.length > 0">
						<thead>
							<tr class="bg-secondary">
								<th>#</th>
								<th>COMPRA</th>
								<th>CLIENTE</th>
								<th>NOTICIA</th>
								<th>FECHA</th>
								<th>MONTO</th>
								<th>OPCIONES</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let a of compras; let i = index">
								<th>{{i + 1}}</th>
								<th>{{a.codigoCompra}}</th>
								<th>{{a.nombresCliente}}, {{a.apellidosCliente}}</th>
								<th>{{a.nombrePromocion}}</th>
								<th>{{a.fechaCompra  | date: 'mediumDate'}}</th>
								<th>{{a.montoCompra}}</th>
								<th>
									<button class="btn btn-primary btn-sm mr-2" title="editar" (click)="openModalEditar(editarA, a)"><i class="fas fa-edit"></i></button>
									<button class="btn btn-danger btn-sm mr-2" title="eliminar" (click)="eliminarCompra(a.codigoCompra)"><i class="fas fa-times"></i></button>
								</th>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-footer"></div>
			</div>
		</div>
	</div>
</div>


<!--============================
=            MODALS            =
=============================-->
<ng-template #agregarA let-modalAgregar>
	<div class="modal-header bg-dark">
		<h5 class="modal-title">AGREGAR COMPRA</h5>
		<button type="button" class="close" (click)="cerrarModalAgregar()">
			<span aria-hidden="true" class="text-white">&times;</span>
		</button>
	</div>


	<div class="modal-body">

		<div class="row">
		    <div class="col-md-12">
		      <div class="callout callout-info">
		        <h5>Información!</h5>
		        <p>Los campos marcados con <b>*</b> son obligatorios.</p>
		      </div>
		    </div>
		  </div>

		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label>Cliente *</label>
					<div *ngIf="cargandoClientes" class="spinner-border spinner-border-sm" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<select *ngIf="!cargandoClientes" [ngClass]='compraClass.clienteClass' style="width: 100%;" [(ngModel)]="compraAdd.codigoCliente" (change)="quitarInvalidTag('cliente')">
						<option selected value="0">-- Selecciona un cliente --</option>
						<option *ngFor="let a of clientes" [value]="a.codigoCliente">{{a.codigoCliente}} | {{a.nombresCliente}}, {{a.apellidosCliente}}</option>
					</select>
				</div>
			</div>

			<div class="col-md-6">
				<div class="form-group">
					<label>Promocion *</label>
					<div *ngIf="cargandoPromociones" class="spinner-border spinner-border-sm" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<select *ngIf="!cargandoPromociones" [ngClass]='compraClass.promocionClass' style="width: 100%;" [(ngModel)]="compraAdd.codigoPromocion" (change)="quitarInvalidTag('promocion')">
						<option selected value="0">-- Selecciona una promocion --</option>
						<option *ngFor="let a of promociones" [value]="a.id">{{a.id}} | {{a.promocion}} | {{a.proveedor}}</option>
					</select>
				</div>
			</div>

			<div class="col-md-12">
				<div class="form-group">
					<label>Fecha *</label>
					<input type="date" [(ngModel)]="compraAdd.fechaCompra" [ngClass]='compraClass.fechaClass' placeholder="YYYY-MM-DD" maxlength="10" (keyup)="quitarInvalidTag('fecha')">
				</div>
			</div>

			<div class="col-md-12">
				<div class="form-group">
					<label>Monto *</label>
					<input type="text" [(ngModel)]="compraAdd.montoCompra" [ngClass]='compraClass.montoClass' placeholder="Monto" maxlength="10" (keyup)="quitarInvalidTag('monto')">
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="cerrarModalAgregar()">Cerrar</button>
		<button type="button" class="btn btn-success" (click)="agregarCompra()"><i class="fas fa-save"></i> Guardar</button>
	</div>
</ng-template>


<ng-template #editarA let-modalEditar>
	<div class="modal-header bg-dark">
		<h5 class="modal-title">EDITAR COMPRA</h5>
		<button type="button" class="close" (click)="cerrarModalEditar()">
			<span aria-hidden="true" class="text-white">&times;</span>
		</button>
	</div>


	<div class="modal-body">

		<div class="row">
		    <div class="col-md-12">
		      <div class="callout callout-info">
		        <h5>Información!</h5>
		        <p>Los campos marcados con <b>*</b> son obligatorios.</p>
		      </div>
		    </div>
		  </div>

		  
		<div class="row">
			<div class="col-md-6">
				<div class="form-group">
					<label>Cliente *</label>
					<div *ngIf="cargandoClientes" class="spinner-border spinner-border-sm" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<select *ngIf="!cargandoClientes" [ngClass]='compraClass.clienteClass' style="width: 100%;" [(ngModel)]="compraEditar.codigoCliente" (change)="quitarInvalidTag('cliente')">
						<option selected value="0">-- Selecciona un cliente --</option>
						<option *ngFor="let a of clientes" [value]="a.codigoCliente">{{a.codigoCliente}} | {{a.nombresCliente}}, {{a.apellidosCliente}}</option>
					</select>
				</div>
			</div>

			<div class="col-md-6">
				<div class="form-group">
					<label>Promocion *</label>
					<div *ngIf="cargandoPromociones" class="spinner-border spinner-border-sm" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<select *ngIf="!cargandoPromociones" [ngClass]='compraClass.promocionClass' style="width: 100%;" [(ngModel)]="compraEditar.codigoPromocion" (change)="quitarInvalidTag('promocion')">
						<option selected value="0">-- Selecciona una promocion --</option>
						<option *ngFor="let a of promociones" [value]="a.id">{{a.id}} | {{a.promocion}} | {{a.proveedor}}</option>
					</select>
				</div>
			</div>

			<div class="col-md-12">
				<div class="form-group">
					<label>Fecha *</label>
					<input type="date" [(ngModel)]="compraEditar.fechaCompra" [ngClass]='compraClass.fechaClass' placeholder="YYYY-MM-DD" maxlength="10" (keyup)="quitarInvalidTag('fecha')">
				</div>
			</div>

			<div class="col-md-12">
				<div class="form-group">
					<label>Monto *</label>
					<input type="text" [(ngModel)]="compraEditar.montoCompra" [ngClass]='compraClass.montoClass' placeholder="Monto" maxlength="10" (keyup)="quitarInvalidTag('monto')">
				</div>
			</div>
		</div>
	</div>
	

    <div class="modal-footer">
    	<button type="button" class="btn btn-secondary" (click)="cerrarModalEditar()">Cerrar</button>
    	<button type="button" class="btn btn-primary" (click)="actualizarCompra()"><i class="fas fa-edit"></i> Actualizar</button>
    </div>
</ng-template>
