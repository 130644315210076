import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})


export class ProveedoresService {

	constructor(private http:HttpClient,
				private aService:AuthService) {
		this.TOKEN = this.aService.leerToken();
	}

	/*constantes*/
	APIKEY:string = environment.APIKEY;
	APIURL:string = environment.APIURL;
	TOKEN:string;


	// public getProveedores(proveedor = null){
	// 	const headers = new HttpHeaders({
	// 		'pp-apikey': this.APIKEY,
	// 		'pp-token': this.TOKEN
	// 	})

	// 	if(proveedor){
	// 		let url:string = `${this.APIURL}proveedores/${proveedor}`;
	// 		return this.http.get(url, { headers } );

	// 	}else{
	// 		let url:string = this.APIURL + 'proveedores?estado=1';
	// 		return this.http.get(url, { headers } );
	// 	}	

	// }

	getProveedores(proveedor = null, filter = null, valuefilter = null){

		// proveedores/2?filter=filtros
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})



		if(proveedor){

			if(filter){
				let url:string = `${this.APIURL}proveedores/${proveedor}?${filter}=${valuefilter}`;
				return this.http.get(url, { headers } );
			}else{
				let url:string = `${this.APIURL}proveedores/${proveedor}`;
				return this.http.get(url, { headers } );
			}
		}else{

			if(filter){
				let url:string = this.APIURL + "proveedores?" + filter + "=" + valuefilter;
				return this.http.get(url, { headers } );
			}else{
				let url:string = this.APIURL + 'proveedores';
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	getProveedorSedes(proveedor){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})
		// let url:string = this.APIURL + 'proveedores/26?filter=sedes';
		let url:string = `${this.APIURL}proveedores/${proveedor}?filter=sedes`;
		return this.http.get(url, { headers } );
	}

	postProveedores(data, id = null, coleccion = null){
		let url:string;

		const headers = new HttpHeaders({
				'pp-apikey': this.APIKEY,
				'pp-token': this.TOKEN
			})

		if(id && coleccion){
			url = this.APIURL + 'proveedores/' + id + '/' + coleccion;
			let body = new FormData;
			switch (coleccion) {
				case "sedes":
						body.append('codigoSede', data);
					break;
				case "filtros":
						body.append('codigoFiltro', data.codigoFiltro);
						body.append('codigoCategoria', data.codigoCategoria);
					break;
				default:
					// code...
					break;	
			}

			return this.http.post(url, body, { headers } );


		}else{
			url = this.APIURL + 'proveedores';
			let body = new FormData;
				body.append('codigoCategoria', data.codigoCategoria);
				body.append('nombreProveedor', data.nombreProveedor);
				body.append('emailProveedor', data.emailProveedor);
				body.append('telefonoProveedor', data.telefonoProveedor);
				body.append('estadoProveedor', data.estadoProveedor);
			return this.http.post(url, body, { headers } );
		}
		
	}

	putProveedores(id, data){
		let url:string = this.APIURL + 'proveedores/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	putProveedorImagen(file, proveedor, imgAnterior){


		console.log(file);

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})
			let body = new FormData();
			body.append('imagen', file);
			body.append('imgAnterior', imgAnterior);
		let url:string = this.APIURL + 'proveedores/' + proveedor +'/imagenes';
		return this.http.post(url, body, { headers } );
	}

	deleteProveedores(id, coleccion = null, idcoleccion = null){
		let url:string = this.APIURL + 'proveedores/' + id;
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(coleccion && idcoleccion){
			url = url + '/' + coleccion + '/' + idcoleccion;
		}
		return this.http.delete(url, { headers } );
	}






}
