import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ClientesService } from '../../../../services/clientes.service';
import { ConfiguracionesService } from '../../../../services/configuraciones.service';
import { NotificacionesService } from '../../../../services/notificaciones.service';


@Component({
	selector: 'app-clientes',
	templateUrl: './clientes.component.html',
	styles: []
})

export class ClientesComponent implements OnInit {

	constructor(private clientesService: ClientesService,
				private configService: ConfiguracionesService,
				private notificacionesService: NotificacionesService,
				private router: Router,
				private modalService: NgbModal) {
		this.cargarClientes();
	}

	ngOnInit(): void {}

	/*=================================
	=            VARIABLES            =
	=================================*/
	clientes:string[] = [];
	cargandoClientes:boolean = true;

	/*opciones*/
	membresias:string[] = [];
	sedes:string[] = [];
	deportes:string[] = [];
	instituciones:string[] = [];
	ligas:string[] = [];

	cargandoMembresias:boolean = true;
	cargandoSedes:boolean = true;
	cargandoDeportes:boolean = true;
	cargandoInstituciones:boolean = true;
	cargandoLigas:boolean = true;

	/*========================================
	=            OBTENER CLIENTES            =
	========================================*/
	cargarClientes(){
		this.cargandoClientes = true;
		this.clientesService.getClientes().subscribe( (res:any) =>{
			this.cargandoClientes = false;
			if(!res.error){
				this.clientes = res.clientes;
			}
		}, (err:any) => {
			this.cargandoClientes = false;
			console.error(err);
		})
	}

	/*========================================
	=            AGREGAR CLIENTES            =
	========================================*/
	modalAgregar:any;

	clienteAdd = {
		codigoMembresia: '0',
		codigoDeporte: '0',
		codigoInstitucion: '0',
		codigoLiga: '0',
		codigoSede: '0',
		nombresCliente: '',
		apellidosCliente: '',
		estadoCliente: '1'
	}

	clasesAdd = {
		membresiaClass: ['form-control'],
		deporteClass: ['form-control'],
		institucionClass: ['form-control'],
		ligaClass: ['form-control'],
		sedeClass: ['form-control'],
		nombreClass: ['form-control'],
		apellidoClass: ['form-control'],
		estadoClass: ['form-control']
	}

	openModalAgregar(agregarA) {
		this.cargarSedes();
		this.cargarMembresias();
		this.cargarDeportes();
		this.cargarInstituciones();
		this.cargarLigas();

		this.modalAgregar = this.modalService.open(agregarA, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {
			// console.log(`Closed with: ${result}`);
		}, (reason) => {
			// console.log(`Dismissed ${this.getDismissReason(reason)}`);
		});
	}

	cerrarModalAgregar(){
		this.modalAgregar.close('cerrar');
		this.clienteAdd = {
			codigoMembresia: '0',
			codigoDeporte: '0',
			codigoInstitucion: '0',
			codigoLiga: '0',
			codigoSede: '0',
			nombresCliente: '',
			apellidosCliente: '',
			estadoCliente: '1'
		}
	}

	agregarCliente(){

		this.clienteAdd.codigoMembresia = this.clienteAdd.codigoMembresia.trim();
		this.clienteAdd.codigoDeporte = this.clienteAdd.codigoDeporte.trim();
		this.clienteAdd.codigoInstitucion = this.clienteAdd.codigoInstitucion.trim();
		this.clienteAdd.codigoLiga = this.clienteAdd.codigoLiga.trim();
		this.clienteAdd.codigoSede = this.clienteAdd.codigoSede.trim();
		this.clienteAdd.nombresCliente = this.clienteAdd.nombresCliente.trim();
		this.clienteAdd.apellidosCliente = this.clienteAdd.apellidosCliente.trim();
		this.clienteAdd.estadoCliente = this.clienteAdd.estadoCliente.trim();

		let enabled_add = this.validarDatos();


		if(enabled_add){
			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.clientesService.postClientes(this.clienteAdd).subscribe( (res:any) =>{
				Swal.close();
				if(!res.error){
					Swal.fire('Agregado', 'Sede agregada', 'success').then( () => {
						this.cerrarModalAgregar();
						let ruta = 'main/clientes/' + res.msg;
						this.router.navigateByUrl(ruta);
					});
				}else{
					Swal.fire('Error', res.msg, 'error');
				}
			}, (err:any) =>{
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error');
			})
		}else{
			console.error('error en formulario');
		}
	}


	/*======================================
	=            NOTIFICACIONES            =
	======================================*/
	modalNotificaciones:any;

	notificacion = {
		subtitulo: '',
		contenido: ''
	}

	notificacionClass = {
		subtitulo: ['form-control'],
		contenido: ['form-control']
	}

	openModalNotificaciones(notificaciones) {
		this.modalNotificaciones = this.modalService.open(notificaciones, {backdrop:'static', size: 'lg'});
	}

	cerrarModalNotificaciones(){
		this.modalNotificaciones.close('cerrar');
		this.notificacion = {subtitulo: '', contenido: ''}
	}

	enviarNotificacion(){

		this.notificacion.subtitulo = this.notificacion.subtitulo.trim();
		this.notificacion.contenido = this.notificacion.contenido.trim();

		if(this.notificacion.subtitulo.length > 0  && this.notificacion.subtitulo.length < 31 ){
			if(this.notificacion.contenido.length > 0  && this.notificacion.contenido.length < 201 ){

				Swal.fire({
					allowOutsideClick: false,
					text: 'Enviando notificación!',
				})
				Swal.showLoading();
				const data = {
					"subtitulo": this.notificacion.subtitulo,
					"contenido": this.notificacion.contenido,
					"path": 'promociones/'
				}

				this.notificacionesService.sendNotification(data).subscribe( (res:any) => {
					Swal.close();
					Swal.fire('Enviado', 'Notificación enviada a: <b>' + res.recipients + ' cliente(s)</b>', 'success').then(() => {
						this.cerrarModalNotificaciones();
					});
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', 'No se pudo enviar la notificación', 'error');
				})


			}else{
				this.notificacionClass.contenido.push('is-invalid');
				console.error('error en contenido');
			}
		}else{
			this.notificacionClass.subtitulo.push('is-invalid');
			console.error('error en titulo');
		}


	
	}
	
	
	



	/*================================
	=            OPCIONES            =
	================================*/
	cargarSedes(){
		this.cargandoSedes = true;
		this.configService.getSedes().subscribe( (res:any) => {
			this.cargandoSedes = false;
			this.sedes = res.sedes;
		}, (err:any) => {
			this.cargandoSedes = false;
			console.error(err.error.msg);
		})
	}

	cargarMembresias(){
		this.cargandoMembresias = true;
		this.configService.getMembresias(null, 'estado', 1).subscribe( (res:any) => {
			this.cargandoMembresias = false;
			this.membresias = res.membresias;
		}, (err:any) => {
			this.cargandoMembresias = false;
			console.error(err.error.msg);
		})
	}

	cargarDeportes(){
		this.cargandoDeportes = true;
		this.configService.getDeportes().subscribe( (res:any) => {
			this.cargandoDeportes = false;
			this.deportes = res.deportes;
		}, (err:any) => {
			this.cargandoDeportes = false;
			console.error(err.error.msg);
		})
	}

	cargarInstituciones(){
		this.cargandoInstituciones = true;
		this.configService.getInstituciones().subscribe( (res:any) => {
			this.cargandoInstituciones = false;
			this.instituciones = res.instituciones;
		}, (err:any) => {
			this.cargandoInstituciones = false;
			console.error(err.error.msg);
		})
	}

	cargarLigas(){

		this.cargandoLigas = true;
		this.configService.getLigas().subscribe( (res:any) => {
			this.cargandoLigas = false;
			this.ligas = res.ligas;
		}, (err:any) => {
			this.cargandoLigas = false;
			console.error(err.error.msg);
		})	
	}


	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	validarDatos(){
		let a = 7;
		if(this.clienteAdd.codigoMembresia.length != 1 || this.clienteAdd.codigoMembresia == "0"){
			this.clasesAdd.membresiaClass.push('is-invalid');
			a--;
		}

		if(this.clienteAdd.codigoDeporte.length == 0 || this.clienteAdd.codigoDeporte == "0"){
			this.clasesAdd.deporteClass.push('is-invalid');
			a--;
		}

		if(this.clienteAdd.codigoInstitucion.length == 0 || this.clienteAdd.codigoInstitucion == "0"){
			this.clasesAdd.institucionClass.push('is-invalid');
			a--;
		}

		if(this.clienteAdd.codigoLiga.length == 0 || this.clienteAdd.codigoLiga == "0"){
			this.clasesAdd.ligaClass.push('is-invalid');
			a--;
		}

		if(this.clienteAdd.codigoSede.length == 0|| this.clienteAdd.codigoSede == "0"){
			this.clasesAdd.sedeClass.push('is-invalid');
			a--;
		}


		if(this.clienteAdd.nombresCliente.length == 0 || this.clienteAdd.nombresCliente.length > 50 ){
			this.clasesAdd.nombreClass.push('is-invalid');
			a--;
		}

		if(this.clienteAdd.apellidosCliente.length == 0 || this.clienteAdd.apellidosCliente.length > 50){
			this.clasesAdd.apellidoClass.push('is-invalid');
			a--;
		}

		if(a == 7){
			return true;
		}else{
			return false;
		}
	}

	quitarInvalidTag(tipo){
		switch (tipo) {
			case "nombre":
				this.removeItemFromArr(this.clasesAdd.nombreClass, 'is-invalid');
				break;
			case "apellido":
				this.removeItemFromArr(this.clasesAdd.apellidoClass, 'is-invalid');
				break;
			case "membresia":
				this.removeItemFromArr(this.clasesAdd.membresiaClass, 'is-invalid');
				break;
			case "sede":
				this.removeItemFromArr(this.clasesAdd.sedeClass, 'is-invalid');
				break;
			case "deporte":
				this.removeItemFromArr(this.clasesAdd.deporteClass, 'is-invalid');
				break;
			case "institucion":
				this.removeItemFromArr(this.clasesAdd.institucionClass, 'is-invalid');
				break;
			case "liga":
				this.removeItemFromArr(this.clasesAdd.ligaClass, 'is-invalid');
				break;
			case "titulonoti":
				this.removeItemFromArr(this.notificacionClass.subtitulo, 'is-invalid');
				break;
			case "contenidonoti":
				this.removeItemFromArr(this.notificacionClass.contenido, 'is-invalid');
				break;
			default:
				console.error('no existe tipo tag');
				break;
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

}
