import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { PromocionesService } from '../../../../services/promociones.service';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styles: []
})
export class HomeComponent implements OnInit {

	constructor(private router:Router,
				private pService:PromocionesService) { }

	ngOnInit(): void {}


	deshabilitarPromociones(){
		Swal.fire({
			title: '¿Quieres verificar las noticias vencidas?',
			text: "Las noticias con fecha de fin anteriores a HOY serán marcadas como vencidas!",
			icon: 'question',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-success',
				cancelButton: 'bg-danger'
			},
			confirmButtonText: 'Verificar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.pService.vigenciasPromocion().subscribe( (res:any) =>{
					Swal.close();
					Swal.fire('Validado', 'Se marcaron como vencidas: <b>'+ res.msg + ' publicaciones<b/>', 'success');
				}, (err:any) =>{
					Swal.close();
					console.error(err.error);
					Swal.fire('Error', err.error.msg, 'error');
				})
			}
		})
	}


	ir(){
		this.router.navigateByUrl('main/noticias');
	}



}
