<div class="sidenav">
   <div class="login-main-text">
      <img src="assets/img/privato_.png" width="50%">
      <h2>Privato Player App<br></h2>
      <p>Inicio de sesión</p>
   </div>
</div>
<div class="main">
   <div class="col-md-6 col-sm-12">
      <div class="login-form">
         <form>
            <div class="form-group">
               <label>Usuario</label>
               <input type="text" class="form-control" #usuario autocomplete="off">
            </div>
            <div class="form-group">
               <label>Contraseña</label>
               <input type="password" class="form-control" #constrasena autocomplete="off">
            </div>
            <button class="btn btn-black" (click)="login(usuario.value, constrasena.value)">Entrar</button>
         </form>
      </div>
   </div>
</div>