import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ConfiguracionesService } from '../../../../services/configuraciones.service';


@Component({
	selector: 'app-instituciones',
	templateUrl: './instituciones.component.html',
	styles: []
})
export class InstitucionesComponent implements OnInit {


	constructor(private configService: ConfiguracionesService,
				private modalService: NgbModal) {
		this.cargarInstituciones();
	}


	ngOnInit(): void {

	}


	/*=================================
	=            VARIABLES            =
	=================================*/
	instituciones:string[] = [];
	cargandoInstituciones:boolean = true;


	/*=============================================
	=            OBTENER INSTITUCIONES            =
	=============================================*/
	cargarInstituciones(){
		this.cargandoInstituciones = true;
		this.configService.getInstituciones().subscribe( (res:any) =>{
			this.cargandoInstituciones = false;
			if(!res.error){
				this.instituciones = res.instituciones;
			}
		}, (err:any) => {
			this.cargandoInstituciones = false;
			console.error(err);
		})
	}

	/*===========================================
	=            AGREGAR INSTITUCIÓN            =
	===========================================*/
	modalAgregar:any;

	nombreAdd:string = "";
	nombreClass:string[] = ['form-control'];

	openModalAgregar(agregar) {
		this.modalAgregar = this.modalService.open(agregar, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {
		}, (reason) => {
		});
	}

	cerrarModalAgregar(){

		this.modalAgregar.close('cerrar');
		this.nombreAdd = "";
	}

	agregarInstitucion(){
		let nombre = this.nombreAdd.trim();
		if(nombre){
			if(nombre.length > 0 && nombre.length < 51){

				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.configService.postInstituciones(nombre).subscribe( (res:any) =>{
					Swal.close();
					if(!res.error){
						Swal.fire('Agregado', 'Institución agregada', 'success').then( () => {
							this.nombreAdd = "";
							this.cerrarModalAgregar();
							this.cargarInstituciones();
						});
					}else{
						Swal.fire('Error', res.msg, 'error');
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}else{
				console.error('Error en cantidad de caracteres');
				this.nombreClass.push('is-invalid');
			}
		}else{
			console.error('No hay nombre');
			this.nombreClass.push('is-invalid');
		}
	}


	/*==========================================
	=            EDITAR INSTITUCIÓN            =
	==========================================*/
	modalEditar:any;

	institucionActual = {
		codigoInstitucion: "",
		nombreInstitucion: ""
	}

	institucionEditar = {
		codigoInstitucion: "",
		nombreInstitucion: ""
	}
	

	openModalEditar(editar, actual) {

		this.institucionEditar.codigoInstitucion = actual.codigoInstitucion;
		this.institucionEditar.nombreInstitucion = actual.nombreInstitucion;
		this.institucionActual.codigoInstitucion = actual.codigoInstitucion;
		this.institucionActual.nombreInstitucion = actual.nombreInstitucion;

		this.modalEditar = this.modalService.open(editar, {backdrop:'static', size: 'lg'});
		this.modalEditar.result.then((result) => {
		}, (reason) => {
		});

	}

	cerrarModalEditar(){
		this.modalEditar.close('cerrar');
		this.institucionEditar.nombreInstitucion = "";
	}

	actualizarInstitucion(){
		const nombre = this.institucionEditar.nombreInstitucion.trim();

		if(nombre.length > 0 && nombre.length < 101){

			if(nombre != this.institucionActual.nombreInstitucion.trim()){
				const data = {'nombre': nombre};
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.configService.putInstituciones(this.institucionActual.codigoInstitucion, data).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Actualizado', 'Institución actualizada', 'success').then( () => {
							this.cerrarModalEditar();
							this.cargarInstituciones();
						});
					}else{
						Swal.fire('Error', res.msg, 'error')
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error')
				})

			}else{
				console.error('no hay cambios para realizar');
				Swal.fire('Info', 'No hay cambios para realizar', 'info');
			}
		}else{
			console.error('error en caracteres de nombre');
			this.nombreClass.push('is-invalid');

		}

	}

	/*============================================
	=            ELIMINAR INSTITUCIÓN            =
	============================================*/
	eliminarInstitucion(id){

		Swal.fire({
			title: '¿Quieres eliminar la institución?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.configService.deleteInstituciones(id).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Institución eliminada', 'success').then( () => {
							this.cargarInstituciones();
						})
					}else{
						Swal.fire('Error', res.msg, 'error');

					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}







	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	quitarInvalidName(){
		this.removeItemFromArr(this.nombreClass, 'is-invalid');
	}
	

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}



  
  
  

}
