import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ClientesService {

	constructor(private http:HttpClient,
				private aService:AuthService) {
		this.TOKEN = this.aService.leerToken();
	}

	/*==================================
	=            CONSTANTES            =
	==================================*/
	APIKEY:string = environment.APIKEY;
	APIURL:string = environment.APIURL;
	TOKEN:string;


	/*================================
	=            CLIENTES            =
	================================*/
	getClientes(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'clientes';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postClientes(data){
		let url:string = this.APIURL + 'clientes';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body.append('codigoMembresia', data.codigoMembresia);
			body.append('codigoDeporte', data.codigoDeporte);
			body.append('codigoInstitucion', data.codigoInstitucion);
			body.append('codigoLiga', data.codigoLiga);
			body.append('codigoSede', data.codigoSede);
			body.append('nombresCliente', data.nombresCliente);
			body.append('apellidosCliente', data.apellidosCliente);
			body.append('estadoCliente', data.estadoCliente);
		return this.http.post(url, body, { headers } );
	}

	putClientes(id, data){
		let url:string = this.APIURL + 'clientes/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	putClienteImagen(file, cliente, imgAnterior){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

			let body = new FormData();
			body.append('imagen', file);
			body.append('imgAnterior', imgAnterior);
		let url:string = this.APIURL + 'clientes/' + cliente +'/imagenes';
		return this.http.post(url, body, { headers } );
	}

	deleteClientes(id){
		let url:string = this.APIURL + 'clientes/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}





}
