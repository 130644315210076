<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<h2>SOCIOS</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-app bg-success ml-0" (click)="openModalAgregar(agregarA)">
				<i class="fas fa-plus"></i>
				Agregar
			</button>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">

			<div class="card card-dark">
				<div class="card-header">
					<h3>Listado de socios</h3>
				</div>

				<div class="card-body" *ngIf="cargandoProveedores">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>


				<div class="card-body table-responsive" *ngIf="!cargandoProveedores">

					<div class="alert" *ngIf="proveedores.length == 0"><i class="fas fa-exclamation-triangle"></i> No hay información para mostrar.</div>

					<table class="table table-striped" *ngIf="proveedores.length > 0">
						<thead>
							<tr class="bg-secondary">
								<th>#</th>
								<th>SOCIO</th>
								<th>CATEGORÍA</th>
								<th>ESTADO</th>
								<th>IMAGEN</th>
								<th>OPCIONES</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let a of proveedores; let i = index">
								<th>{{i + 1}}</th>
								<th>{{a.proveedor}}</th>
								<th>{{a.categoria}}</th>
								<th *ngIf="a.estado == 1"><i class="fas fa-circle text-success"></i></th>
								<th *ngIf="a.estado != 1"><i class="fas fa-circle text-danger"></i></th>
								<th *ngIf="a.imagen"><i class="fas fa-file-image text-success"></i></th>
								<th *ngIf="!a.imagen"><i class="fas fa-file-image text-danger"></i></th>
								<th>
									<button class="btn btn-primary btn-sm mr-2" title="ver" [routerLink]="[a.idproveedor]">Ver</button>
								</th>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-footer"></div>
			</div>
		</div>
	</div>
</div>



<!--============================
=            MODALS            =
=============================-->

<ng-template #agregarA let-modalAgregar>

   <div class="modal-header bg-dark">
      <h5 class="modal-title">AGREGAR PROVEEDOR</h5>
      <button type="button" class="close" (click)="cerrarModalAgregar()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>


    <div class="modal-body">

    	<div class="row">
			<div class="col-md-12">
				<div class="callout callout-info">
					<h5>Información!</h5>
					<p>Los campos marcados con <b>*</b> son obligatorios.</p>
				</div>
			</div>
		</div>

      <div class="row">


      	<div class="col-md-6">
          <div class="form-group">
            <label>Categoría *</label>
            <div *ngIf="cargandoCategorias" class="spinner-border spinner-border-sm" role="status">
            	<span class="sr-only">Loading...</span>
            </div>
            <select *ngIf="!cargandoCategorias" [ngClass]='clasesAdd.categoriaClass' style="width: 100%;" [(ngModel)]="proveedorAdd.codigoCategoria" (change)="quitarInvalidTag('categoria')">
              <option selected value="0">-- Selecciona una categoria --</option>
              <option *ngFor="let a of categorias" [value]="a.codigoCategoria">{{a.nombreCategoria}}</option>
            </select>
          </div>
        </div>



        <div class="col-md-12">
          <div class="form-group">
            <label>Nombres *  <small>(Max 50 caracteres)</small></label>
            <input type="text" [(ngModel)]="proveedorAdd.nombreProveedor" [ngClass]='clasesAdd.nombreClass' placeholder="Nombres" maxlength="50" (keyup)="quitarInvalidTag('nombre')">
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Email  <small>(Max 50 caracteres)</small></label>
            <input type="email" [(ngModel)]="proveedorAdd.emailProveedor" [ngClass]='clasesAdd.emailClass' placeholder="Email" maxlength="50" (keyup)="quitarInvalidTag('email')">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Teléfono <small>(10 caracteres)</small></label>
            <input type="text" [(ngModel)]="proveedorAdd.telefonoProveedor" [ngClass]='clasesAdd.telefonoClass' placeholder="Teléfono" maxlength="10" (keyup)="quitarInvalidTag('telefono')">
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Estado *</label>
            <select [ngClass]='clasesAdd.estadoClass' style="width: 100%;" [(ngModel)]="proveedorAdd.estadoProveedor">
              <option selected value="1"> Activo </option>
              <option selected value="0"> Inactivo </option>
            </select>
          </div>
        </div>
 
      </div>     

    </div>


    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalAgregar()">Cerrar</button>
      <button type="button" class="btn btn-success" (click)="agregarProveedor()"><i class="fas fa-save"></i> Guardar</button>
    </div>
</ng-template>