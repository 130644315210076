import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

import { PromocionesService } from '../../../../services/promociones.service';
import { ProveedoresService } from '../../../../services/proveedores.service';
import { ProductosService } from '../../../../services/productos.service';
import { ValidacionesService } from '../../../../services/validaciones.service';
import { Promocion, Promociones, PromocionNueva } from '../../../../models/promocion.interface';
import { RulesValidation } from '../../../../models/general.interface';

@Component({
  selector: 'app-listadopromocion',
  templateUrl: './listadopromocion.component.html',
  styles: []
})
export class ListadopromocionComponent implements OnInit {

	// modal:NgbModalRef;
	modal:any;


	constructor(private pService:PromocionesService,
				private prService: ProveedoresService,
				private proService: ProductosService,
				private vService: ValidacionesService,
				private router: Router,
				private modalService: NgbModal,
				private calendar:NgbCalendar
				) {

		/*obtener promociones al entrar a la página*/
		this.obtenerPromociones();
	}

	/* -----------  variables para cargar listado de promociones  ----------- */
	cargando:boolean = true;
	promociones:Promocion[] = [];
	promociones_filter = [];
	filterName:string = 'Todas';
	opciones:string[] = [];

	/* -----------  variables para cargar opciones de agregar promocion  ----------- */
	proveedores = [];
	cargandoProveedores:boolean = true;
	productos = [];
	cargandoProductos:boolean = true;
	sedesProveedor = [];
	cargandoSedesProveedores:boolean = false;

	

	/* -----------  variables para validar campos de agregar promocioj  ----------- */
	promocionN:PromocionNueva = {
		nombre: null,
		proveedor: 0,
		sede: 0,
		producto: 0
	};
	enabled_add:boolean = false;
	val_nombre:boolean = false;
	val_proveedor:boolean = false;
	val_sede:boolean = false;
	val_producto:boolean = false;

	nombreClass:string[] = ['form-control'];
	proveedorClass:string[] = ['form-control'];
	productoClass:string[] = ['form-control'];
	sedeClass:string[] = ['form-control'];


	validacion:any = {};

	alertName:string;
	alertClass:string;
	textDiv:boolean = true;

	

	ngOnInit(): void {}

	/* -----------  función para validar campos  ----------- */
	validarProveedor(tipo, valor, rules){
		this.validacion = {};

		valor = valor.trim();
		switch (tipo) {
			case "nombre":
				this.val_nombre = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.promocionN.nombre = valor;
					this.val_nombre = this.validacion.response;
					this.removeItemFromArr(this.nombreClass, 'is-invalid');
				}else{
					this.val_nombre = this.validacion.response;
				}
				break;
			case "proveedor":
				this.val_proveedor = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.promocionN.proveedor = valor;
					this.val_proveedor = this.validacion.response;
					this.removeItemFromArr(this.proveedorClass, 'is-invalid');
				}else{
					this.val_proveedor = this.validacion.response;
				}
				break;
			case "producto":
				this.val_producto = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.promocionN.producto = valor;
					this.val_producto = this.validacion.response;
					this.removeItemFromArr(this.productoClass, 'is-invalid');
				}else{
					this.val_producto = this.validacion.response;
				}
				break;
			case "sede":
				this.val_sede = false;
				this.validacion = this.vService.comprobarRules(valor, rules);
				if(this.validacion.response){
					this.promocionN.sede = valor;
					this.val_sede = this.validacion.response;
					this.removeItemFromArr(this.sedeClass, 'is-invalid');
				}else{
					this.val_sede = this.validacion.response;
				}
				break;
			
			default:
				console.error('Tipo no existe');
				break;
		}
	}

	guardarProveedor(){

		Swal.fire('Cargando');
		Swal.showLoading();





		this.enabled_add = false;
		if(this.val_nombre && this.val_proveedor && this.val_sede && this.val_producto){
			this.enabled_add = true;
		}
		
		if(this.enabled_add){
			this.pService.postPromocion(this.promocionN).subscribe( (res:any) => {
				if(!res.error){
					/*agregadocorrectamente*/
					Swal.close();
					this.modal.close();
					let ruta = 'main/noticias/' + res.msg.msg;
					this.router.navigateByUrl(ruta);

				}else{
					/*hubo error*/
					Swal.close();
					Swal.fire('Error', res.msg, 'error');
				}
			}, (err:any) =>{
				Swal.fire('Error', 'No se puede agregar, hay campos erróneos', 'error');
			})
		}else{
			Swal.close();
			Swal.fire('Error', 'No se puede agregar, hay campos erróneos', 'error');

			this.mostrarErroresProveedorNuevo();
		}
	}


	/* -----------  función para obtener listado de promociones  ----------- */
	obtenerPromociones(){
		this.cargando = true;
		this.pService.getPromociones().subscribe( (res:Promociones) => {
			this.cargando = false;
			console.log(res);
			this.promociones = res.promociones;
			this.promociones_filter = res.promociones;
		}, err => {
			this.cargando = false;
			console.error(err);
		})
	}



	/*=============================
	=            MODAL            =
	=============================*/
	obtenerOpciones(){
		/* al abrir modal se obtienen los proveedores y productos disponibles */
		this.cargandoProductos = true;
		this.proService.getProductos().subscribe( (res:any) =>{
			this.cargandoProductos = false;
			this.productos = res.productos;
		}, err =>{
			this.cargandoProductos = false;
			console.error(err);
		})

		this.cargandoProveedores = true;
		this.prService.getProveedores(null, 'estado', 1).subscribe( (res:any) =>{
			this.cargandoProveedores = false;
			this.proveedores = res.proveedores;
		}, err =>{
			this.cargandoProveedores = false;
			console.error(err);
		})

	}


	filtroPromociones(tipo){
		switch (tipo) {
			case "todas":
				this.promociones_filter = this.promociones;
				this.filterName = 'Todas';
				break;
			case "vencidas":
				this.filterName = 'Vencidas';
				this.promociones_filter = [];

				for (var i = 0; i < this.promociones.length; i++) {
					if(this.promociones[i].deshabilitado == '1'){
						this.promociones_filter.push(this.promociones[i])
					}
				}
				break;
			case "pendientes":
				this.filterName = 'Pendientes';
				this.promociones_filter = [];
				for (var i = 0; i < this.promociones.length; i++) {
					if(this.promociones[i].estado == 0 && this.promociones[i].deshabilitado == '0'){
						this.promociones_filter.push(this.promociones[i])
					}
				}
				break;
			case "publicadas":
				this.filterName = 'Publicadas';
				this.promociones_filter = [];
				for (var i = 0; i < this.promociones.length; i++) {
					if(this.promociones[i].estado == 1 && this.promociones[i].deshabilitado == '0'){
						this.promociones_filter.push(this.promociones[i])
					}
				}
				break;
			default:
				// code...
				break;
		}
	}



	deshabilitarPromociones(){

		Swal.fire({
			title: '¿Quieres verificar las noticias vencidas?',
			text: "Las noticias con fecha de fin anteriores a HOY serán marcadas como vencidas!",
			icon: 'question',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-success',
				cancelButton: 'bg-danger'
			},
			confirmButtonText: 'Verificar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.pService.vigenciasPromocion().subscribe( (res:any) =>{
					Swal.close();
					Swal.fire('Validado', 'Se marcaron como vencidas: <b>'+ res.msg + ' publicaciones<b/>', 'success').then( () =>{
						if(res.msg > 0){
							this.obtenerPromociones();
						}
					});
				}, (err:any) =>{
					Swal.close();
					console.error(err.error);
					Swal.fire('Error', err.error.msg, 'error');
				})
			}
		})

	}


	obtenerSedesProveedor_agregarPromocion(proveedor){
		if(proveedor != 0){
			this.obtenerSedesProveedor(proveedor);
		}else{
			this.sedesProveedor = [];
		}
	}



	obtenerSedesProveedor(proveedor){
		this.sedesProveedor = [];
		this.promocionN.sede = 0;
		this.val_sede = false;

		this.validarProveedor('proveedor', proveedor, 'isint');
		this.cargandoSedesProveedores = true;
		this.prService.getProveedorSedes(proveedor).subscribe( (res:any) =>{
			this.cargandoSedesProveedores = false;
			this.sedesProveedor = res.sedes;
		}, err =>{
			this.cargandoSedesProveedores = false;
			console.error(err);
		})
	}

	mostrarErroresProveedorNuevo(){
		if(!this.val_nombre){
			this.nombreClass.push('is-invalid')
		}
		if(!this.val_proveedor){
			this.proveedorClass.push('is-invalid')
		}
		if(!this.val_sede){
			this.sedeClass.push('is-invalid')
		}
		if(!this.val_producto){
			this.productoClass.push('is-invalid')
		}
	}



	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}




	/*=============================
	=            MODAL            =
	=============================*/
	
	cerrarModal(){
		// this.modalService.dismiss();
		// this.modalService.dismissAll();
		this.modal.close('isaa');
	}

	openModal(content) {
		this.modal = this.modalService.open(content, {backdrop:'static', size: 'lg'});
		this.modal.result.then((result) => {
		}, (reason) => {
		});
		/*cuando se abre el modal, se obtienen las opciones para agregar una promoción*/
	    this.obtenerOpciones();
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}


	
	
	

}
