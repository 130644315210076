<div class="container-fluid">
  
  <div class="row">
    <div class="col-md-12">
      <h2>INICIO</h2>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12">

      <div class="jumbotron">
        <h1 class="display-4">Bienvenido</h1>
        <p class="lead">En esta sección de inicio puedes validar las promociones actuales para marcar como <b>vencidas</b> aquellas que ya hayan pasado su fecha de fin!</p>
        <hr class="my-4">
        <p>También puedes revisar primero cuales están activas, dirigiendore al módulo de "Promociones.</p>
        
        <button class="btn btn-warning btn-lg ml-3 float-center" (click)="deshabilitarPromociones()">Validar</button>
        <button class="btn btn-primary btn-lg ml-3 float-center" (click)="ir()">Ir a Noticias</button>
      </div>

    </div>
  </div>
</div>