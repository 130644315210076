import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ConfiguracionesService } from '../../../../services/configuraciones.service';


@Component({
	selector: 'app-filtros',
	templateUrl: './filtros.component.html',
	styles: []
})
export class FiltrosComponent implements OnInit {

  
	constructor(private configService: ConfiguracionesService,
				private modalService: NgbModal) {
		this.cargarFiltros();
	}

	ngOnInit(): void {}

	/*=================================
	=            VARIABLES            =
	=================================*/
	filtros:string[] = [];
	cargandoFiltros:boolean = true;

	/*=======================================
	=            OBTENER FILTROS            =
	=======================================*/
	cargarFiltros(){
		this.cargandoFiltros = true;
		this.configService.getFiltros().subscribe( (res:any) =>{
			this.cargandoFiltros = false;
			if(!res.error){
				this.filtros = res.filtros;
			}
		}, (err:any) => {
			this.cargandoFiltros = false;
			console.error(err);
		})
	}


	/*=======================================
	=            AGREGAR FILTROS            =
	=======================================*/
	modalAgregar:any;

	nombreAdd:string = "";
	nombreClass:string[] = ['form-control'];

	openModalAgregar(agregarA) {
		this.modalAgregar = this.modalService.open(agregarA, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {

		}, (reason) => {

		});
	}

	cerrarModalAgregar(){
		this.modalAgregar.close('cerrar');
		this.nombreAdd = "";
	}

	agregarFiltro(){
		let nombre = this.nombreAdd.trim();
		if(nombre){
			if(nombre.length > 0 && nombre.length < 101){

				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.configService.postFiltros(nombre).subscribe( (res:any) =>{
					Swal.close();
					if(!res.error){
						Swal.fire('Agregado', 'Característica agregada', 'success').then( () => {
							this.nombreAdd = "";
							this.cerrarModalAgregar();
							this.cargarFiltros();
						});
					}else{
						Swal.fire('Error', res.msg, 'error');
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}else{
				console.error('Error en cantidad de caracteres');
				this.nombreClass.push('is-invalid');
			}
		}else{
			console.error('No hay nombre');
			this.nombreClass.push('is-invalid');
		}
	}


	/*======================================
	=            EDITAR FILTROS            =
	======================================*/
	modalEditar:any;

	filtroActual = {
		codigoFiltro: "",
		nombreFiltro: ""
	}

	filtroEditar = {
		codigoFiltro: "",
		nombreFiltro: ""
	}
	

	openModalEditar(editarA, actual) {

		this.filtroEditar.codigoFiltro = actual.codigoFiltro;
		this.filtroEditar.nombreFiltro = actual.nombreFiltro;
		this.filtroActual.codigoFiltro = actual.codigoFiltro;
		this.filtroActual.nombreFiltro = actual.nombreFiltro;

		this.modalEditar = this.modalService.open(editarA, {backdrop:'static', size: 'lg'});
		this.modalEditar.result.then((result) => {

		}, (reason) => {

		});

	}

	cerrarModalEditar(){
		this.modalEditar.close('cerrar');
		this.filtroEditar.codigoFiltro = "";
		this.filtroEditar.nombreFiltro = "";
	}

	actualizarFiltro(){
		const nombre = this.filtroEditar.nombreFiltro.trim();

		if(nombre.length > 0 && nombre.length < 101){

			if(nombre != this.filtroActual.nombreFiltro.trim()){
				const data = {'nombre': nombre};
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.configService.putFiltros(this.filtroActual.codigoFiltro, data).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Actualizado', 'Característica actualizada', 'success').then( () => {
							this.cerrarModalEditar();
							this.cargarFiltros();
						});
					}else{
						Swal.fire('Error', res.msg, 'error')
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error')
				})

			}else{
				console.error('no hay cambios para realizar');
				Swal.fire('Info', 'No hay cambios para realizar', 'info');
			}
		}else{
			console.error('error en caracteres de nombre');
			this.nombreClass.push('is-invalid');

		}

	}

	/*========================================
	=            ELIMINAR FILTROS            =
	========================================*/
	eliminarFiltro(id){

		Swal.fire({
			title: '¿Quieres eliminar la característica?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.configService.deleteFiltros(id).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Característica eliminado', 'success').then( () => {
							this.cargarFiltros();
						})
					}else{
						Swal.fire('Error', res.msg, 'error');

					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}




	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	quitarInvalidName(){
		this.removeItemFromArr(this.nombreClass, 'is-invalid');
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}


}
