import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ClientesService } from '../../../../services/clientes.service';
import { ConfiguracionesService } from '../../../../services/configuraciones.service';
import { NotificacionesService } from '../../../../services/notificaciones.service';



@Component({
	selector: 'app-cliente',
	templateUrl: './cliente.component.html',
	styles: []
})
export class ClienteComponent implements OnInit {

	constructor(private clientesService: ClientesService,
				private rutaActiva:ActivatedRoute,
				private router:Router,
				private configService: ConfiguracionesService,
				private notificacionesService: NotificacionesService,
				private modalService: NgbModal) {

		this.idcliente = this.rutaActiva.snapshot.params.id;
		this.cargarSedes();
		this.cargarMembresias();
		this.cargarDeportes();
		this.cargarInstituciones();
		this.cargarLigas();

		this.cargarCliente(this.idcliente);
	}

	ngOnInit(): void {}

	/*=================================
	=            VARIABLES            =
	=================================*/
	idcliente:string;
	cliente = {};
	cargandoCliente:boolean = true;
	fileToUpload: File = null;

	/*opciones*/
	membresias:string[] = [];
	membresiasDisabled:string[] = [];
	sedes:string[] = [];
	deportes:string[] = [];
	instituciones:string[] = [];
	ligas:string[] = [];

	cargandoMembresias:boolean = true;
	cargandoSedes:boolean = true;
	cargandoDeportes:boolean = true;
	cargandoInstituciones:boolean = true;
	cargandoLigas:boolean = true;

	/*========================================
	=            OBTENER CLIENTES            =
	========================================*/
	cargarCliente(id){
		this.cargandoCliente = true;
		this.clientesService.getClientes(id).subscribe( (res:any) =>{
			this.cargandoCliente = false;
			this.inicilizarVariables(res.cliente);
			if(!res.error){
				this.cliente = res.cliente
			}
		}, (err:any) => {
			this.cargandoCliente = false;
			console.error(err);
		})
	}

	/*==========================================
	=            ACTUALIZAR CLIENTE            =
	==========================================*/
	clienteActual = {
		codigoMembresia: '0',
		codigoDeporte: '0',
		codigoInstitucion: '0',
		codigoLiga: '0',
		codigoSede: '0',
		nombresCliente: '',
		apellidosCliente: '',
		estadoCliente: '1',
		passwordCliente: "",
		emailCliente: ""
	}

	clienteEditar = {
		codigoMembresia: '0',
		codigoDeporte: '0',
		codigoInstitucion: '0',
		codigoLiga: '0',
		codigoSede: '0',
		nombresCliente: '',
		apellidosCliente: '',
		estadoCliente: '1',
		passwordCliente: "",
		emailCliente: ""
	}

	classEdit = {
		membresiaClass: ['form-control'],
		deporteClass: ['form-control'],
		institucionClass: ['form-control'],
		ligaClass: ['form-control'],
		sedeClass: ['form-control'],
		nombreClass: ['form-control'],
		apellidoClass: ['form-control'],
		estadoClass: ['form-control'],
		emailClass: ['form-control'],
		passClass: ['form-control']
	}


	actualizarCliente(){
		this.prepararData();
		let enabled_act = this.validarData(this.clienteActual, this.clienteEditar);

		if(enabled_act){
			const data = {
				'codigoMembresia': this.clienteEditar.codigoMembresia, 
				'codigoDeporte' : this.clienteEditar.codigoDeporte,
				'codigoInstitucion' : this.clienteEditar.codigoInstitucion,
				'codigoLiga' : this.clienteEditar.codigoLiga,
				'codigoSede' : this.clienteEditar.codigoSede,
				'nombresCliente' : this.clienteEditar.nombresCliente,
				'apellidosCliente' : this.clienteEditar.apellidosCliente,
				'estadoCliente' : this.clienteEditar.estadoCliente,
				'passwordCliente' : this.clienteEditar.passwordCliente,
				'emailCliente' : this.clienteEditar.emailCliente
			};

			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.clientesService.putClientes(this.idcliente, data).subscribe( (res:any) => {
				Swal.close();
				if(!res.error){
					Swal.fire('Actualizado', 'Cliente actualizado', 'success').then( () => {
						this.cargarCliente(this.idcliente);
					});
				}else{
					Swal.fire('Error', res.msg, 'error')
				}
			}, (err:any) =>{
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error')
			})

		}else{
			console.error('Error en formulario');
		}
	}

	/*==================================
	=            MULTIMEDIA            =
	==================================*/
	public imagePath;
  	imgURL: any;
  	public message: string;

  	multimediaChanged = {
		imagen: ""
	};

	handleFileInput(files: FileList) {
		/*verificar si hay un archivo*/
		if (files.length === 0)
			return;
		/*validar que sea imagen*/
		var mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.message = "Only images are supported.";
			return;
		}
		var reader = new FileReader();
		// this.imagePath = files;
		reader.readAsDataURL(files[0]); 
		reader.onload = (_event) => { 
			this.imgURL = reader.result; 
		}
		this.fileToUpload = files[0];
	}

	uploadImagen() {

		if(this.fileToUpload){
			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.clientesService.putClienteImagen(this.fileToUpload, this.idcliente, this.multimediaChanged.imagen).subscribe( (res:any) => {
				if(!res.error){
					Swal.close();
					Swal.fire('Actualizado', 'La imagen se actualizó', 'success').then(()=>{
						this.imgURL = "";
						this.fileToUpload = null;
						this.cargarCliente(this.idcliente);
					})
				}else{
					Swal.close();
					Swal.fire('Error', 'No se actualizó', 'error');
				}
			}, err => {
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error');
				
			});
		}else{
			console.error('no hay archivo');
		}
	}


	/*========================================
	=            ELIMINAR CLIENTE            =
	========================================*/
	eliminarCliente(){

		Swal.fire({
			title: '¿Quieres eliminar el cliente?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.clientesService.deleteClientes(this.idcliente).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Cliente eliminado', 'success').then( () => {
							this.router.navigateByUrl('main/clientes');
						})
					}else{
						Swal.fire('Error', res.msg, 'error');
					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}


	/*======================================
	=            NOTIFICACIONES            =
	======================================*/
	modalNotificaciones:any;

	notificacion = {
		subtitulo: '',
		contenido: ''
	}

	notificacionClass = {
		subtitulo: ['form-control'],
		contenido: ['form-control']
	}

	openModalNotificaciones(notificaciones) {
		this.modalNotificaciones = this.modalService.open(notificaciones, {backdrop:'static', size: 'lg'});
	}

	cerrarModalNotificaciones(){
		this.modalNotificaciones.close('cerrar');
		this.notificacion = {subtitulo: '', contenido: ''}
	}

	enviarNotificacion(){

		this.notificacion.subtitulo = this.notificacion.subtitulo.trim();
		this.notificacion.contenido = this.notificacion.contenido.trim();

		if(this.notificacion.subtitulo.length > 0  && this.notificacion.subtitulo.length < 31 ){
			if(this.notificacion.contenido.length > 0  && this.notificacion.contenido.length < 201 ){

				Swal.fire({
					allowOutsideClick: false,
					text: 'Enviando notificación!',
				})
				Swal.showLoading();
				const data = {
					"subtitulo": this.notificacion.subtitulo,
					"contenido": this.notificacion.contenido,
					"cliente": this.idcliente,
					"path": 'promociones/'
				}

				this.notificacionesService.sendNotification_cliente(data).subscribe( (res:any) => {
					Swal.close();
					Swal.fire('Enviado', 'Notificación enviada a: <b>' + res.recipients + ' cliente</b>' , 'success').then(() => {
						this.cerrarModalNotificaciones();
					});
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', 'No se pudo enviar la notificación', 'error');
				})


			}else{
				this.notificacionClass.contenido.push('is-invalid');
				console.error('error en contenido');
			}
		}else{
			this.notificacionClass.subtitulo.push('is-invalid');
			console.error('error en titulo');
		}


	
	}


	/*================================
	=            OPCIONES            =
	================================*/
	cargarSedes(){
		this.cargandoSedes = true;
		this.configService.getSedes().subscribe( (res:any) => {
			this.cargandoSedes = false;
			this.sedes = res.sedes;
		}, (err:any) => {
			this.cargandoSedes = false;
			console.error(err.error.msg);
		})
	}

	cargarMembresias(){
		this.cargandoMembresias = true;
		this.configService.getMembresias().subscribe( (res:any) => {
			this.cargandoMembresias = false;
			let data = res.membresias;
			for (var i = 0; i < data.length; i++) {
				if(data[i].estadoMembresia == 1){
					this.membresias.push(data[i]);
				}else{
					this.membresiasDisabled.push(data[i]);
				}
			}
			// this.membresias = res.membresias;
		}, (err:any) => {
			this.cargandoMembresias = false;
			console.error(err.error.msg);
		})
	}

	cargarDeportes(){
		this.cargandoDeportes = true;
		this.configService.getDeportes().subscribe( (res:any) => {
			this.cargandoDeportes = false;
			this.deportes = res.deportes;
		}, (err:any) => {
			this.cargandoDeportes = false;
			console.error(err.error.msg);
		})
	}

	cargarInstituciones(){
		this.cargandoInstituciones = true;
		this.configService.getInstituciones().subscribe( (res:any) => {
			this.cargandoInstituciones = false;
			this.instituciones = res.instituciones;
		}, (err:any) => {
			this.cargandoInstituciones = false;
			console.error(err.error.msg);
		})
	}

	cargarLigas(){

		this.cargandoLigas = true;
		this.configService.getLigas().subscribe( (res:any) => {
			this.cargandoLigas = false;
			this.ligas = res.ligas;
		}, (err:any) => {
			this.cargandoLigas = false;
			console.error(err.error.msg);
		})	
	}




	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}


	validarData(actual, editar){
		let a = 9;
		let cambios = false;

		// vvalidar valores
		if( editar.codigoMembresia.length != 1 || editar.codigoMembresia == "0"){
			a--;
			this.classEdit.membresiaClass.push('is-invalid');
		}

		if( !editar.codigoDeporte || editar.codigoDeporte == "0"){
			a--;
			this.classEdit.deporteClass.push('is-invalid');
		}

		if( !editar.codigoInstitucion || editar.codigoInstitucion == "0"){
			a--;
			this.classEdit.institucionClass.push('is-invalid');
		}

		if( !editar.codigoLiga || editar.codigoLiga == "0"){
			a--;
			this.classEdit.ligaClass.push('is-invalid');
		}

		if( !editar.codigoSede || editar.codigoSede == "0"){
			a--;
			this.classEdit.sedeClass.push('is-invalid');
		}

		if( editar.nombresCliente.length < 1 || editar.nombresCliente.length > 50){
			a--;
			this.classEdit.nombreClass.push('is-invalid');
		}

		if( editar.apellidosCliente.length < 1 || editar.apellidosCliente.length > 50){
			a--;
			this.classEdit.apellidoClass.push('is-invalid');
		}

		if( editar.passwordCliente.length != 5){
			a--;
			this.classEdit.passClass.push('is-invalid');
		}

		if(editar.emailCliente.length > 0 && editar.emailCliente.length > 50){
			a--;
			this.classEdit.emailClass.push('is-invalid');
		}

		//validar cambios

		if(
			editar.codigoMembresia != actual.codigoMembresia ||
			editar.codigoDeporte != actual.codigoDeporte ||
			editar.codigoInstitucion != actual.codigoInstitucion ||
			editar.codigoLiga != actual.codigoLiga ||
			editar.codigoSede != actual.codigoSede ||
			editar.nombresCliente != actual.nombresCliente ||
			editar.apellidosCliente != actual.apellidosCliente ||
			editar.passwordCliente != actual.passwordCliente ||
			editar.emailCliente != actual.emailCliente ||
			editar.estadoCliente != actual.estadoCliente
			){
			cambios = true;
		}else{
			cambios = false;
			console.warn('No hay cambios');
			Swal.fire('Alerta', 'No hay cambios para realizar', 'info');
		}


		if( a == 9 && cambios){
			return true;
		}else{
			return false;
		}



	}

	quitarInvalidTag(tipo){
		switch (tipo) {
			case "nombre":
				this.removeItemFromArr(this.classEdit.nombreClass, 'is-invalid');
				break;
			case "apellido":
				this.removeItemFromArr(this.classEdit.apellidoClass, 'is-invalid');
				break;
			case "membresia":
				this.removeItemFromArr(this.classEdit.membresiaClass, 'is-invalid');
				break;
			case "sede":
				this.removeItemFromArr(this.classEdit.sedeClass, 'is-invalid');
				break;
			case "deporte":
				this.removeItemFromArr(this.classEdit.deporteClass, 'is-invalid');
				break;
			case "institucion":
				this.removeItemFromArr(this.classEdit.institucionClass, 'is-invalid');
				break;
			case "liga":
				this.removeItemFromArr(this.classEdit.ligaClass, 'is-invalid');
				break;
			case "email":
				this.removeItemFromArr(this.classEdit.emailClass, 'is-invalid');
				break;
			case "password":
				this.removeItemFromArr(this.classEdit.passClass, 'is-invalid');
				break;
			case "titulonoti":
				this.removeItemFromArr(this.notificacionClass.subtitulo, 'is-invalid');
				break;
			case "contenidonoti":
				this.removeItemFromArr(this.notificacionClass.contenido, 'is-invalid');
				break;
			default:
				console.error('no existe tipo tag');
				break;
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	inicilizarVariables(data){

		this.clienteEditar.codigoMembresia = data.codigoMembresia.trim();
		this.clienteEditar.codigoDeporte = data.codigoDeporte.trim();
		this.clienteEditar.codigoInstitucion = data.codigoInstitucion.trim();
		this.clienteEditar.codigoLiga = data.codigoLiga.trim();
		this.clienteEditar.codigoSede = data.codigoSede.trim();
		this.clienteEditar.nombresCliente = data.nombresCliente.trim();
		this.clienteEditar.apellidosCliente = data.apellidosCliente.trim();
		this.clienteEditar.estadoCliente = data.estadoCliente.trim();
		this.clienteEditar.emailCliente = data.emailCliente.trim();
		this.clienteEditar.passwordCliente = data.passwordCliente.trim();

		this.clienteActual.codigoMembresia = data.codigoMembresia.trim();
		this.clienteActual.codigoDeporte = data.codigoDeporte.trim();
		this.clienteActual.codigoInstitucion = data.codigoInstitucion.trim();
		this.clienteActual.codigoLiga = data.codigoLiga.trim();
		this.clienteActual.codigoSede = data.codigoSede.trim();
		this.clienteActual.nombresCliente = data.nombresCliente.trim();
		this.clienteActual.apellidosCliente = data.apellidosCliente.trim();
		this.clienteActual.estadoCliente = data.estadoCliente.trim();
		this.clienteActual.emailCliente = data.emailCliente.trim();
		this.clienteActual.passwordCliente = data.passwordCliente.trim();

		this.multimediaChanged.imagen = data.qRCliente;
	}

	prepararData(){
		this.clienteEditar.codigoMembresia = this.clienteEditar.codigoMembresia.trim();
		this.clienteEditar.codigoDeporte = this.clienteEditar.codigoDeporte.trim();
		this.clienteEditar.codigoInstitucion = this.clienteEditar.codigoInstitucion.trim();
		this.clienteEditar.codigoLiga = this.clienteEditar.codigoLiga.trim();
		this.clienteEditar.codigoSede = this.clienteEditar.codigoSede.trim();
		this.clienteEditar.nombresCliente = this.clienteEditar.nombresCliente.trim();
		this.clienteEditar.apellidosCliente = this.clienteEditar.apellidosCliente.trim();
		this.clienteEditar.estadoCliente = this.clienteEditar.estadoCliente.trim();
		this.clienteEditar.emailCliente = this.clienteEditar.emailCliente.trim();
		this.clienteEditar.passwordCliente = this.clienteEditar.passwordCliente.trim();

	}
	
	
	
	

}
