import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ProveedoresService } from '../../../../services/proveedores.service';
import { ConfiguracionesService } from '../../../../services/configuraciones.service';


@Component({
	selector: 'app-proveedores',
	templateUrl: './proveedores.component.html',
	styles: []
})
export class ProveedoresComponent implements OnInit {

	constructor(private proveedoresService: ProveedoresService,
				private rutaActiva:ActivatedRoute,
				private router:Router,
				private configService: ConfiguracionesService,
				private modalService: NgbModal) {

		this.cargarProveedores();
	}

	ngOnInit(): void {}


	/*=================================
	=            VARIABLES            =
	=================================*/
	proveedores = [];
	cargandoProveedores:boolean = true;
	fileToUpload: File = null;

	/*opciones*/
	categorias:string[] = [];
	sedes:string[] = [];

	cargandoCategorias:boolean = true;
	cargandoSedes:boolean = true;


	/*===========================================
	=            OBTENER PROVEEDORES            =
	===========================================*/
	cargarProveedores(){
		this.cargandoProveedores = true;
		this.proveedoresService.getProveedores().subscribe( (res:any) =>{
			console.log(res);

			if(!res.error){
				this.proveedores = res.proveedores;
			}

			this.cargandoProveedores = false;
		}, (err:any) => {
			this.cargandoProveedores = false;
			console.error(err);
		})
	}



	/*========================================
	=            AGREGAR CLIENTES            =
	========================================*/
	modalAgregar:any;

	proveedorAdd = {
		codigoCategoria: '0',
		nombreProveedor: '',
		emailProveedor: '',
		telefonoProveedor: '',
		estadoProveedor: '1'
	}

	clasesAdd = {
		categoriaClass: ['form-control'],
		sedeClass: ['form-control'],
		nombreClass: ['form-control'],
		emailClass: ['form-control'],
		telefonoClass: ['form-control'],
		estadoClass: ['form-control']
	}

	openModalAgregar(agregarA) {
		this.cargarCategorias();
		this.cargarSedes();

		this.modalAgregar = this.modalService.open(agregarA, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {
		}, (reason) => {
		});
	}

	cerrarModalAgregar(){
		this.modalAgregar.close('cerrar');
		this.proveedorAdd = {
			codigoCategoria: '0',
			nombreProveedor: '',
			emailProveedor: '',
			telefonoProveedor: '',
			estadoProveedor: '1'
		}
	}

	agregarProveedor(){

		this.proveedorAdd.codigoCategoria = this.proveedorAdd.codigoCategoria.trim();
		this.proveedorAdd.nombreProveedor = this.proveedorAdd.nombreProveedor.trim();
		this.proveedorAdd.emailProveedor = this.proveedorAdd.emailProveedor.trim();
		this.proveedorAdd.telefonoProveedor = this.proveedorAdd.telefonoProveedor.trim();
		this.proveedorAdd.estadoProveedor = this.proveedorAdd.estadoProveedor.trim();
		let enabled_add = this.validarDatos();
		if(enabled_add){
			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.proveedoresService.postProveedores(this.proveedorAdd).subscribe( (res:any) =>{
				Swal.close();
				if(!res.error){
					Swal.fire('Agregado', 'Proveedor agregado', 'success').then( () => {
						this.cerrarModalAgregar();
						let ruta = 'main/socios/' + res.msg;
						this.router.navigateByUrl(ruta);
					});
				}else{
					Swal.fire('Error', res.msg, 'error');
				}
			}, (err:any) =>{
				Swal.close();
				console.error(err);
				if(err.error.msg){
					Swal.fire('Error', err.error.msg, 'error');
				}else{

					const e = err.error.emailProveedor + ' | ' + err.error.telefonoProveedor;
					Swal.fire('Error', e, 'error');
				}
				
			})
		}else{
			console.error('error en formulario');
		}
	}

	/*================================
	=            OPCIONES            =
	================================*/
	cargarSedes(){
		this.cargandoSedes = true;
		this.configService.getSedes().subscribe( (res:any) => {
			this.cargandoSedes = false;
			this.sedes = res.sedes;
		}, (err:any) => {
			this.cargandoSedes = false;
			console.error(err.error.msg);
		})
	}

	cargarCategorias(){
		this.cargandoCategorias = true;
		this.configService.getCategorias(null, 'estado', 1).subscribe( (res:any) => {
			this.cargandoCategorias = false;
			this.categorias = res.categorias;
		}, (err:any) => {
			this.cargandoCategorias = false;
			console.error(err.error.msg);
		})
	}

	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	validarDatos(){
		let a = 4;

		if(this.proveedorAdd.codigoCategoria.length == 0 || this.proveedorAdd.codigoCategoria == "0"){
			this.clasesAdd.categoriaClass.push('is-invalid');
			a--;
		}

		if(this.proveedorAdd.nombreProveedor.length == 0 || this.proveedorAdd.nombreProveedor.length > 50 ){
			this.clasesAdd.nombreClass.push('is-invalid');
			a--;
		}

		if(this.proveedorAdd.emailProveedor.length > 0 ) {
			if(this.proveedorAdd.emailProveedor.length > 50 ){
				this.clasesAdd.emailClass.push('is-invalid');
				a--;
			}
		}

		if(this.proveedorAdd.telefonoProveedor.length > 0){
			if(this.proveedorAdd.telefonoProveedor.length != 10 ){
				this.clasesAdd.telefonoClass.push('is-invalid');
				a--;
			}
		}


		if(a == 4){
			return true;
		}else{
			return false;
		}
	}

	quitarInvalidTag(tipo){
		switch (tipo) {
			case "categoria":
				this.removeItemFromArr(this.clasesAdd.categoriaClass, 'is-invalid');
				break;
			case "sede":
				this.removeItemFromArr(this.clasesAdd.sedeClass, 'is-invalid');
				break;
			case "nombre":
				this.removeItemFromArr(this.clasesAdd.nombreClass, 'is-invalid');
				break;
			case "email":
				this.removeItemFromArr(this.clasesAdd.emailClass, 'is-invalid');
				break;
			case "telefono":
				this.removeItemFromArr(this.clasesAdd.telefonoClass, 'is-invalid');
				break;
			default:
				console.error('no existe tipo tag');
				break;
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}


}
