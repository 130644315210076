<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<h3>SEDES</h3>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-app bg-success ml-0" (click)="openModalAgregar(agregarA)">
				<i class="fas fa-plus"></i>
				Agregar
			</button>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">

			<div class="card card-dark">
				<div class="card-header">
					<h3>Listado de sedes</h3>
				</div>


				<div class="card-body" *ngIf="cargandoSedes">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>


				<div class="card-body table-responsive" *ngIf="!cargandoSedes">
          <div *ngIf="sedes.length == 0" class="alert"><i class="fas fa-exclamation-triangle"></i>No hay información para mostrar!</div>
					<table *ngIf="sedes.length > 0" class="table table-striped">
						<thead>
							<tr class="bg-secondary">
								<th>#</th>
								<th>SEDE</th>
								<th>ESTADO</th>
								<th>PAÍS</th>
								<th>OPCIONES</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let a of sedes; let i = index">
								<th>{{i + 1}}</th>
								<th>{{a.nombreSede}}</th>
								<th>{{a.nombreEstado}}</th>
								<th>{{a.nombrePais}}</th>
								<th>
									<button class="btn btn-primary btn-sm mr-2" title="editar" (click)="openModalEditar(editarA, a)"><i class="fas fa-edit"></i></button>
									<button class="btn btn-danger btn-sm mr-2" title="eliminar" (click)="eliminarSede(a.codigoSede)"><i class="fas fa-times"></i></button>
								</th>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-footer"></div>
			</div>

		</div>
	</div>
</div>




<!--============================
=            MODALS            =
=============================-->

<ng-template #agregarA let-modalAgregar>

   <div class="modal-header bg-dark">
      <h5 class="modal-title">AGREGAR SEDE</h5>
      <button type="button" class="close" (click)="cerrarModalAgregar()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>


    <div class="modal-body">

      <div class="row">
        <div class="col-md-12">
          <div class="callout callout-info">
            <h5>Información!</h5>
            <p>Los campos marcados con <b>*</b> son obligatorios.</p>
          </div>
        </div>
      </div>

      <div class="row">

      	<div class="col-md-6">
          <div class="form-group">
            <label>País *</label>

            <div *ngIf="cargandoPaises" class="spinner-border spinner-border-sm" role="status">
            	<span class="sr-only">Loading...</span>
            </div>
            <select *ngIf="!cargandoPaises" [ngClass]='paisClass' style="width: 100%;" [(ngModel)]="paisAdd" (change)="quitarInvalidTag('pais')" disabled>
              <option *ngFor="let a of paises" [value]="a.codigoPais">{{a.nombrePais}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">

            <label>Estado *</label>
            <div *ngIf="cargandoEstados" class="spinner-border spinner-border-sm" role="status">
            	<span class="sr-only">Loading...</span>
            </div>
           
            <select *ngIf="!cargandoEstados" [ngClass]='estadoClass' style="width: 100%;" [(ngModel)]="estadoAdd" (change)="quitarInvalidTag('estado')">
              <option selected value="0">-- Selecciona una estado --</option>
              <option *ngFor="let a of estados" [value]="a.codigoEstado">{{a.nombreEstado}}</option>
            </select>

          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label>Nombre sede *  <small>(Max 50 caracteres)</small></label>
            <input type="text" [(ngModel)]="nombreAdd" [ngClass]='nombreClass' placeholder="Nombre" maxlength="50" (keyup)="quitarInvalidTag('nombre')">
          </div>
        </div>

        
      </div>     

    </div>


    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalAgregar()">Cerrar</button>
      <button type="button" class="btn btn-success" (click)="agregarSedes()"><i class="fas fa-save"></i> Guardar</button>
    </div>
</ng-template>


<ng-template #editarA let-modalEditar>

   <div class="modal-header bg-dark">
      <h5 class="modal-title">EDITAR SEDE</h5>
      <button type="button" class="close" (click)="cerrarModalEditar()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>


    <div class="modal-body">

      <div class="row">
      <div class="col-md-12">
        <div class="callout callout-info">
          <h5>Información!</h5>
          <p>Los campos marcados con <b>*</b> son obligatorios.</p>
        </div>
      </div>
    </div>

      <div class="row">

      	<div class="col-md-6">
          <div class="form-group">
            <label>País *</label>

            <div *ngIf="cargandoPaises" class="spinner-border spinner-border-sm" role="status">
            	<span class="sr-only">Loading...</span>
            </div>
            <select *ngIf="!cargandoPaises" [ngClass]='paisClass' style="width: 100%;" [(ngModel)]="paisAdd" (change)="quitarInvalidTag('pais')" disabled>
              <option *ngFor="let a of paises" [value]="a.codigoPais">{{a.nombrePais}}</option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">

            <label>Estado *</label>
            <div *ngIf="cargandoEstados" class="spinner-border spinner-border-sm" role="status">
            	<span class="sr-only">Loading...</span>
            </div>
           
            <select *ngIf="!cargandoEstados" [ngClass]='estadoClass' style="width: 100%;" [(ngModel)]="sedeEditar.codigoEstado" (change)="quitarInvalidTag('estado')">
              <option selected value="0">-- Selecciona una estado --</option>
              <option *ngFor="let a of estados" [value]="a.codigoEstado">{{a.nombreEstado}}</option>
            </select>

          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>Nombre sede * <small>(Max 50 caracteres)</small></label>
            <input type="text" [(ngModel)]="sedeEditar.nombreSede" [ngClass]='nombreClass' placeholder="Nombre" maxlength="50"  (keyup)="quitarInvalidTag('nombre')">
          </div>
        </div>
      </div>     

    </div>


    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalEditar()">Cerrar</button>
      <button type="button" class="btn btn-primary" (click)="actualizarSede()"><i class="fas fa-edit"></i> Actualizar</button>
    </div>
</ng-template>







