import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';


import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})

export class AuthService {

	constructor(
		private http: HttpClient,
		private router:Router) {
		this.leerToken();
	}

	/*constantes*/
	APIKEY:string = environment.APIKEY;
	APIURL:string = environment.APIURL;



	userToken:string = "";

	logout(){
		localStorage.removeItem('pp-token');
		this.userToken = "";
	}


	obtenerToken(usuario, constrasena){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY
		})

		let url:string = this.APIURL + 'login';
		let body = new HttpParams();
			body = body.append('usuario', usuario);
			body = body.append('contrasena', constrasena);

		return this.http.post(url, body, { headers}).pipe(map(res =>{
			if(res[0].res === 'ok'){
				this.guardarToken(res[0].msg);
			}
			return res;
		}));
	}

	private guardarToken(token:string){
		this.userToken = token;
		localStorage.setItem('pp-token', token);
	}

	leerToken(){
		if(localStorage.getItem('pp-token')){
			this.userToken = localStorage.getItem('pp-token');
		}else{
			this.userToken = "";
		}
		return this.userToken;
	}

	validarAutenticacion(): boolean{
		return this.userToken.length == 32;
	}


	/*================================================
	=            VALIDAR QUE TOKEN ACTIVO            =
	================================================*/
	public validarClienteEstado(){

		return new Promise<boolean>( resolve =>{
			let URL = this.APIURL + '/login';
			const headers = new HttpHeaders({
				'pp-apikey': this.APIKEY,
				'pp-token': this.userToken
			});

			this.http.get(URL, {headers}).subscribe( (res:any) => {
				if(!res.error && res.msg){
					resolve(true);
				}else{
					this.logout();
					this.router.navigateByUrl('/login')
					resolve(false);
				}
			}, (err:any) =>{
				this.logout();
				this.router.navigateByUrl('/login')
				resolve(false);
			})
		})
	}
}
