import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class NotificacionesService {

	constructor(private http:HttpClient) { }
	
	// message = { 
	// 	app_id: "b3684de6-7807-4fe7-84a3-6bc15f240af8",
	// 	contents: {"en": "English Message"},
	// 	included_segments: ["All"]
	// };


	/*=====================================================
	=            ENVIAR NOTIFICACIONES A TODOS            =
	=====================================================*/
	headers = {"Content-Type": "application/json; charset=utf-8", "Authorization": "Basic NDYzNTI4ZTktOGFmYS00ZTI2LWIxZDItODRkZTFhY2Q5ODMz"};
	URL = 'https://onesignal.com/api/v1/notifications';

	sendNotification(data) {
		let headers = this.headers;

		let body = {
			"app_id": "b3684de6-7807-4fe7-84a3-6bc15f240af8",
			"included_segments": ["All"],
			"subtitle": {"en": data.subtitulo},
			"contents": {"en": data.contenido},
			"data": {"path": data.path},
			'ios_badgeType': "Increase",
            'ios_badgeCount': 1
		}

		return this.http.post(this.URL, body, { headers } );

	};


	sendNotification_cliente(data) {
		let headers = this.headers;

		let body = {
			"app_id": "b3684de6-7807-4fe7-84a3-6bc15f240af8",
			"filters": [{"field": "tag", "key": "cliente", "relation": "=", "value": data.cliente}],
			"subtitle": {"en": data.subtitulo},
			"contents": {"en": data.contenido},
			"data": {"path": data.path},
			'ios_badgeType': "Increase",
            'ios_badgeCount': 1
		}
		return this.http.post(this.URL, body, {headers } );

	};


	/*==========================================================
	=            ENVIAR NOTIFICACIONES A UN USUARIO            =
	==========================================================*/
	
	
	
	
	
	
}
