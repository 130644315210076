import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


/*SERVICIOS*/

/*COMPONENETES*/
import { AppComponent } from './app.component';
import { NavbarComponent } from './componentes/main/shared/navbar/navbar.component';
import { LoginComponent } from './componentes/login/login/login.component';
import { HomeComponent } from './componentes/main/pages/home/home.component';

/*RUTAS*/
import { APP_ROUTING } from './app.routes';
import { PromocionesComponent } from './componentes/main/pages/promociones/promociones.component';
import { PromocionComponent } from './componentes/main/pages/promociones/promocion.component';
import { ProveedoresComponent } from './componentes/main/pages/proveedores/proveedores.component';
import { ClientesComponent } from './componentes/main/pages/clientes/clientes.component';
import { ComprasComponent } from './componentes/main/pages/compras/compras.component';
import { ConfiguracionesComponent } from './componentes/main/pages/configuraciones/configuraciones.component';
import { FooterComponent } from './componentes/main/shared/footer/footer.component';
import { MainComponent } from './componentes/main/main.component';
import { ListadopromocionComponent } from './componentes/main/pages/promociones/listadopromocion.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AsociacionesComponent } from './componentes/main/pages/configuraciones/asociaciones.component';
import { InstitucionesComponent } from './componentes/main/pages/configuraciones/instituciones.component';
import { LigasComponent } from './componentes/main/pages/configuraciones/ligas.component';
import { MembresiasComponent } from './componentes/main/pages/configuraciones/membresias.component';
import { CategoriasComponent } from './componentes/main/pages/configuraciones/categorias.component';
import { CategoriaslistadoComponent } from './componentes/main/pages/configuraciones/categoriaslistado.component';
import { CategoriaComponent } from './componentes/main/pages/configuraciones/categoria.component';
import { FiltrosComponent } from './componentes/main/pages/configuraciones/filtros.component';
import { DeportesComponent } from './componentes/main/pages/configuraciones/deportes.component';
import { SedesComponent } from './componentes/main/pages/configuraciones/sedes.component';
import { ProductosComponent } from './componentes/main/pages/configuraciones/productos.component';
import { ClienteComponent } from './componentes/main/pages/clientes/cliente.component';
import { ProveedorComponent } from './componentes/main/pages/proveedores/proveedor.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    HomeComponent,
    PromocionesComponent,
    ProveedoresComponent,
    ClientesComponent,
    ComprasComponent,
    ConfiguracionesComponent,
    FooterComponent,
    MainComponent,
    PromocionComponent,
    ListadopromocionComponent,
    AsociacionesComponent,
    InstitucionesComponent,
    LigasComponent,
    MembresiasComponent,
    CategoriasComponent,
    CategoriaslistadoComponent,
    CategoriaComponent,
    FiltrosComponent,
    DeportesComponent,
    SedesComponent,
    ProductosComponent,
    ClienteComponent,
    ProveedorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    APP_ROUTING,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
