import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ConfiguracionesService } from '../../../../services/configuraciones.service';


@Component({
	selector: 'app-categoria',
	templateUrl: './categoria.component.html',
	styles: []
})
export class CategoriaComponent implements OnInit {

	constructor(private configService: ConfiguracionesService,
				private modalService: NgbModal,
				private router: Router,
				private activedRoute: ActivatedRoute) {
		
	}

	ngOnInit(): void {
		this.idcategoria = this.activedRoute.snapshot.params.id;

		if( parseInt(this.idcategoria) % 1 != 0){
			this.router.navigateByUrl('main/configuraciones/categorias');
		}


		this.cargarCategoria( this.idcategoria);
		this.cargarCategoriaFiltros(this.idcategoria);
		this.cargarFiltros();
	}


	/*=================================
	=            VARIABLES            =
	=================================*/
	idcategoria:string;
	categorias = {};
	filtros:string[] = [];
	filtrosOpciones:string[] = [];
	cargandoCategoria:boolean = true;
	cargandoCategoriaFiltros:boolean = true;
	cargandoFiltros:boolean = true;

	nombreClass:string[] = ['form-control'];
	filtroClass:string[] = ['custom-select'];

	
	/*=========================================
	=            OBTENER CATEGORÍA            =
	=========================================*/
	cargarCategoria(id){
		this.cargandoCategoria = true;
		this.configService.getCategorias(id).subscribe( (res:any) =>{
			this.cargandoCategoria = false;
			if(!res.error){
				if( res.categoria.nombreCategoria.length > 0){
					this.categoriaActual.nombre = res.categoria.nombreCategoria;
					this.categoriaActual.estado = res.categoria.estadoCategoria;
					this.categoriaActual.imagen = res.categoria.imgCatergoria;

					this.categoriaChanged.nombre = res.categoria.nombreCategoria;
					this.categoriaChanged.estado = res.categoria.estadoCategoria;
					this.categoriaChanged.imagen = res.categoria.imgCatergoria;
				}else{
					this.router.navigateByUrl('main/configuraciones/categorias');
				}
			}
		}, (err:any) => {
			this.cargandoCategoria = false;
			console.error(err);
			this.router.navigateByUrl('main/configuraciones/categorias');
		})
	}

	/*=================================================
	=            OBTENER CATEGORIA FILTROS            =
	=================================================*/
	cargarCategoriaFiltros(id){
		this.cargandoCategoriaFiltros = true;
		this.configService.getCategorias(id, 'filter', 'filtros').subscribe( (res:any) =>{
			this.cargandoCategoriaFiltros = false;
			if(!res.error){
				this.filtros = res.filtros;
			}
		}, (err:any) => {
			console.error(err);
			this.cargandoCategoriaFiltros = false;
		})
	}

	/*=======================================
	=            OBTENER FILTROS            =
	=======================================*/
	cargarFiltros(){
		this.cargandoFiltros = true;
		this.configService.getFiltros().subscribe( (res:any) =>{
			this.cargandoFiltros = false;
			if(!res.error){
				this.filtrosOpciones = res.filtros;
			}
		}, (err:any) => {
			console.error(err);
			this.cargandoFiltros = false;
		})
	}

	/*==========================================
	=            EDITAR INSTITUCIÓN            =
	==========================================*/
	/**datos actuales**/
	categoriaActual = {
		nombre: "",
		estado: "",
		imagen: ""
	}

	/**datos editados**/
	categoriaChanged = {
		nombre: "",
		estado: "",
		imagen: ""
	}

	actualizarCategoriaGeneral(){

		this.categoriaChanged.nombre = this.categoriaChanged.nombre.trim();

		if(this.categoriaChanged.nombre.length > 0 && this.categoriaChanged.nombre.length < 51){

			if(this.categoriaChanged.nombre != this.categoriaActual.nombre.trim() || this.categoriaChanged.estado != this.categoriaActual.estado){

				const data = {
					'nombre': this.categoriaChanged.nombre,
					'estado': this.categoriaChanged.estado
				};

				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.configService.putCategorias(this.idcategoria, data).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Actualizado', '', 'success').then( () => {
							this.cargarCategoria(this.idcategoria);
						});
					}else{
						Swal.fire('Error', res.msg, 'error')
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error')
				})

			}else{
				console.error('no hay cambios para realizar');
				Swal.fire('Info', 'No hay cambios para realizar', 'info');
			}
		}else{
			console.error('error en caracteres de nombre');
			this.nombreClass.push('is-invalid');

		}
	}

	/*======================================
	=            AGREGAR FILTRO            =
	======================================*/
	filtroAdd:string = "0";

	agregarFiltro(){
		if(this.filtroAdd != '0'){
			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.configService.postCategoriaFiltros(this.idcategoria, this.filtroAdd).subscribe( (res:any) => {
				Swal.close();
				Swal.fire('Agregado', 'Filtro agregado', 'success').then( () => {
					this.cargarCategoriaFiltros(this.idcategoria);
					this.filtroAdd = '0';
				})
			}, (err:any) => {
				console.error(err);
				Swal.close();
				Swal.fire('Error', err.error.msg, 'error');
			})
		}else{
			console.error('error en filtro');
			this.filtroClass.push('is-invalid');
		}
	}

	/*=======================================
	=            ELIMINAR FILTRO            =
	=======================================*/
	eliminarFiltro(filtro){

		Swal.fire({
			title: '¿Quieres eliminar el filtro?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.configService.deleteCategoriaFiltros(this.idcategoria, filtro).subscribe( (res:any) => {
					Swal.close();
					Swal.fire('Eliminado', 'Filtro eliminado correctamente', 'success').then( () => {
						this.cargarCategoriaFiltros(this.idcategoria);
					});
				}, (err:any) => {
					console.error(err);
					Swal.close();
					Swal.fire('Error', err.error.msg, 'error');
				})
			}
		})
	}

	/*==================================
	=            MULTIMEDIA            =
	==================================*/
	public imagePath;
  	imgURL: any;
  	public message: string;
  	fileToUpload: File = null;

	handleFileInput(files: FileList) {
		/*verificar si hay un archivo*/
		if (files.length === 0)
			return;
		/*validar que sea imagen*/
		var mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.message = "Only images are supported.";
			return;
		}
		var reader = new FileReader();
		// this.imagePath = files;
		reader.readAsDataURL(files[0]); 
		reader.onload = (_event) => { 
			this.imgURL = reader.result; 
		}
		this.fileToUpload = files[0];
	}
	
	uploadImagen() {
		if(this.fileToUpload){
			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.configService.postCategoriaImagen(this.fileToUpload, this.idcategoria, this.categoriaActual.imagen).subscribe( (res:any) => {

				console.log(res);
				if(!res.error){
					Swal.close();
					Swal.fire('Actualizado', 'La imagen se actualizó', 'success').then(()=>{
						this.imgURL = "";
						this.fileToUpload = null;
						this.cargarCategoria(this.idcategoria);
					})
				}else{
					Swal.close();
					Swal.fire('Error', 'No se actualizó', 'error');
				}
			}, err => {
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error');
				
			});
		}else{
			console.error('no hay archivo');
		}
	}

	/*=========================================
	=            ELIMIAR CATEGORÍA            =
	=========================================*/
	eliminarCategoria(){

		Swal.fire({
			title: '¿Quieres eliminar la categoría?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',
		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.configService.deleteCategorias(this.idcategoria).subscribe( (res:any) => {
					Swal.close();
					Swal.fire('Eliminado', 'Categoría correctamente', 'success').then( () => {
						this.router.navigateByUrl('main/configuraciones/categorias');

					});
				}, (err:any) => {
					console.error(err);
					Swal.close();
					Swal.fire('Error', err.error.msg, 'error');
				})
			}
		})
	}
	

	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	quitarInvalidTag(tipo){
		switch (tipo) {
			case "nombre":
				this.removeItemFromArr(this.nombreClass, 'is-invalid');
				break;
			case "filtro":
				this.removeItemFromArr(this.filtroClass, 'is-invalid');
				break;
			
			default:
				console.error('No existe tipo clase');
				break;
		}
	}
	

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

}
