<div class="container-fluid">
	<div class="row"><div class="col-md-12"><h2>DETALLES NOTICIA</h2></div></div>

	<div class="row">
		<div class="col-md-4"><button class="btn btn-primary" [routerLink]="['/main/noticias']"><i class="fas fa-long-arrow-alt-left"></i> Regresar</button></div>
		<div class="col-md-4"></div>
		<div class="col-md-4">
			<button *ngIf="otro.estado == 0 && deshabilitado == '0'" class="btn bg-danger btn-app float-right" (click)="eliminarPromocion()"><i class="fas fa-times"></i> Eliminar</button>
			<button *ngIf="otro.estado == 0 && deshabilitado == '0'" class="btn bg-success btn-app float-right" (click)="publicarPromocion()"><i class="fas fa-share-square"></i> Publicar</button>
			<button *ngIf="otro.estado == 1 && deshabilitado == '0'" class="btn bg-warning btn-app float-right" (click)="deshabilitarPromocion()"><i class="fas fa-ban"></i> Deshabilitar</button>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="callout callout-info">
				<h5>Información!</h5>
				<p>Los campos marcados con <b>*</b> son obligatorios.</p>
			</div>
		</div>
	</div>

	<br>
	<div class="row" *ngIf="otro.estado == 1 && deshabilitado == '0'">
		<div class="col-md-12">
			<div class="alert alert-success"> <i class="fas fa-check"></i> Esta noticia está marcada como <b>publicada</b>; No es posible editarla!</div>
		</div>
	</div>

	<div class="row" *ngIf="otro.estado == 0 && deshabilitado == '1'">
		<div class="col-md-12">
			<div class="alert alert-warning"> <i class="fas fa-check"></i> Esta noticia ya ha <b>vencido</b>; No es posible editarla!</div>
		</div>
	</div>

	<div class="row" *ngIf="otro.estado == 1 && deshabilitado == '1'">
		<div class="col-md-12">
			<div class="alert alert-info"> <i class="fas fa-info-circle"></i> Esta noticia está marcada como <b>deshabilitada</b>; No es posible editarla!</div>
		</div>
	</div>

	<br>


	<div class="row">

		<div class="col-md-6">
			<div class="card">
				<div class="card-header bg-dark">DATOS GENERALES</div>
				<div class="card-body" *ngIf="cargandoProductos || cargandoProveedores || cargandoSedes || cargandoPromocionG">
					<div class="text-center">
						<br><br>
						<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<br><br>
					</div>
				</div>
				<div class="card-body" *ngIf="!cargandoProductos && !cargandoProveedores && !cargandoSedes && !cargandoPromocionG">
					<div class="row">
						<div class="col-md-4">
							<div class="form-group">
								<label>ID</label>
								<input type="text" [(ngModel)]="promocionChanged.idpromocion" [ngClass]='classId' readonly>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label>Fecha creación</label>
								<input type="text" [(ngModel)]="promocionChanged.fcreacion" [ngClass]='classId' readonly>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12">

							<div class="form-group">
								<label>Nombre * <small>(50 caractéres)</small></label>
								<input type="text" [(ngModel)]="promocionChanged.promocion" [ngClass]='classNombre' placeholder="Nombre" maxlength="50" (change)="validarProveedor('nombre', promocionChanged.promocion, 'maxlength:50,minlength:10')" autocomplete="off">
							</div>

							<div class="form-group">
								<label>Producto *</label>
								<select [ngClass]='classProducto' style="width: 100%;" [(ngModel)]="promocionChanged.codigoProducto" (change)="validarProveedor('producto', promocionChanged.codigoProducto, 'isint')">
									<option selected="selected" value="0">-- Selecciona un producto --</option>
									<option *ngFor="let p of productos" [value]="p.codigoProducto">{{p.nombreProducto}}</option>
								</select>
							</div>

							<div class="form-group">
								<label>Proveedor *</label>
								<select [ngClass]='classProveedor' style="width: 100%;" [(ngModel)]="promocionChanged.idproveedor" (change)="obtenerSedes_actProveedor(promocionChanged.idproveedor)">
									<option selected value="0">-- Selecciona un proveedor --</option>
									<option *ngFor="let p of proveedores" [value]="p.idproveedor">{{p.proveedor}}</option>
									<hr>
									<option *ngFor="let p of proveedoresDisabled" disabled [value]="p.idproveedor">{{p.proveedor}}</option>
								</select>
							</div>

							<div class="form-group">
								<label>Sede *</label>
								<br>
								<div class="spinner-grow spinner-grow-sm " role="status" *ngIf="cargandoSedes">
									<span class="sr-only">Loading...</span>
								</div>
								<div class="spinner-grow spinner-grow-sm" role="status" *ngIf="cargandoSedes">
									<span class="sr-only">Loading...</span>
								</div>
								<div class="spinner-grow spinner-grow-sm" role="status" *ngIf="cargandoSedes">
									<span class="sr-only">Loading...</span>
								</div>
								<select [ngClass]='classSede' style="width: 100%;" [(ngModel)]="promocionChanged.idsede" *ngIf="!cargandoSedes" (change)="validarProveedor('sede', promocionChanged.idsede, 'isint')">
									<option value="0">-- Selecciona una sede del proveedor --</option>
									<option *ngFor="let s of sedes" [value]="s.idsede">{{s.sede}}</option>
								</select>
							</div>


						</div>
					</div>

				</div>
				<div class="card-footer" *ngIf="!cargandoProductos && !cargandoProveedores && !cargandoPromocionG">
					<button *ngIf="otro.estado != 1" class="btn btn-primary float-right" (click)="actualizarProveedorG()">Actualizar</button>
				</div>
			</div>
		</div>


		<div class="col-md-6">
			<div class="card">
				<div class="card-header bg-dark">
					DATOS DETALLES
				</div>

				<div class="card-body" *ngIf="cargandoPromocionD">
					<div class="text-center">
						<br>
						<br>
						<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<br>
						<br>
					</div>
				</div>

				<div class="card-body" *ngIf="!cargandoPromocionD">

					<div class="row">

						<div class="col-md-6">
							<div class="form-group">
								<label for="">Fecha inicio *</label>
								<div class="input-group">
									<input [(ngClass)]="classFechai" placeholder="yyyy-mm-dd" type="date" [(ngModel)]="detallesChanged.finicio" (change)="validarProveedor('fechai', detallesChanged.finicio, 'isdate')">
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<div class="form-group">
								<label for="">Fecha fin *</label>
								<div class="input-group">
									<input [(ngClass)]="classFechaf" placeholder="yyyy-mm-dd" type="date" [(ngModel)]="detallesChanged.ffin" (change)="validarProveedor('fechaf', detallesChanged.ffin, 'isdate')">
								</div>
							</div>
							</div>
						</div>

					</div>

					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<label>Detalles *</label>
								<textarea [(ngModel)]="detallesChanged.detalles" class="form-control" rows="12" placeholder="Detalles ..." (change)="validarProveedor('detalle', detallesChanged.detalles, 'string')"></textarea>
							</div>
						</div>
					</div>


				</div>
				<div class="card-footer" *ngIf="!cargandoPromocionD">
					<button *ngIf="otro.estado != 1" class="btn btn-primary float-right" (click)="actualizarProveedorD()">Actualizar</button>
				</div>
			</div>
		</div>



		<div class="col-md-6">
			<div class="card">
				<div class="card-header bg-dark">
					DATOS MULTIMEDIA
				</div>
				<div class="card-body" *ngIf="cargandoPromocionM">
					<div class="text-center">
						<br><br>
						<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<br><br>
					</div>
				</div>
				<div class="card-body" *ngIf="!cargandoPromocionM">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<input class="custom-file-input" #file id="file" type="file" accept='image/*' (change)="handleFileInput(file.files)" />
								<label class="custom-file-label" for="file">Choose file</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label for="">Selección</label>
								<img [src]="imgURL" width="100%" *ngIf="imgURL">
							</div>
						<div class="col-md-6">
							<label for="">Imagen actual *</label>
							<!-- <img [src]="multimediaChanged.imagen" alt="" width="100%"> -->
							<img *ngIf="multimediaChanged.imagen != null" [src]="multimediaChanged.imagen" alt="" width="100%">
              				<img *ngIf="!multimediaChanged.imagen" src="assets/img/noimage.jpg" alt="" width="100%">
						</div>
					</div>
				</div>
				<div class="card-footer" *ngIf="!cargandoPromocionM">
					<button *ngIf="otro.estado != 1" class="btn btn-primary float-right" (click)="uploadImagen()">Actualizar</button>
				</div>
			</div>
		</div>

	</div>
</div>

