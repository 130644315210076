import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ConfiguracionesService } from '../../../../services/configuraciones.service';


@Component({
	selector: 'app-membresias',
	templateUrl: './membresias.component.html',
	styles: []
})
export class MembresiasComponent implements OnInit {

	constructor(private configService: ConfiguracionesService,
				private modalService: NgbModal) {
		this.cargarMembresias();
	}


	ngOnInit(): void {}

	/*=================================
	=            VARIABLES            =
	=================================*/
	membresias:string[] = [];
	cargandoMembresias:boolean = true;


	/*==========================================
	=            OBTENER MEMBRESÍAS            =
	==========================================*/
	cargarMembresias(){
		this.cargandoMembresias = true;
		this.configService.getMembresias().subscribe( (res:any) =>{
			this.cargandoMembresias = false;
			if(!res.error){
				this.membresias = res.membresias;
			}
		}, (err:any) => {
			this.cargandoMembresias = false;
			console.error(err);
		})
	}

	/*=========================================
	=            AGREGAR MEMBRESÍA            =
	=========================================*/
	modalAgregar:any;

	membresiaAdd = {
		codigo: '',
		nombre: '',
		color: '',
		estado: 1
	}

	idClass:string[] = ['form-control'];
	nombreClass:string[] = ['form-control'];
	colorClass:string[] = ['form-control'];

	openModalAgregar(agregar) {
		this.modalAgregar = this.modalService.open(agregar, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {
		}, (reason) => {
		});
	}

	cerrarModalAgregar(){
		this.modalAgregar.close('cerrar');
		this.membresiaAdd = {
			codigo: '',
			nombre: '',
			color: '',
			estado: 1
		}
	}

	agregarMembresia(){

		this.membresiaAdd.codigo = this.membresiaAdd.codigo.trim();
		this.membresiaAdd.nombre = this.membresiaAdd.nombre.trim();
		this.membresiaAdd.color = this.membresiaAdd.color.trim();

		if(this.membresiaAdd.codigo.length == 1){
			if(this.membresiaAdd.nombre.length > 0 && this.membresiaAdd.nombre.length < 51){
				if(this.membresiaAdd.color != ''){
					Swal.fire({allowOutsideClick: false});
					Swal.showLoading();
					this.configService.postMembresias(this.membresiaAdd).subscribe( (res:any) =>{
						Swal.close();
						if(!res.error){
							Swal.fire('Agregado', 'Membresía agregada', 'success').then( () => {
								this.cerrarModalAgregar();
								this.cargarMembresias();
							});
						}else{
							Swal.fire('Error', res.msg, 'error');
						}
					}, (err:any) =>{
						Swal.close();
						console.error(err);
						Swal.fire('Error', err.error.msg, 'error');
					})

				}else{
					console.error('Error en selección de color');
					this.colorClass.push('is-invalid');
				}
			}else{
				console.error('Error en cantidad de caracteres nombre');
				this.nombreClass.push('is-invalid');
			}
		}else{
			console.error('Error en cantidad de caracteres código');
			this.idClass.push('is-invalid');
		}
	}


	/*==========================================
	=            EDITAR INSTITUCIÓN            =
	==========================================*/
	modalEditar:any;

	membresiaActual = {
		codigoMembresia: "",
		nombreMembresia: "",
		colorMembresia: "",
		estadoMembresia: ""
	}

	membresiaEditar = {
		codigoMembresia: "",
		nombreMembresia: "",
		colorMembresia: "",
		estadoMembresia: ""
	}
	

	openModalEditar(editar, actual) {
		this.membresiaEditar.codigoMembresia = actual.codigoMembresia;
		this.membresiaEditar.nombreMembresia = actual.nombreMembresia;
		this.membresiaEditar.colorMembresia = actual.colormembresia;
		this.membresiaEditar.estadoMembresia = actual.estadoMembresia;

		this.membresiaActual.codigoMembresia = actual.codigoMembresia;
		this.membresiaActual.nombreMembresia = actual.nombreMembresia;
		this.membresiaActual.colorMembresia = actual.colormembresia;
		this.membresiaActual.estadoMembresia = actual.estadoMembresia;

		this.modalEditar = this.modalService.open(editar, {backdrop:'static', size: 'lg'});
		this.modalEditar.result.then((result) => {
		}, (reason) => {
		});
	}

	cerrarModalEditar(){
		this.modalEditar.close('cerrar');
		this.membresiaEditar = {
			codigoMembresia: "",
			nombreMembresia: "",
			colorMembresia: "",
			estadoMembresia: ""
		}
	}

	actualizarMembresia(){

		this.membresiaEditar.nombreMembresia = this.membresiaEditar.nombreMembresia.trim();
		this.membresiaEditar.colorMembresia = this.membresiaEditar.colorMembresia.trim();
		this.membresiaEditar.estadoMembresia = this.membresiaEditar.estadoMembresia.trim();



		if(this.membresiaEditar.nombreMembresia.length > 0 && this.membresiaEditar.nombreMembresia.length < 51){

			if(this.membresiaEditar.colorMembresia != ''){

				if(
					this.membresiaEditar.nombreMembresia != this.membresiaActual.nombreMembresia ||
					this.membresiaEditar.colorMembresia != this.membresiaActual.colorMembresia ||
					this.membresiaEditar.estadoMembresia != this.membresiaActual.estadoMembresia 
				){


					const data = {
						'nombre': this.membresiaEditar.nombreMembresia ,
						'color': this.membresiaEditar.colorMembresia ,
						'estado': this.membresiaEditar.estadoMembresia
					};

					Swal.fire({allowOutsideClick: false});
					Swal.showLoading();
					this.configService.putMembresias(this.membresiaActual.codigoMembresia, data).subscribe( (res:any) => {
						Swal.close();
						if(!res.error){
							Swal.fire('Actualizado', 'Membresía actualizada', 'success').then( () => {
								this.cerrarModalEditar();
								this.cargarMembresias();
							});
						}else{
							Swal.fire('Error', res.msg, 'error')
						}
					}, (err:any) =>{
						Swal.close();
						console.error(err);
						Swal.fire('Error', err.error.msg, 'error')
					})

				}else{
					console.error('no hay cambios para realizar');
					Swal.fire('Info', 'No hay cambios para realizar', 'info');
				}

			}else{
				console.error('error en selección de color');
				this.colorClass.push('is-invalid');
			}

			
		}else{
			console.error('error en caracteres de nombre');
			this.nombreClass.push('is-invalid');

		}

	}


	/*============================================
	=            ELIMINAR INSTITUCIÓN            =
	============================================*/
	eliminarMembresia(id){
		Swal.fire({
			title: '¿Quieres eliminar la membresía?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {

			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.configService.deleteMembresias(id).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Membresía eliminada', 'success').then( () => {
							this.cargarMembresias();
						})
					}else{
						Swal.fire('Error', res.msg, 'error');

					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}





	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	quitarInvalidTag(tipo){
		switch (tipo) {
			case "codigo":
				this.removeItemFromArr(this.idClass, 'is-invalid');
				break;
			case "nombre":
				this.removeItemFromArr(this.nombreClass, 'is-invalid');
				break;
			case "color":
				this.removeItemFromArr(this.colorClass, 'is-invalid');
				break;
			default:
				console.error('no existe tipo');
				break;
		}
	}
	

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

}
