import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ConfiguracionesService } from '../../../../services/configuraciones.service';


@Component({
	selector: 'app-categoriaslistado',
	templateUrl: './categoriaslistado.component.html',
	styles: []
})
export class CategoriaslistadoComponent implements OnInit {

	constructor(private configService: ConfiguracionesService,
				private modalService: NgbModal) {
		this.cargarCategorias();
	}

	ngOnInit(): void {}

	/*=================================
	=            VARIABLES            =
	=================================*/
	categorias:string[] = [];
	cargandoCategorias:boolean = true;

	/*==========================================
	=            OBTENER CATEGORÍIS            =
	==========================================*/
	cargarCategorias(){
		this.cargandoCategorias = true;
		this.configService.getCategorias().subscribe( (res:any) =>{
			this.cargandoCategorias = false;
			if(!res.error){
				this.categorias = res.categorias;
			}
		}, (err:any) => {
			this.cargandoCategorias = false;
		})
	}

	/*=========================================
	=            AGREGAR CATEGORÍA            =
	=========================================*/
	modalAgregar:any;

	nombreAdd:string = "";

	nombreClass:string[] = ['form-control'];

	openModalAgregar(agregar) {
		this.modalAgregar = this.modalService.open(agregar, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {
		}, (reason) => {
		});
	}

	cerrarModalAgregar(){

		this.modalAgregar.close('cerrar');
		this.nombreAdd = "";
	}

	agregarCategoria(){
		let nombre = this.nombreAdd.trim();
		if(nombre){
			if(nombre.length > 0 && nombre.length < 51){

				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.configService.postCategorias(nombre).subscribe( (res:any) =>{
					Swal.close();
					if(!res.error){
						Swal.fire('Agregado', 'Categoría agregada', 'success').then( () => {
							this.nombreAdd = "";
							this.cerrarModalAgregar();
							this.cargarCategorias();
						});
					}else{
						Swal.fire('Error', res.msg, 'error');
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}else{
				console.error('Error en cantidad de caracteres');
				this.nombreClass.push('is-invalid');
			}
		}else{
			console.error('No hay nombre');
			this.nombreClass.push('is-invalid');
		}
	}



	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	quitarInvalidName(){
		this.removeItemFromArr(this.nombreClass, 'is-invalid');
	}
	

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

}
