import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ConfiguracionesService } from '../../../../services/configuraciones.service';


@Component({
	selector: 'app-sedes',
	templateUrl: './sedes.component.html',
	styles: []
})
export class SedesComponent implements OnInit {

	constructor(private configService: ConfiguracionesService,
				private modalService: NgbModal) {
		this.cargarSedes();
	}

	ngOnInit(): void {}



	/*=================================
	=            VARIABLES            =
	=================================*/
	sedes:string[] = [];
	cargandoSedes:boolean = true;
	cargandoEstados:boolean = true;
	estados:string[];
	cargandoPaises:boolean = true;
	paises:string[];

	/*=====================================
	=            OBTENER SEDES            =
	=====================================*/
	cargarSedes(){
		this.cargandoSedes = true;
		this.configService.getSedes().subscribe( (res:any) =>{
			this.cargandoSedes = false;
			if(!res.error){
				this.sedes = res.sedes;
			}
		}, (err:any) => {
			this.cargandoSedes = false;
			console.error(err);
		})
	}


	/*=====================================
	=            AGREGAR SEDES            =
	=====================================*/
	modalAgregar:any;

	nombreAdd:string = "";
	estadoAdd:string = "0";
	paisAdd:string = "1";
	nombreClass:string[] = ['form-control'];
	estadoClass:string[] = ['form-control'];
	paisClass:string[] = ['form-control'];

	openModalAgregar(agregarA) {
		this.cargarPaises();
		this.cargarEstados(null, 'pais', this.paisAdd);
		this.modalAgregar = this.modalService.open(agregarA, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {
		}, (reason) => {
		});
	}

	cerrarModalAgregar(){
		this.modalAgregar.close('cerrar');
		this.nombreAdd = "";
		this.estadoAdd = "0";
		this.paisAdd = "1";
	}

	agregarSedes(){
		let nombre = this.nombreAdd.trim();
		let estado = this.estadoAdd.trim();

		if(nombre){
			if(nombre.length > 0 && nombre.length < 51){

				if(estado){
					if(estado != '0'){

						Swal.fire({allowOutsideClick: false});
						Swal.showLoading();
						this.configService.postSedes(nombre, estado).subscribe( (res:any) =>{
							Swal.close();
							if(!res.error){
								Swal.fire('Agregado', 'Sede agregada', 'success').then( () => {
									this.nombreAdd = "";
									this.cerrarModalAgregar();
									this.cargarSedes();
								});
							}else{
								Swal.fire('Error', res.msg, 'error');
							}
						}, (err:any) =>{
							Swal.close();
							console.error(err);
							Swal.fire('Error', err.error.msg, 'error');
						})

					}else{
						console.error('Error en selección de estdo');
						this.estadoClass.push('is-invalid');
					}
				}else{
					console.error('No hay estado');
					this.estadoClass.push('is-invalid');
				}

			}else{
				console.error('Error en cantidad de caracteres');
				this.nombreClass.push('is-invalid');
			}
		}else{
			console.error('No hay nombre');
			this.nombreClass.push('is-invalid');
		}
	}

	/*=======================================
	=            EDITAR PRODUCTO            =
	========================================*/
	modalEditar:any;

	sedeActual = {
		codigoSede: "",
		nombreSede: "",
		codigoEstado: ""
	}

	sedeEditar = {
		codigoSede: "",
		nombreSede: "",
		codigoEstado: ""
	}
	

	openModalEditar(editarA, actual) {

		this.cargarPaises();
		this.cargarEstados(null, 'pais', this.paisAdd);
		
		this.sedeEditar.codigoSede = actual.codigoSede;
		this.sedeEditar.nombreSede = actual.nombreSede;
		this.sedeEditar.codigoEstado = actual.codigoEstado;
		this.sedeActual.codigoSede = actual.codigoSede;
		this.sedeActual.nombreSede = actual.nombreSede;
		this.sedeActual.codigoEstado = actual.codigoEstado;

		this.modalEditar = this.modalService.open(editarA, {backdrop:'static', size: 'lg'});
		this.modalEditar.result.then((result) => {
		}, (reason) => {
		});
	}

	cerrarModalEditar(){
		this.modalEditar.close('cerrar');
		this.sedeEditar.nombreSede = "";
		this.sedeEditar.codigoEstado = "0";
		this.paisAdd = "1";
	}

	actualizarSede(){
		const nombre = this.sedeEditar.nombreSede.trim();
		const estado = this.sedeEditar.codigoEstado.trim();

		if(nombre.length > 0 && nombre.length < 51){
			if(estado && estado != '0'){
				if(nombre != this.sedeActual.nombreSede.trim() || estado != this.sedeActual.codigoEstado){
					const data = {'nombre': nombre, 'estado' : estado};
					Swal.fire({allowOutsideClick: false});
					Swal.showLoading();
					this.configService.putSedes(this.sedeActual.codigoSede, data).subscribe( (res:any) => {
						Swal.close();
						if(!res.error){
							Swal.fire('Actualizado', 'Sede actualizada', 'success').then( () => {
								this.cerrarModalEditar();
								this.cargarSedes();
							});
						}else{
							Swal.fire('Error', res.msg, 'error')
						}
					}, (err:any) =>{
						Swal.close();
						console.error(err);
						Swal.fire('Error', err.error.msg, 'error')
					})
				}else{
					console.error('no hay cambios para realizar');
					Swal.fire('Info', 'No hay cambios para realizar', 'info');
				}
			}else{
				console.error('error en selección de estado');
				this.estadoClass.push('is-invalid');
			}
		}else{
			console.error('error en caracteres de nombre');
			this.nombreClass.push('is-invalid');
		}
	}


	/*=========================================
	=            ELIMINAR SEDE            =
	=========================================*/
	eliminarSede(id){

		Swal.fire({
			title: '¿Quieres eliminar la sede?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.configService.deleteSedes(id).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Sede eliminada', 'success').then( () => {
							this.cargarSedes();
						})
					}else{
						Swal.fire('Error', res.msg, 'error');

					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}

	/*================================
	=            OPCIONES            =
	================================*/
	cargarEstados(id, filter, valueFilter){

		this.cargandoEstados = true;
		this.configService.getEstados(id, filter, valueFilter).subscribe( (res:any) =>{
			this.cargandoEstados = false;
			if(!res.error){
				this.estados = res.estados
			}
		}, (err:any) => {
			this.cargandoEstados = false;
			console.error(err);
		})
	}
	cargarPaises(){

		this.cargandoPaises = true;
		this.configService.getPaises().subscribe( (res:any) =>{
			this.cargandoPaises = false;
			if(!res.error){
				this.paises = res.paises
			}
		}, (err:any) => {
			this.cargandoPaises = false;
			console.error(err);
		})
	}
	
	
	


	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	quitarInvalidTag(tipo){
		switch (tipo) {
			case "nombre":
				this.removeItemFromArr(this.nombreClass, 'is-invalid');
				break;
			case "estado":
				this.removeItemFromArr(this.estadoClass, 'is-invalid');
				break;
			case "pais":
				this.estadoAdd = "0";
				this.cargarEstados(null, 'pais', this.paisAdd);
				this.removeItemFromArr(this.paisClass, 'is-invalid');
				break;
			default:
				console.error('no existe tipo tag');
				break;
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}




}
