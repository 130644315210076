import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ConfiguracionesService } from '../../../../services/configuraciones.service';

@Component({
	selector: 'app-ligas',
	templateUrl: './ligas.component.html',
	styles: []
})

export class LigasComponent implements OnInit {

	constructor(private configService: ConfiguracionesService,
				private modalService: NgbModal) {
		this.cargarLigas();
	}

	ngOnInit(): void {

	}


	/*=================================
	=            VARIABLES            =
	=================================*/
	ligas:string[] = [];
	asociaciones:string[] = [];
	cargandoLigas:boolean = true;

	/*=============================================
	=            OBTENER INSTITUCIONES            =
	=============================================*/
	cargarLigas(){
		this.cargandoLigas = true;
		this.configService.getLigas().subscribe( (res:any) =>{
			this.cargandoLigas = false;
			if(!res.error){
				this.ligas = res.ligas;
			}
		}, (err:any) => {
			this.cargandoLigas = false;
			console.error(err);
		})
	}

	/*===========================================
	=            AGREGAR INSTITUCIÓN            =
	===========================================*/
	modalAgregar:any;

	ligaAdd = {
		nombre: "",
		asociacion: "19"
	}

	nombreClass:string[] = ['form-control'];
	asociacionClass:string[] = ['form-control'];

	openModalAgregar(agregar) {
		this.modalAgregar = this.modalService.open(agregar, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {
		}, (reason) => {
		});

		this.obtenerAsociaciones();
	}

	cerrarModalAgregar(){
		this.modalAgregar.close('cerrar');
		this.ligaAdd.nombre = "";
		this.ligaAdd.asociacion = "19";
	}

	agregarLiga(){
		let asociacion = this.ligaAdd.asociacion.trim()
		const data = {
			nombre: this.ligaAdd.nombre.trim(),
			asociacion : parseInt(asociacion)
		}


		if(data.nombre){
			if(data.nombre.length > 0 && data.nombre.length < 51){

				if(data.asociacion != 0){
					Swal.fire({allowOutsideClick: false});
					Swal.showLoading();
					this.configService.postLigas(data).subscribe( (res:any) =>{
						Swal.close();
						if(!res.error){
							Swal.fire('Agergado', 'Liga agregada', 'success').then( () => {
								this.ligaAdd.nombre = "";
								this.ligaAdd.asociacion = "19";
								this.cerrarModalAgregar();
								this.cargarLigas();
							});
						}else{
							Swal.fire('Error', res.msg, 'error');
						}
					}, (err:any) =>{
						Swal.close();
						console.error(err);
						Swal.fire('Error', err.error.msg, 'error');
					})

				}else{
					console.error('Error en asociacion');
					this.asociacionClass.push('is-invalid');
				}
			}else{
				console.error('Error en cantidad de caracteres');
				this.nombreClass.push('is-invalid');
			}
		}else{
			console.error('No hay nombre');
			this.nombreClass.push('is-invalid');
		}
	}


	/*==========================================
	=            EDITAR INSTITUCIÓN            =
	==========================================*/
	modalEditar:any;

	ligaActual = {
		codigoLiga: "",
		nombre: "",
		asociacion: 19
	}

	ligaEditar = {
		codigoLiga: "",
		nombre: "",
		asociacion: "19"
	}
	
	openModalEditar(editar, actual) {

		this.ligaActual.codigoLiga = actual.codigoLiga;
		this.ligaActual.nombre = actual.nombreLiga;
		this.ligaActual.asociacion = parseInt(actual.codigoAsociacion);

		this.ligaEditar.codigoLiga = actual.codigoLiga;
		this.ligaEditar.nombre = actual.nombreLiga;
		this.ligaEditar.asociacion = actual.codigoAsociacion;

		this.modalEditar = this.modalService.open(editar, {backdrop:'static', size: 'lg'});
		this.modalEditar.result.then((result) => {
		}, (reason) => {
		});

		this.obtenerAsociaciones();

	}

	cerrarModalEditar(){
		this.modalEditar.close('cerrar');
		this.ligaActual.codigoLiga = "";
		this.ligaActual.nombre = "";
		this.ligaActual.asociacion = 19;

		this.ligaEditar.codigoLiga = "";
		this.ligaEditar.nombre = "";
		this.ligaEditar.asociacion = "19";
	}

	actualizarLiga(){
		let asociacion = this.ligaEditar.asociacion.trim()

		const data = {
			nombre: this.ligaEditar.nombre.trim(),
			asociacion : parseInt(asociacion)
		}


		if(data.nombre.length > 0 && data.nombre.length < 51){

			if(data.nombre != this.ligaActual.nombre.trim() || data.asociacion != this.ligaActual.asociacion ){

				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.configService.putLigas(this.ligaActual.codigoLiga, data).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Actualizado', 'Institución actualizada', 'success').then( () => {
							this.cerrarModalEditar();
							this.cargarLigas();
						});
					}else{
						Swal.fire('Error', res.msg, 'error')
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error')
				})

			}else{
				console.error('no hay cambios para realizar');
				Swal.fire('Info', 'No hay cambios para realizar', 'info');
			}
		}else{
			console.error('error en caracteres de nombre');
			this.nombreClass.push('is-invalid');

		}

	}

	/*============================================
	=            ELIMINAR INSTITUCIÓN            =
	============================================*/
	eliminarLiga(id){

		Swal.fire({
			title: '¿Quieres eliminar la liga?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.configService.deleteLigas(id).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Liga eliminada', 'success').then( () => {
							this.cargarLigas();
						})
					}else{
						Swal.fire('Error', res.msg, 'error');

					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}



	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	quitarInvalidTag(tipo){
		switch (tipo) {
			case "nombre":
				this.removeItemFromArr(this.nombreClass, 'is-invalid');
				break;
			case "asociacion":
				this.removeItemFromArr(this.asociacionClass, 'is-invalid');
				break;
			default:
				console.warn('error en tipo tag')
				break;
		}
		
	}

	obtenerAsociaciones(){
		this.configService.getAsociaciones().subscribe( (res:any) => {
			this.asociaciones = res.asociaciones;
		}, (err:any) => {
			console.error(err);
		})
	}
	

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}



}
