import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';



@Injectable({
	providedIn: 'root'
})


export class PromocionesService {

	constructor(private http:HttpClient,
				private aService:AuthService) {
		this.TOKEN = this.aService.leerToken();
	}

	/*constantes*/
	APIKEY:string = environment.APIKEY;
	APIURL:string = environment.APIURL;
	TOKEN:string;

	public getPromociones(id = null, filter = 'generales'){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let url:string = this.APIURL + 'promociones';


		if(id){
			let params = new HttpParams();
				params = params.append('filter', filter);
			url = url + '/' + id;
			return this.http.get(url, { headers, params } );


		}else{
			return this.http.get(url, { headers } );
		}
	}

	public getSedes(){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let url:string = this.APIURL + 'sedes';
		return this.http.get(url, { headers } );
	}
	
	public postPromocion(datos){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = datos;
		let url:string = this.APIURL + 'promociones';
		return this.http.post(url, body, { headers } );
	}

	public putPromocion(datos, id){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})
		let body = new HttpParams;
			body = datos;

		let url:string = this.APIURL + 'promociones/' + id;
		return this.http.put(url, body, { headers } );
	}

	public publicarPromocion(id, datos){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})
		let body = new HttpParams;
			body = datos;

		let url:string = this.APIURL + 'promociones/' + id;
		return this.http.put(url, body, { headers } );
	}

	public putImagen(file, promocion, imgAnterior){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

			let body = new FormData();
			body.append('imagen', file);
			body.append('imgAnterior', imgAnterior);
		let url:string = this.APIURL + 'promociones/imagen/' + promocion;
		return this.http.post(url, body, { headers } );
	}

	public deletePromocion(id){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let url:string = this.APIURL + 'promociones/' + id;
		return this.http.delete(url, { headers } );

	}

	public vigenciasPromocion(){
		const headers = new HttpHeaders({'pp-apikey': this.APIKEY,'pp-token': this.TOKEN})
		let url:string = this.APIURL + 'promociones/deshabilitar';
		return this.http.put(url, '', { headers } );

	}



}
