import { Injectable } from '@angular/core';

import { RulesValidation } from '../models/general.interface';

@Injectable({
	providedIn: 'root'
})


export class ValidacionesService {



	/*
	 este servicio valida campos según la función
	*/

	constructor() { }



	comprobarRules(value, rulesString){

		const rulesArray = rulesString.split(',');
		// console.log(rulesArray);

		let respuesta = {
				response: null,
				value: null,
				rule:null,
				ruleValue: null
			}

			let respuesta_ = {
				response: false,
				value: null,
				rule:null,
				ruleValue: null,
				message: 'Regla no existe'
			}

			let respuesta_ok = {
				response: true,
				message: 'Validado'
			}


		for (var i = 0; i < rulesArray.length; i++) {

			let ruleVal = rulesArray[i].split(':');
			// console.log('validando: ', ruleVal);

			switch (ruleVal[0]) {

				case "maxlength":
					if(value.length > parseInt(ruleVal[1])){
						respuesta.response = false;
						respuesta.value = value;
						respuesta.rule = ruleVal[0];
						respuesta.ruleValue = ruleVal[1];
						return respuesta;
					}
					break;
				case "minlength":
					if(value.length < parseInt(ruleVal[1])){
						respuesta.response = false;
						respuesta.value = value;
						respuesta.rule = ruleVal[0];
						respuesta.ruleValue = ruleVal[1];
						return respuesta;
					}
					break;
				case "isint":
					if( (value == 0) || (value % 1 != 0) ){
						respuesta.response = false;
						respuesta.value = value;
						respuesta.rule = ruleVal[0];
						respuesta.ruleValue = ruleVal[0];
						return respuesta;
					}
					break;	
				case "isdate":
					if( value == '' ){
						respuesta.response = false;
						respuesta.value = value;
						respuesta.rule = ruleVal[0];
						respuesta.ruleValue = ruleVal[0];
						return respuesta;
					}
					break;					
				default:
					respuesta_.value = value;
					respuesta_.rule = ruleVal[0];
					respuesta_.ruleValue = ruleVal[1];
					return respuesta_;
					break;
			}

			
		}

		return respuesta_ok;


	}





	
}
