import {RouterModule, Routes} from '@angular/router';

/*pages*/
import {LoginComponent} from './componentes/login/login/login.component';
import {HomeComponent} from './componentes/main/pages/home/home.component';
import {MainComponent} from './componentes/main/main.component';
import {PromocionesComponent} from './componentes/main/pages/promociones/promociones.component';
import {PromocionComponent} from './componentes/main/pages/promociones/promocion.component';
import {ListadopromocionComponent} from './componentes/main/pages/promociones/listadopromocion.component';
import {ProveedoresComponent} from './componentes/main/pages/proveedores/proveedores.component';
import {ProveedorComponent} from './componentes/main/pages/proveedores/proveedor.component';
import {ClientesComponent} from './componentes/main/pages/clientes/clientes.component';
import {ClienteComponent} from './componentes/main/pages/clientes/cliente.component';
import {ComprasComponent} from './componentes/main/pages/compras/compras.component';

import {ConfiguracionesComponent} from './componentes/main/pages/configuraciones/configuraciones.component';
import {AsociacionesComponent} from './componentes/main/pages/configuraciones/asociaciones.component';
import {InstitucionesComponent} from './componentes/main/pages/configuraciones/instituciones.component';
import {LigasComponent} from './componentes/main/pages/configuraciones/ligas.component';
import {MembresiasComponent} from './componentes/main/pages/configuraciones/membresias.component';
import {FiltrosComponent} from './componentes/main/pages/configuraciones/filtros.component';
import {DeportesComponent} from './componentes/main/pages/configuraciones/deportes.component';
import {ProductosComponent} from './componentes/main/pages/configuraciones/productos.component';
import {SedesComponent} from './componentes/main/pages/configuraciones/sedes.component';
import {CategoriasComponent} from './componentes/main/pages/configuraciones/categorias.component';
import {CategoriaslistadoComponent} from './componentes/main/pages/configuraciones/categoriaslistado.component';
import {CategoriaComponent} from './componentes/main/pages/configuraciones/categoria.component';

/*guards*/
import {MainGuard} from './guards/main.guard';
import {LoginGuard} from './guards/login.guard';



const APP_ROUTES:Routes = [
	{
		path:'main',
		component : MainComponent,
		canActivate: [MainGuard],
		children: [
			{
				path:'',
				component : HomeComponent
			},
			{
				path:'home',
				component : HomeComponent
			},
			{
				path:'noticias',
				component : PromocionesComponent,
				children: [
					{
						path:'',
						component : ListadopromocionComponent
					},
					{
						path:':id',
						component : PromocionComponent
					}
				]
			},
			{
				path:'socios',
				component : ProveedoresComponent
			},
			{
				path:'socios/:id',
				component : ProveedorComponent
			},
			{
				path:'clientes',
				component : ClientesComponent
			},
			{
				path:'clientes/:id',
				component : ClienteComponent
			},
			{
				path:'compras',
				component : ComprasComponent
			},
			{
				path:'configuraciones',
				component : ConfiguracionesComponent,
				children: [
					{
						path:'instituciones',
						component : InstitucionesComponent
					},
					{
						path:'ligas',
						component : LigasComponent
					},
					{
						path:'membresias',
						component : MembresiasComponent
					},
					{
						path:'caracteristicas',
						component : FiltrosComponent
					},
					{
						path:'sedes',
						component : SedesComponent
					},
					{
						path:'deportes',
						component : DeportesComponent
					},
					{
						path:'productos',
						component : ProductosComponent
					},
					{
						path:'categorias',
						component : CategoriasComponent,
						children: [
							{
								path:'',
								component : CategoriaslistadoComponent
							},
							{
								path:':id',
								component : CategoriaComponent
							},
						]
					}
				] 
			},
		]
	},
	{
		path:'login',
		component : LoginComponent,
		canActivate: [LoginGuard]
	},
	{
		path:'**',
		pathMatch: 'full',
		redirectTo: 'main' 
	}
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, {useHash: true});