import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ProveedoresService } from '../../../../services/proveedores.service';
import { ConfiguracionesService } from '../../../../services/configuraciones.service';


@Component({
	selector: 'app-proveedor',
	templateUrl: './proveedor.component.html',
	styles: []
})
export class ProveedorComponent implements OnInit {

	constructor(private proveedoresService: ProveedoresService,
				private rutaActiva:ActivatedRoute,
				private router:Router,
				private configService: ConfiguracionesService,
				private modalService: NgbModal) {

		this.idproveedor = this.rutaActiva.snapshot.params.id;
		if( parseInt(this.idproveedor) % 1 != 0){
			// this.router.navigateByUrl('main/proveedores');
		}
		this.cargarSedes();
		this.cargarCategorias();
		this.cargarProveedor();
		this.cargarProveedorSedes();
		this.cargarProveedorFiltros();
	}

	ngOnInit(): void {}


	/*=================================
	=            VARIABLES            =
	=================================*/
	idproveedor:string;


	sedesproveedor:string[] =[];
	sedeAdd:string = "0";
	sedeClass:string[] = ['form-control'];

	filtrosproveedor:string[] =[];
	filtroAdd:string = "0";
	filtroClass:string[] = ['form-control'];

	cargandoProveedorFiltros:boolean = true;
	cargandoProveedores:boolean = true;
	cargandoProveedorSedes:boolean = true;
	fileToUpload: File = null;

	/*opciones*/
	categorias:string[] = [];
	categoriasDisabled:string[] = [];
	sedes:string[] = [];
	filtros:string[] = [];



	cargandoCategorias:boolean = true;
	cargandoSedes:boolean = true;
	cargandoFiltros:boolean = true;


	/*========================================
	=            OBTENER PROVEEDOR           =
	========================================*/
	cargarProveedor(){
		this.cargandoProveedores = true;
		this.proveedoresService.getProveedores(this.idproveedor).subscribe( (res:any) =>{
			this.cargandoProveedores = false;
			this.inicilizarVariables(res.proveedor);
		}, (err:any) => {
			this.cargandoProveedores = false;
			console.error(err);
		})
	}

	cargarProveedorSedes(){
		this.cargandoProveedorSedes = true;
		this.proveedoresService.getProveedores(this.idproveedor, 'filter', 'sedes').subscribe( (res:any) =>{
			this.cargandoProveedorSedes = false;
			this.sedesproveedor = res.sedes;
		}, (err:any) => {
			this.cargandoProveedorSedes = false;
			console.error(err);
		})
	}

	cargarProveedorFiltros(){
		this.cargandoProveedorFiltros = true;
		this.proveedoresService.getProveedores(this.idproveedor, 'filter', 'filtros').subscribe( (res:any) =>{
			this.cargandoProveedorFiltros = false;
			this.filtrosproveedor = res.filtros;
		}, (err:any) => {
			this.cargandoProveedorFiltros = false;
			console.error(err);
		})
	}


	/*============================================
	=            ACTUALIZAR PROVEEDOR            =
	============================================*/
	proveedorActual = {
		codigoCategoria: '0',
		nombreProveedor: '',
		emailProveedor: '',
		telefonoProveedor: '',
		passwordProveedor:'',
		estadoProveedor: '0',
	}

	proveedorEditar = {
		codigoCategoria: '0',
		nombreProveedor: '',
		emailProveedor: '',
		telefonoProveedor: '',
		passwordProveedor:'',
		estadoProveedor: '0',
	}

	classEdit = {
		categoriaClass: ['form-control'],
		nombreClass: ['form-control'],
		emailClass: ['form-control'],
		telefonoClass: ['form-control'],
		passwordClass: ['form-control'],
		estadoClass: ['form-control']
	}

	actualizarProveedor(){
		this.prepararData();
		let enabled_act = this.validarData(this.proveedorActual, this.proveedorEditar);

		if(enabled_act){

			const data = {
				'codigoCategoria': this.proveedorEditar.codigoCategoria, 
				'nombreProveedor' : this.proveedorEditar.nombreProveedor,
				'emailProveedor' : this.proveedorEditar.emailProveedor,
				'telefonoProveedor' : this.proveedorEditar.telefonoProveedor,
				'passwordProveedor' : this.proveedorEditar.passwordProveedor,
				'estadoProveedor' : this.proveedorEditar.estadoProveedor
			};

			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.proveedoresService.putProveedores(this.idproveedor, data).subscribe( (res:any) => {
				Swal.close();
				if(!res.error){
					Swal.fire('Actualizado', 'Proveedor actualizado', 'success').then( () => {
						this.cargarProveedor();
						this.cargarProveedorFiltros();
					});
				}else{
					Swal.fire('Error', res.msg, 'error')
				}
			}, (err:any) =>{
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error')
			})

		}else{
			console.error('Error en formulario');
		}
	}

	/*===========================================
	=            AGREGAR COMPONENTES            =
	===========================================*/
	agregarSede(){
		let sede = this.sedeAdd.trim();
		if(sede != '0'){

			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.proveedoresService.postProveedores(sede, this.idproveedor, 'sedes').subscribe( (res:any) => {
				Swal.close();
				if(!res.error){
					Swal.fire('Agregado', 'Sede agregada', 'success').then( () => {
						this.sedeAdd = '0';
						this.cargarProveedorSedes();
					})
				}
			}, (err:any) => {
				Swal.close();
				console.error(err.error);
				Swal.fire('Error', err.error.msg, 'error');
			})
		}else{
			this.sedeClass.push('is-invalid');
		}
	}

	agregarFiltro(){
		let filtro = this.filtroAdd.trim();
		if(filtro != '0'){

			const data = {
				'codigoFiltro': filtro,
				'codigoCategoria': this.proveedorActual.codigoCategoria
			}

			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.proveedoresService.postProveedores(data, this.idproveedor, 'filtros').subscribe( (res:any) => {
				Swal.close();
				if(!res.error){
					Swal.fire('Agregado', 'Filtro agregado', 'success').then( () => {
						this.filtroAdd = '0';
						this.cargarProveedorFiltros();
					})
				}
			}, (err:any) => {
				Swal.close();
				console.error(err.error);
				Swal.fire('Error', err.error.msg, 'error');
			})
		}else{
			this.filtroClass.push('is-invalid');
		}
	}
	
	

	/*==================================
	=            MULTIMEDIA            =
	==================================*/
	public imagePath;
  	imgURL: any;
  	public message: string;

  	multimediaChanged = {
		imagen: ""
	};

	handleFileInput(files: FileList) {
		/*verificar si hay un archivo*/
		if (files.length === 0)
			return;
		/*validar que sea imagen*/
		var mimeType = files[0].type;
		if (mimeType.match(/image\/*/) == null) {
			this.message = "Only images are supported.";
			return;
		}
		var reader = new FileReader();
		// this.imagePath = files;
		reader.readAsDataURL(files[0]); 
		reader.onload = (_event) => { 
			this.imgURL = reader.result; 
		}
		this.fileToUpload = files[0];
	}

	uploadImagen() {
		if(this.fileToUpload){
			Swal.fire({allowOutsideClick: false});
			Swal.showLoading();
			this.proveedoresService.putProveedorImagen(this.fileToUpload, this.idproveedor, this.multimediaChanged.imagen).subscribe( (res:any) => {
				if(!res.error){
					Swal.close();
					Swal.fire('Actualizado', 'La imagen se actualizó', 'success').then(()=>{
						this.imgURL = "";
						this.fileToUpload = null;
						this.cargarProveedor();
					})
				}else{
					Swal.close();
					Swal.fire('Error', 'No se actualizó', 'error');
				}
			}, err => {
				Swal.close();
				console.error(err);
				Swal.fire('Error', err.error.msg, 'error');
				
			});
		}else{
			console.error('no hay archivo');
		}
	}

	/*==========================================
	=            ELIMINAR PROVEEDOR            =
	==========================================*/
	eliminarProveedor(){
		Swal.fire({
			title: '¿Quieres eliminar el proveedor?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.proveedoresService.deleteProveedores(this.idproveedor).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Proveedor eliminado', 'success').then( () => {
							this.router.navigateByUrl('main/socios');
						})
					}else{
						Swal.fire('Error', res.msg, 'error');
					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}

	eliminarSede(sede, proveedor){
		Swal.fire({
			title: '¿Quieres eliminar la sede al proveedor?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.proveedoresService.deleteProveedores(this.idproveedor, 'sedes', sede).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Sede eliminada', 'success').then( () => {
							this.cargarProveedorSedes();
						})
					}else{
						Swal.fire('Error', res.msg, 'error');
					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}

	eliminarFiltro(filtro, proveedor){
		Swal.fire({
			title: '¿Quieres eliminar el filtro al proveedor?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.proveedoresService.deleteProveedores(this.idproveedor, 'filtros', filtro).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Filtro eliminado', 'success').then( () => {
							this.cargarProveedorFiltros();
						})
					}else{
						Swal.fire('Error', res.msg, 'error');
					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}


	/*================================
	=            OPCIONES            =
	================================*/
	cargarSedes(){
		this.cargandoSedes = true;
		this.configService.getSedes().subscribe( (res:any) => {
			this.cargandoSedes = false;
			this.sedes = res.sedes;
		}, (err:any) => {
			this.cargandoSedes = false;
			console.error(err.error.msg);
		})
	}

	cargarCategorias(){
		this.cargandoCategorias = true;
		this.configService.getCategorias().subscribe( (res:any) => {
			this.cargandoCategorias = false;
			let cat = res.categorias;

			// this.categorias = res.categorias;

			for (var i = 0; i < cat.length; i++) {
				if(cat[i].estadoCategoria == '1'){
					this.categorias.push(cat[i]);
				}else{
					this.categoriasDisabled.push(cat[i]);
				}
			}


		}, (err:any) => {
			this.cargandoCategorias = false;
			console.error(err.error.msg);
		})
	}


	cargarFiltros(){
		this.cargandoFiltros = true;
		this.configService.getFiltros(null, 'categoria', this.proveedorActual.codigoCategoria).subscribe( (res:any) => {
			this.cargandoFiltros = false;
			this.filtros = res.filtros;
		}, (err:any) => {
			this.cargandoFiltros = false;
			console.error(err.error.msg);
		})
	}


	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	validarData(actual, editar){
		let a:number = 5;
		let cambios:boolean = false;

		if(editar.codigoCategoria.length == 0 || editar.codigoCategoria == "0"){
			this.classEdit.categoriaClass.push('is-invalid');
			a--;
		}

		if(editar.nombreProveedor.length == 0 || editar.nombreProveedor.length > 50 ){
			this.classEdit.nombreClass.push('is-invalid');
			a--;
		}

		if(editar.emailProveedor.length > 0 ) {
			if(editar.emailProveedor.length > 50 ){
				this.classEdit.emailClass.push('is-invalid');
				a--;
			}
		}

		if(editar.telefonoProveedor.length > 0){
			if(editar.telefonoProveedor.length != 10 ){
				this.classEdit.telefonoClass.push('is-invalid');
				a--;
			}
		}

		if(editar.passwordProveedor.length != 5 ){
			this.classEdit.passwordClass.push('is-invalid');
			a--;
		}

		if(
			editar.codigoCategoria != actual.codigoCategoria ||
			editar.nombreProveedor != actual.nombreProveedor ||
			editar.emailProveedor != actual.emailProveedor ||
			editar.telefonoProveedor != actual.telefonoProveedor ||
			editar.passwordProveedor != actual.passwordProveedor ||
			editar.estadoProveedor != actual.estadoProveedor 
			){
			cambios = true;
		}else{
			cambios = false;
			console.warn('No hay cambios');
			Swal.fire('Alerta', 'No hay cambios para realizar', 'info');
		}


		if( a == 5 && cambios){
			return true;
		}else{
			return false;
		}
	}

	quitarInvalidTag(tipo){
		switch (tipo) {
			case "categoria":
				this.removeItemFromArr(this.classEdit.categoriaClass, 'is-invalid');
				break;
			case "nombre":
				this.removeItemFromArr(this.classEdit.nombreClass, 'is-invalid');
				break;
			case "email":
				this.removeItemFromArr(this.classEdit.emailClass, 'is-invalid');
				break;
			case "telefono":
				this.removeItemFromArr(this.classEdit.telefonoClass, 'is-invalid');
				break;
			case "sede":
				this.removeItemFromArr(this.sedeClass, 'is-invalid');
				break;
			case "filtro":
				this.removeItemFromArr(this.filtroClass, 'is-invalid');
				break;
			default:
				console.error('no existe tipo tag');
				break;
		}
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	categoriaActual:string;
	inicilizarVariables(data){


		this.proveedorActual.codigoCategoria = data.idcategoria.trim();
		this.proveedorActual.nombreProveedor = data.proveedor.trim();
		this.proveedorActual.emailProveedor = data.email.trim();
		this.proveedorActual.telefonoProveedor = data.telefono.trim();
		this.proveedorActual.passwordProveedor = data.password.trim();
		this.proveedorActual.estadoProveedor = data.estado.trim();

		this.proveedorEditar.codigoCategoria = data.idcategoria.trim();
		this.proveedorEditar.nombreProveedor = data.proveedor.trim();
		this.proveedorEditar.emailProveedor = data.email.trim();
		this.proveedorEditar.telefonoProveedor = data.telefono.trim();
		this.proveedorEditar.passwordProveedor = data.password.trim();
		this.proveedorEditar.estadoProveedor = data.estado.trim();
		this.categoriaActual = data.categoria.trim();

		this.multimediaChanged.imagen = data.imagen;

		this.cargarFiltros();
	}

	prepararData(){
		this.proveedorEditar.codigoCategoria = this.proveedorEditar.codigoCategoria.trim();
		this.proveedorEditar.nombreProveedor = this.proveedorEditar.nombreProveedor.trim();
		this.proveedorEditar.emailProveedor = this.proveedorEditar.emailProveedor.trim();
		this.proveedorEditar.telefonoProveedor = this.proveedorEditar.telefonoProveedor.trim();
		this.proveedorEditar.passwordProveedor = this.proveedorEditar.passwordProveedor.trim();
		this.proveedorEditar.estadoProveedor = this.proveedorEditar.estadoProveedor.trim();
	}

}
