import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ProductosService {

	constructor(private http:HttpClient,
				private aService:AuthService) {
		this.TOKEN = this.aService.leerToken();
	}

	/*constantes*/
	APIKEY:string = environment.APIKEY;
	APIURL:string = environment.APIURL;
	TOKEN:string;


	public getProductos(){
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let url:string = this.APIURL + 'productos';
		return this.http.get(url, { headers } );
	}
}
