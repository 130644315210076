import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';




@Injectable({
	providedIn: 'root'
})
export class ComprasService {

	constructor(private http:HttpClient,
				private aService:AuthService) {
		this.TOKEN = this.aService.leerToken();
	}

	/*==================================
	=            CONSTANTES            =
	==================================*/
	APIKEY:string = environment.APIKEY;
	APIURL:string = environment.APIURL;
	TOKEN:string;


	/*===============================
	=            COMPRAS            =
	================================*/
	getCompras(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'compras';
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postCompras(data){
		let url:string = this.APIURL + 'compras';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body.append('codigoCliente', data.codigoCliente);
			body.append('codigoPromocion', data.codigoPromocion);
			body.append('fechaCompra', data.fechaCompra);
			body.append('montoCompra', data.montoCompra);

		return this.http.post(url, body, { headers } );
	}

	putCompras(id, data){
		let url:string = this.APIURL + 'compras/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteCompras(id){
		let url:string = this.APIURL + 'compras/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}


}
