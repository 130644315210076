<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<h3>CATEGORÍAS</h3>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-app bg-success ml-0" (click)="openModalAgregar(agregar)">
				<i class="fas fa-plus"></i>
				Agregar
			</button>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">

			<div class="card card-dark">
				<div class="card-header">
					<h3>Listado de categorías</h3>
				</div>


				<div class="card-body" *ngIf="cargandoCategorias">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>


				<div class="card-body table-responsive" *ngIf="!cargandoCategorias">

					<div class="alert"  *ngIf="categorias.length == 0"><i class="fas fa-exclamation-triangle"></i> No hay información para mostrar!</div>
					


					<table class="table table-striped" *ngIf="categorias.length > 0">
						<thead>
							<tr class="bg-secondary">
								<th>#</th>
								<th>CATEGORÍA</th>
								<th>IMAGEN</th>
								<th>ESTADO</th>
								<th>OPCIONES</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let a of categorias; let i = index">
								<th>{{i + 1}}</th>
								<th>{{a.nombreCategoria}}</th>
								<th *ngIf="a.imgCatergoria"><i class="fas fa-file-image text-success"></i></th>
								<th *ngIf="!a.imgCatergoria"><i class="fas fa-file-image text-danger"></i></th>
								<th *ngIf="a.estadoCategoria ==  1"><i class="fas fa-circle text-success"></i></th>
								<th *ngIf="a.estadoCategoria !=  1"><i class="fas fa-circle text-danger"></i></th>
								<th>
									<button class="btn btn-primary btn-sm mr-2" title="editar" [routerLink]="[a.codigoCategoria]"><i class="fas fa-edit"></i></button>
								</th>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-footer"></div>
			</div>

		</div>
	</div>
</div>




<!--============================
=            MODALS            =
=============================-->

<ng-template #agregar let-modalAgregar>

	<div class="modal-header bg-dark">
		<h5 class="modal-title" id="agregarPromocion_modalLabel">AGREGAR CATEGORÍA</h5>
		<button type="button" class="close" (click)="cerrarModalAgregar()">
			<span aria-hidden="true" class="text-white">&times;</span>
		</button>
	</div>

	<div class="modal-body">

		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label>Nombre categoría *  <small>(Max 50 caracteres)</small></label>
					<input type="text" [(ngModel)]="nombreAdd" [ngClass]='nombreClass' placeholder="Nombre" maxlength="50" (keyup)="quitarInvalidName()">
				</div>
			</div>
		</div>
	</div>

	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="cerrarModalAgregar()">Cerrar</button>
		<button type="button" class="btn btn-success" (click)="agregarCategoria()"><i class="fas fa-save"></i> Guardar</button>
	</div>

</ng-template>