<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<h2>DETALLES SOCIO</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-md-4">
			<button class="btn btn-primary" [routerLink]="['/main/socios']"><i class="fas fa-long-arrow-alt-left"></i> Regresar</button>
		</div>
		<div class="col-md-4">
			
		</div>
		<div class="col-md-4">
			<button class="btn bg-danger btn-app float-right" (click)="eliminarProveedor()"><i class="fas fa-times"></i> Eliminar</button>
		</div>
	</div>

	<div class="row" *ngIf="!cargandoProveedores && proveedorEditar.estadoProveedor != '1'">
		<div class="col-md-12">
			<div class="alert alert-warning"><i class="fas fa-exclamation-triangle"></i> El proveedor está marcado como <b>inactivo</b> y no podrá ser seleccionable en las promociones!</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="callout callout-info">
				<h5>Información!</h5>
				<p>Los campos marcados con <b>*</b> son obligatorios.</p>
			</div>
		</div>
	</div>

	<div class="row">

		<div class="col-md-6">
			<div class="card card-dark">
				<div class="card-header">DATOS GENERALES</div>

				<!-- loading -->
				<div class="card-body" *ngIf="cargandoProveedores">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>

				<div class="card-body" *ngIf="!cargandoProveedores">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<label>Categoría *</label>
								<div *ngIf="cargandoCategorias" class="spinner-border spinner-border-sm" role="status">
									<span class="sr-only">Loading...</span>
								</div>
								<select *ngIf="!cargandoCategorias" [ngClass]='classEdit.categoriaClass' style="width: 100%;" [(ngModel)]="proveedorEditar.codigoCategoria" (change)="quitarInvalidTag('categoria')">
									<!-- <option *ngFor="let a of categorias" [value]="a.codigoCategoria">{{a.nombreCategoria}}</option> -->
										<option *ngFor="let a of categorias" [value]="a.codigoCategoria">{{a.nombreCategoria}}</option>
										<hr>
										<option *ngFor="let a of categoriasDisabled" [value]="a.codigoCategoria" disabled>{{a.nombreCategoria}}</option>
								</select>
								<span *ngIf="proveedorEditar.codigoCategoria != proveedorActual.codigoCategoria" class="text-warning text-bold">Al cambiar la categoría, se eliminarán las características que no correspondan a la nueva categoría.</span>
							</div>
				        </div>

				        <div class="col-md-12">
				        	<div class="form-group">
				        		<label>Nombres *  <small>(Max 50 caracteres)</small></label>
				        		<input type="text" [(ngModel)]="proveedorEditar.nombreProveedor" [ngClass]='classEdit.nombreClass' placeholder="Nombres" maxlength="50" (keyup)="quitarInvalidTag('nombre')">
				        	</div>
				        </div>

				        <div class="col-md-12">
				        	<div class="form-group">
				        		<label>Email  <small>(Max 50 caracteres)</small></label>
				        		<input type="email" [(ngModel)]="proveedorEditar.emailProveedor" [ngClass]='classEdit.emailClass' placeholder="Email" maxlength="50" (keyup)="quitarInvalidTag('email')">
				        	</div>
				        </div>

				        <div class="col-md-12">
				        	<div class="form-group">
				        		<label>Teléfono <small>(10 caracteres)</small></label>
				        		<input type="text" [(ngModel)]="proveedorEditar.telefonoProveedor" [ngClass]='classEdit.telefonoClass' placeholder="Teléfono" maxlength="10" (keyup)="quitarInvalidTag('telefono')">
				        	</div>
				        </div>

				        <div class="col-md-6">
				        	<div class="form-group">
				        		<label>Password  *<small>(5 caracteres)</small></label>
				        		<input type="text" [(ngModel)]="proveedorEditar.passwordProveedor" [ngClass]='classEdit.passwordClass' placeholder="Password" maxlength="5" (keyup)="quitarInvalidTag('password')">
				        	</div>
				        </div>


				        <div class="col-md-6">
				        	<div class="form-group">
				        		<label>Estado *</label>
				        		<select [ngClass]='classEdit.estadoClass' style="width: 100%;" [(ngModel)]="proveedorEditar.estadoProveedor">
				        			<option selected value="1"> Activo </option>
				        			<option selected value="0"> Inactivo </option>
				        		</select>
				        	</div>
				        </div>
					</div>
				</div>

				<div class="card-footer">
					<button class="btn btn-primary float-right" (click)="actualizarProveedor()">Actualizar</button>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="card card-dark">
				<div class="card-header">DATOS MULTIMEDIA</div>

				<!-- loading -->
				<div class="card-body" *ngIf="cargandoProveedores">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>

				<div class="card-body" *ngIf="!cargandoProveedores">

					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<input class="custom-file-input" #file id="file" type="file" accept='image/*' (change)="handleFileInput(file.files)" />
								<label class="custom-file-label" for="file">Choose file</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label for="">Selección</label>
							<img [src]="imgURL" width="100%" *ngIf="imgURL">
						</div>
						<div class="col-md-6">
							<label for="">Imagen actual</label>
							<img *ngIf="multimediaChanged.imagen" [src]="multimediaChanged.imagen" alt="" width="100%">
							<img *ngIf="!multimediaChanged.imagen" src="assets/img/noimage.jpg" alt="" width="100%">
						</div>
					</div>
				</div>

				<div class="card-footer">
					<button class="btn btn-primary float-right" (click)="uploadImagen()">Actualizar</button>
				</div>
			</div>
		</div>
	</div>

	<div class="row">

		<div class="col-md-6">
			<div class="card card-dark">
				<div class="card-header">DATOS SEDES</div>
				<!-- loading -->
				<div class="card-body" *ngIf="cargandoProveedorSedes">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>

				<div class="card-body" *ngIf="!cargandoProveedorSedes">
					<div class="row">
						<div class="col-md-12">

							<div class="input-group" *ngIf="cargandoSedes">
								Cargando opciones
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="sr-only">Loading...</span>
								</div>
							</div>

							<div class="input-group" *ngIf="!cargandoSedes">
								<select [(ngModel)]="sedeAdd" [(ngClass)]="sedeClass" (change)="quitarInvalidTag('sede')">
									<option value="0">-- Selecciona una sede --</option>
									<option *ngFor="let s of sedes" [value]="s.codigoSede">{{s.nombreSede}}</option>
								</select>
								<div class="input-group-append">
									<button class="btn btn-success" (click)="agregarSede()"><i class="fas fa-plus"></i> Agregar</button>
								</div>
							</div>
						</div>
					</div>


					<div class="row mt-2">
						<div class="col-md-12 table-responsive">


							<div class="alert" *ngIf="sedesproveedor.length == 0"><i class="fas fa-exclamation-triangle"></i> No hay información para mostrar!</div>

							<table class="table" *ngIf="sedesproveedor.length > 0">
								<thead class="bg-secondary">
									<th>SEDE</th>
									<th>OPCIONES</th>
								</thead>
								<tbody>
									<tr *ngFor="let s of sedesproveedor">
										<td>{{s.sede}}</td>
										<td><button class="btn btn-sm btn-danger" (click)="eliminarSede(s.idsede, s.idproveedor)"><i class="fas fa-times"></i></button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					
				</div>

				<div class="card-footer"></div>
			</div>
		</div>

		<div class="col-md-6">
			<div class="card card-dark">
				<div class="card-header">DATOS CARACTERÍSTICAS</div>
				<!-- loading -->
				<div class="card-body" *ngIf="cargandoProveedorFiltros">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>

				<div class="card-body" *ngIf="!cargandoProveedorFiltros">
					<div class="row">
						<div class="col-md-12">

							<div class="input-group" *ngIf="cargandoFiltros">
								Cargando opciones
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="sr-only">Loading...</span>
								</div>
							</div>

							<div class="input-group" *ngIf="!cargandoFiltros">
								<select [(ngModel)]="filtroAdd" [(ngClass)]="filtroClass" (change)="quitarInvalidTag('filtro')">
									<option value="0">-- Selecciona una característica --</option>
									<option *ngFor="let f of filtros" [value]="f.codigoFiltro">{{f.nombreFiltro}}</option>
								</select>
								<div class="input-group-append">
									<button class="btn btn-success" (click)="agregarFiltro()"><i class="fas fa-plus"></i> Agregar</button>
								</div>
							</div>
						</div>
					</div>


					<div class="row mt-2">
						<div class="col-md-12 table-responsive">


							<div class="alert" *ngIf="filtrosproveedor.length == 0"><i class="fas fa-exclamation-triangle"></i> No hay información para mostrar!</div>

							<table class="table" *ngIf="filtrosproveedor.length > 0">
								<thead class="bg-secondary">
									<th>CARACTERÍSTICA</th>
									<th>OPCIONES</th>
								</thead>
								<tbody>
									<tr *ngFor="let f of filtrosproveedor">
										<td>{{f.nombreFiltro}}</td>
										<td><button class="btn btn-sm btn-danger" (click)="eliminarFiltro(f.codigoFiltro, f.codigoProveedor)"><i class="fas fa-times"></i></button></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					
				</div>

				<div class="card-footer"></div>
			</div>
		</div>

	</div>




</div>