<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<h3>MEMBRESÍAS</h3>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<button class="btn btn-app bg-success ml-0" (click)="openModalAgregar(agregar)">
				<i class="fas fa-plus"></i>
				Agregar
			</button>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">

			<div class="card card-dark">
				<div class="card-header">
					<h3>Listado de membresías</h3>
				</div>


				<div class="card-body" *ngIf="cargandoMembresias">
					<div class="text-center">
				        <br> <br>
				        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
				          <span class="sr-only">Loading...</span>
				        </div>
				        <br><br>
				    </div>
				</div>


				<div class="card-body table-responsive" *ngIf="!cargandoMembresias">
          <div *ngIf="membresias.length == 0" class="alert"><i class="fas fa-exclamation-triangle"></i>No hay información para mostrar!</div>
					<table *ngIf="membresias.length > 0" class="table table-striped">
						<thead>
							<tr class="bg-secondary">
								<th>#</th>
								<th>ID</th>
								<th>MEMBRESÍA</th>
								<th>COLOR</th>
								<th>ESTADO</th>
								<th>OPCIONES</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let a of membresias; let i = index">
								<th>{{i + 1}}</th>
								<th>{{a.codigoMembresia}}</th>
								<th>{{a.nombreMembresia}}</th>
								<th>{{a.colormembresia}}</th>
								<th *ngIf="a.estadoMembresia ==  1"><i class="fas fa-circle text-success"></i></th>
								<th *ngIf="a.estadoMembresia !=  1"><i class="fas fa-circle text-danger"></i></th>
								<th>
									<button class="btn btn-primary btn-sm mr-2" title="editar" (click)="openModalEditar(editar, a)"><i class="fas fa-edit"></i></button>
									<button class="btn btn-danger btn-sm mr-2" title="eliminar" (click)="eliminarMembresia(a.codigoMembresia)"><i class="fas fa-times"></i></button>
								</th>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="card-footer"></div>
			</div>

		</div>
	</div>
</div>


<!--============================
=            MODALS            =
=============================-->

<ng-template #agregar let-modalAgregar>

   <div class="modal-header bg-dark">
      <h5 class="modal-title" id="agregarPromocion_modalLabel">AGREGAR MEMBRESÍA</h5>
      <button type="button" class="close" (click)="cerrarModalAgregar()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>


    <div class="modal-body">

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Código *  <small>(Max 1 caracter)</small></label>
            <input type="text" [(ngModel)]="membresiaAdd.codigo" [ngClass]='idClass' placeholder="Código" maxlength="1" (keyup)="quitarInvalidTag('codigo')">
          </div>
        </div>

        <div class="col-md-8">
          <div class="form-group">
            <label>Nombre membresía *  <small>(Max 50 caracteres)</small></label>
            <input type="text" [(ngModel)]="membresiaAdd.nombre" [ngClass]='nombreClass' placeholder="Nombre" maxlength="50" (keyup)="quitarInvalidTag('nombre')">
          </div>
        </div>

        <div class="col-md-8">
          <div class="form-group">
            <label>Color * </label>
            <select [ngClass]='colorClass' style="width: 100%;" [(ngModel)]="membresiaAdd.color" (change)="quitarInvalidTag('color')">
              <option value = ''>-- Selecciona un color -- </option>
              <option value = 'warning'>Amarillo</option>
              <option value = 'primary'>Azul</option>
              <option value = 'secondary'>Azul claro </option>
              <option value = 'medium'>Gris </option>
              <option value = 'light'>Gris claro </option>
              <option value = 'tertiary'>Morado </option>
              <option value = 'dark'>Negro </option>
              <option value = 'danger'>Rojo </option>
              <option value = 'success'>Verde </option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Estado *</label>
            <select class='form-control' style="width: 100%;" [(ngModel)]="membresiaAdd.estado">
              <option value = 1>Activo</option>
              <option value = 0>Inactivo</option>
            </select>
          </div>
        </div>
      </div>     

    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalAgregar()">Cerrar</button>
      <button type="button" class="btn btn-success" (click)="agregarMembresia()"><i class="fas fa-save"></i> Guardar</button>
    </div>

</ng-template>



<ng-template #editar let-modalEditar>

   <div class="modal-header bg-dark">
      <h5 class="modal-title" id="agregarPromocion_modalLabel">EDITAR MEMBRESÍA</h5>
      <button type="button" class="close" (click)="cerrarModalEditar()">
        <span aria-hidden="true" class="text-white">&times;</span>
      </button>
    </div>

    <div class="modal-body">

      <div class="row">
<!--         <div class="col-md-4">
          <div class="form-group">
            <label>Código *  <small>(Max 1 caracter)</small></label>
            <input type="text" [(ngModel)]="membresiaEditar.codigoMembresia" [ngClass]='idClass' placeholder="Código" maxlength="1" (keyup)="quitarInvalidTag('codigo')">
          </div>
        </div> -->

        <div class="col-md-8">
          <div class="form-group">
            <label>Nombre membresía *  <small>(Max 50 caracteres)</small></label>
            <input type="text" [(ngModel)]="membresiaEditar.nombreMembresia" [ngClass]='nombreClass' placeholder="Nombre" maxlength="50" (keyup)="quitarInvalidTag('nombre')">
          </div>
        </div>

        <div class="col-md-8">
          <div class="form-group">
            <label>Color * </label>
            <select [ngClass]='colorClass' style="width: 100%;" [(ngModel)]="membresiaEditar.colorMembresia" (change)="quitarInvalidTag('color')">
              <option value = ''>-- Selecciona un color -- </option>
              <option value = 'warning'>Amarillo</option>
              <option value = 'primary'>Azul</option>
              <option value = 'secondary'>Azul claro </option>
              <option value = 'medium'>Gris </option>
              <option value = 'light'>Gris claro </option>
              <option value = 'tertiary'>Morado </option>
              <option value = 'dark'>Negro </option>
              <option value = 'danger'>Rojo </option>
              <option value = 'success'>Verde </option>
            </select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>Estado *</label>
            <select class='form-control' style="width: 100%;" [(ngModel)]="membresiaEditar.estadoMembresia">
              <option value = 1>Activo</option>
              <option value = 0>Inactivo</option>
            </select>
          </div>
        </div>
      </div>     

    </div>


    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cerrarModalEditar()">Cerrar</button>
      <button type="button" class="btn btn-primary" (click)="actualizarMembresia()"><i class="fas fa-edit"></i> Actualizar</button>
    </div>

</ng-template>



