import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})



export class LoginComponent implements OnInit {

	constructor(private authService:AuthService,
				private router:Router) {

	}

	ngOnInit(): void {

	}

	login(usuario, contrasena){
		this.authService.obtenerToken(usuario, contrasena).subscribe( (data) => {
			console.log(data);
			this.router.navigateByUrl('/main/home');
		}, (err) =>{
			console.log(err.error[0].msg);
		});
	}



}
