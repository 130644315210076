<nav class="navbar navbar-expand-lg navbar-dark bg-black">

  <a class="navbar-brand" href="#">
    <img src="assets/img/privato_.png" width="30" height="30" class="d-inline-block align-top" alt="" loading="lazy">
    Privato Player App
  </a>


  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item ">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['home']">Home</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['noticias']">Noticias</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['socios']">Socios</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['clientes']">Clientes</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['compras']">Compras</a>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" routerLinkActive="active">
          Configuraciones
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" [routerLink]="['configuraciones/categorias']" routerLinkActive="active">Categorías</a>
          <a class="dropdown-item" [routerLink]="['configuraciones/deportes']" routerLinkActive="active">Deportes</a>
          <a class="dropdown-item" [routerLink]="['configuraciones/caracteristicas']" routerLinkActive="active">Características</a>
          <a class="dropdown-item" [routerLink]="['configuraciones/instituciones']" routerLinkActive="active">Instituciones</a>
          <a class="dropdown-item" [routerLink]="['configuraciones/ligas']" routerLinkActive="active">Ligas</a>
          <a class="dropdown-item" [routerLink]="['configuraciones/membresias']" routerLinkActive="active">Membresías</a>
          <a class="dropdown-item" [routerLink]="['configuraciones/productos']" routerLinkActive="active">Productos</a>
          <a class="dropdown-item" [routerLink]="['configuraciones/sedes']" routerLinkActive="active">Sedes</a>
        </div>
      </li>

    </ul>
    <div class="form-inline my-2 my-lg-0">
      <button class="btn btn-outline-danger my-2 my-sm-0" (click)="salir()">Salir</button>
    </div>
  </div>
</nav>