import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';import {NgbModal, ModalDismissReasons, NgbDateStruct, NgbDate, NgbCalendar, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

import { ConfiguracionesService } from '../../../../services/configuraciones.service';

@Component({
	selector: 'app-asociaciones',
	templateUrl: './asociaciones.component.html',
	styles: [
	]
})
export class AsociacionesComponent implements OnInit {



	constructor(private configService: ConfiguracionesService,
				private modalService: NgbModal) {
		this.cargarAsociaciones();
	}

	ngOnInit(): void {}



	/*=================================
	=            VARIABLES            =
	=================================*/
	asociaciones:string[] = [];
	cargandoAsociaciones:boolean = true;

	/*============================================
	=            OBTENER ASOCIACIONES            =
	============================================*/
	cargarAsociaciones(){
		this.cargandoAsociaciones = true;
		this.configService.getAsociaciones().subscribe( (res:any) =>{
			this.cargandoAsociaciones = false;
			if(!res.error){
				this.asociaciones = res.asociaciones
			}
		}, (err:any) => {
			this.cargandoAsociaciones = false;
			console.error(err);
		})
	}
	
	
	/*==========================================
	=            AGREGAR ASOCIACIÓN            =
	==========================================*/
	modalAgregar:any;

	nombreAdd:string = "";
	nombreClass:string[] = ['form-control'];

	openModalAgregar(agregarA) {
		this.modalAgregar = this.modalService.open(agregarA, {backdrop:'static', size: 'lg'});
		this.modalAgregar.result.then((result) => {
		}, (reason) => {
		});
	}

	cerrarModalAgregar(){

		this.modalAgregar.close('cerrar');
		this.nombreAdd = "";
	}

	agregarAsociacion(){
		let nombre = this.nombreAdd.trim();
		if(nombre){
			if(nombre.length > 0 && nombre.length < 101){

				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.configService.postAsociaciones(nombre).subscribe( (res:any) =>{
					Swal.close();
					if(!res.error){
						Swal.fire('Agregado', 'Asociación agregada', 'success').then( () => {
							this.nombreAdd = "";
							this.cerrarModalAgregar();
							this.cargarAsociaciones();
						});
					}else{
						Swal.fire('Error', res.msg, 'error');
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}else{
				console.error('Error en cantidad de caracteres');
				this.nombreClass.push('is-invalid');
			}
		}else{
			console.error('No hay nombre');
			this.nombreClass.push('is-invalid');
		}
	}



	/*=========================================
	=            EDITAR ASOCIACIÓN            =
	=========================================*/
	modalEditar:any;

	asociacionActual = {
		codigoAsociacion: "",
		nombreAsociacion: ""
	}

	asociacionEditar = {
		codigoAsociacion: "",
		nombreAsociacion: ""
	}
	

	openModalEditar(editarA, actual) {

		this.asociacionEditar.codigoAsociacion = actual.codigoAsociacion;
		this.asociacionEditar.nombreAsociacion = actual.nombreAsociacion;
		this.asociacionActual.codigoAsociacion = actual.codigoAsociacion;
		this.asociacionActual.nombreAsociacion = actual.nombreAsociacion;

		this.modalEditar = this.modalService.open(editarA, {backdrop:'static', size: 'lg'});
		this.modalEditar.result.then((result) => {
		}, (reason) => {
		});

	}

	cerrarModalEditar(){
		this.modalEditar.close('cerrar');
		this.asociacionEditar.nombreAsociacion = "";
	}

	actualizarAsociacion(){
		const nombre = this.asociacionEditar.nombreAsociacion.trim();

		if(nombre.length > 0 && nombre.length < 101){

			if(nombre != this.asociacionActual.nombreAsociacion.trim()){
				const data = {'nombre': nombre};
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();
				this.configService.putAsociaciones(this.asociacionActual.codigoAsociacion, data).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Actualizado', 'Asociación actualizada', 'success').then( () => {
							this.cerrarModalEditar();
							this.cargarAsociaciones();
						});
					}else{
						Swal.fire('Error', res.msg, 'error')
					}
				}, (err:any) =>{
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error')
				})

			}else{
				console.error('no hay cambios para realizar');
				Swal.fire('Info', 'No hay cambios para realizar', 'info');
			}
		}else{
			console.error('error en caracteres de nombre');
			this.nombreClass.push('is-invalid');

		}

	}


	/*===========================================
	=            ELIMINAR ASOCIACIÓN            =
	===========================================*/
	eliminarAsociacion(id){

		Swal.fire({
			title: '¿Quieres eliminar la asociación?',
			text: "Este cambio ya no se podrá revertir!",
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'bg-danger',
				cancelButton: 'bg-secondary'
			},
			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar!',

		}).then((result) => {
			if (result.value) {
				Swal.fire({allowOutsideClick: false});
				Swal.showLoading();

				this.configService.deleteAsociaciones(id).subscribe( (res:any) => {
					Swal.close();
					if(!res.error){
						Swal.fire('Eliminado', 'Asociación eliminada', 'success').then( () => {
							this.cargarAsociaciones();
						})
					}else{
						Swal.fire('Error', res.msg, 'error');

					}
				}, (err:any) => {
					Swal.close();
					console.error(err);
					Swal.fire('Error', err.error.msg, 'error');
				})

			}
		})
	}

	/*==========================================
	=            FUNCIONES DE AYUDA            =
	==========================================*/
	removeItemFromArr( array, item ) {
		var i = array.indexOf( item );
		if ( i !== -1 ) {
			array.splice( i, 1 );
		}
	}

	quitarInvalidName(){
		this.removeItemFromArr(this.nombreClass, 'is-invalid');
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

}
