import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';


import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MainGuard implements CanActivate {

	constructor(private authService:AuthService,
				private router:Router){

	}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		console.log('canLoad');
		/*VALIDAR QUE EL USUARIO ESTÉ ACTIVO*/
		if(this.authService.validarAutenticacion()){
			return this.authService.validarClienteEstado();
		}else{
			this.router.navigateByUrl('/login')
			return false;
		}
	}
  
}
