import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesService {


	constructor(private http:HttpClient,
				private aService:AuthService) {
		this.TOKEN = this.aService.leerToken();
	}

	/*constantes*/
	APIKEY:string = environment.APIKEY;
	APIURL:string = environment.APIURL;
	TOKEN:string;

	/*====================================
	=            ASOCIACIONES            =
	====================================*/
	getAsociaciones(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'asociaciones';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			return this.http.get(url, { headers } );
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postAsociaciones(data){

		let url:string = this.APIURL + 'asociaciones';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body.append('nombre', data);

		return this.http.post(url, body, { headers } );
	}

	putAsociaciones(id, data){
		let url:string = this.APIURL + 'asociaciones/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteAsociaciones(id){
		let url:string = this.APIURL + 'asociaciones/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}

	/*=====================================
	=            INSTITUCIONES            =
	=====================================*/
	getInstituciones(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'instituciones';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			return this.http.get(url, { headers } );
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postInstituciones(data){

		let url:string = this.APIURL + 'instituciones';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body.append('nombre', data);

		return this.http.post(url, body, { headers } );
	}

	putInstituciones(id, data){
		let url:string = this.APIURL + 'instituciones/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteInstituciones(id){
		let url:string = this.APIURL + 'instituciones/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}

	/*=============================
	=            LIGAS            =
	=============================*/
	getLigas(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'ligas';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			return this.http.get(url, { headers } );
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postLigas(data){

		let url:string = this.APIURL + 'ligas';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body = data;

		return this.http.post(url, body, { headers } );
	}

	putLigas(id, data){
		let url:string = this.APIURL + 'ligas/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteLigas(id){
		let url:string = this.APIURL + 'ligas/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}

	/*==================================
	=            MEMBRESÍAS            =
	==================================*/
	getMembresias(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'membresias';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			return this.http.get(url, { headers } );
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postMembresias(data){

		let url:string = this.APIURL + 'membresias';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body = data;

		return this.http.post(url, body, { headers } );
	}

	putMembresias(id, data){
		let url:string = this.APIURL + 'membresias/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteMembresias(id){
		let url:string = this.APIURL + 'membresias/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}


	/*==================================
	=            CATEGORIAS            =
	==================================*/
	getCategorias(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'categorias';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){

			url = url + '/' + id;

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postCategorias(data){

		let url:string = this.APIURL + 'categorias';
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})
		let body = new FormData;
			body.append('nombre', data);
		return this.http.post(url, body, { headers } );
	}

	postCategoriaImagen(file, categoria, imgAnterior){

		let url:string = this.APIURL + 'categorias/' + categoria + '/imagenes';

		console.log(url);

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

			let body = new FormData();
			body.append('imagen', file);
			body.append('imgAnterior', imgAnterior);

			console.log(body);

		return this.http.post(url, body, { headers } );
	}

	postCategoriaFiltros(id, data){
		let url:string = this.APIURL + 'categorias/' + id + '/filtros';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})
		let body = new FormData;
			body.append('filtro', data);
		return this.http.post(url, body, { headers } );
	}

	putCategorias(id, data){
		let url:string = this.APIURL + 'categorias/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteCategoriaFiltros(id, filtro){
		let url:string = this.APIURL + 'categorias/' + id + '/filtros/' + filtro;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})
		return this.http.delete(url, { headers } );
	}

	deleteCategorias(id){
		let url:string = this.APIURL + 'categorias/' + id;
		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})
		return this.http.delete(url, { headers } );
	}
	

	/*===============================
	=            FILTROS            =
	===============================*/
	getFiltros(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'filtros';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postFiltros(data){
		let url:string = this.APIURL + 'filtros';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body.append('nombre', data);

		return this.http.post(url, body, { headers } );
	}

	putFiltros(id, data){
		let url:string = this.APIURL + 'filtros/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteFiltros(id){
		let url:string = this.APIURL + 'filtros/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}

	/*================================
	=            DEPORTES            =
	================================*/
	getDeportes(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'deportes';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postDeportes(data){
		let url:string = this.APIURL + 'deportes';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body.append('nombre', data);

		return this.http.post(url, body, { headers } );
	}

	putDeportes(id, data){
		let url:string = this.APIURL + 'deportes/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteDeportes(id){
		let url:string = this.APIURL + 'deportes/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}


	/*=================================
	=            PRODUCTOS            =
	=================================*/
	getProductos(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'productos';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postProductos(data){
		let url:string = this.APIURL + 'productos';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body.append('nombre', data);

		return this.http.post(url, body, { headers } );
	}

	putProductos(id, data){
		let url:string = this.APIURL + 'productos/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteProductos(id){
		let url:string = this.APIURL + 'productos/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}

	/*=============================
	=            SEDES            =
	=============================*/
	getSedes(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'sedes';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	postSedes(nombre, estado){
		let url:string = this.APIURL + 'sedes';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new FormData;
			body.append('nombre', nombre);
			body.append('estado', estado);

		return this.http.post(url, body, { headers } );
	}

	putSedes(id, data){
		let url:string = this.APIURL + 'sedes/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		let body = new HttpParams;
			body = data;

		return this.http.put(url, body, { headers } );
	}

	deleteSedes(id){
		let url:string = this.APIURL + 'sedes/' + id;

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		return this.http.delete(url, { headers } );
	}

	/*===============================
	=            ESTADOS            =
	===============================*/
	getEstados(id?, filter?, valuefilter?){

		let url:string = this.APIURL + 'estados';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}

	/*==============================
	=            PAISES            =
	==============================*/
	getPaises(id?, filter?, valuefilter?){
		let url:string = this.APIURL + 'paises';

		const headers = new HttpHeaders({
			'pp-apikey': this.APIKEY,
			'pp-token': this.TOKEN
		})

		if(id){
			url = url + '/' + id;
			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
		}else{

			if( filter && valuefilter ){
				let params = new HttpParams();
				params = params.append(filter, valuefilter);
				return this.http.get(url, { headers, params } );
			}else{
				return this.http.get(url, { headers } );
			}
			
		}	
	}




	

	
	
	
	
	
	
	
	
	
	
	
	
}
