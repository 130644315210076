<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<h3><small>DETALLES CATEGORÍA</small></h3>
		</div>
	</div>
	<div class="row mb-2">
		<div class="col-md-12">
			<button class="btn btn-primary" [routerLink]="['/main/configuraciones/categorias']"><i class="fas fa-long-arrow-alt-left"></i> Regresar</button>
			<button class="btn btn-app bg-danger float-right" (click)="eliminarCategoria()">
				<i class="fas fa-times"></i> 
				Eliminar
			</button>
		</div>
	</div>
	<div class="row mb-2">
		<div class="col-md-12">
			
		</div>
	</div>

	<div class="row">
    <div class="col-md-12">
      <div class="callout callout-info">
        <h5>Información!</h5>
        <p>Los campos marcados con <b>*</b> son obligatorios.</p>
      </div>
    </div>
  </div>

	<div class="row">
		<!-- generales -->
		<div class="col-md-6">
			<div class="card card-dark">
				<div class="card-header">DATOS GENERALES</div>

				<!-- loading -->
				<div class="card-body" *ngIf="cargandoCategoria">
					<div class="text-center">
						<br><br>
						<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<br><br>
					</div>
				</div>

				<div class="card-body" *ngIf="!cargandoCategoria">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label>Código *</label>
								<input type="text" [(ngModel)]="idcategoria" class="form-control" placeholder="Código" readonly>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<label>Nombre *  <small>(Max 50 caracteres)</small></label>
								<input type="text" [(ngModel)]="categoriaChanged.nombre" [ngClass]='nombreClass' placeholder="Nombre" maxlength="50" (keyup)="quitarInvalidTag('nombre')">
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label>Estado *</label>
								<select [(ngModel)]="categoriaChanged.estado" class='form-control' style="width: 100%;">
									<option value = 1>Activo</option>
									<option value = 0>Inactivo</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer" *ngIf="!cargandoCategoria">
					<button class="btn btn-primary float-right" (click)="actualizarCategoriaGeneral()">Actualizar</button>
				</div>
			</div>
		</div>
		<!-- imagen -->

		<div class="col-md-6">
			<div class="card">
				<div class="card-header bg-dark">
					DATOS MULTIMEDIA
				</div>
				<!-- loading -->
				<div class="card-body" *ngIf="cargandoCategoria">
					<div class="text-center">
						<br><br>
						<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						<br><br>
					</div>
				</div>
				<div class="card-body" *ngIf="!cargandoCategoria">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<input class="custom-file-input" #file id="file" type="file" accept='image/*' (change)="handleFileInput(file.files)" />
								<label class="custom-file-label" for="file">Choose file</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label for="">Selección</label>
								<img [src]="imgURL" width="100%" *ngIf="imgURL">
							</div>
						<div class="col-md-6">
							<label for="">Imagen actual</label>
							<img [src]="categoriaChanged.imagen" alt="" width="100%">
						</div>
					</div>
				</div>
				<div class="card-footer" *ngIf="!cargandoCategoria">
					<button class="btn btn-primary float-right" (click)="uploadImagen()">Actualizar</button>
				</div>
			</div>
		</div>
		


	</div>

	<!-- FILTROS -->
	<div class="row">
		<div class="col-md-6">
			<div class="card card-dark">
				<div class="card-header">CARACTERÍSTICAS</div>
				<div class="card-body">
					<!-- loading -->
					<div class="card-body" *ngIf="cargandoCategoriaFiltros">
						<div class="text-center">
							<br><br>
							<div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
								<span class="sr-only">Loading...</span>
							</div>
							<br><br>
						</div>
					</div>

					<div class="card-body" *ngIf="!cargandoCategoriaFiltros">
						<div class="row">
							<div class="col-md-12">

								<div class="input-group" *ngIf="cargandoFiltros">
									Cargando opciones
									<div class="spinner-border spinner-border-sm" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>

								<div class="input-group" *ngIf="!cargandoFiltros">
									<select [(ngModel)]="filtroAdd" [(ngClass)]="filtroClass" (change)="quitarInvalidTag('filtro')">
										<option value="0">-- Selecciona una característica --</option>
										<option *ngFor="let f of filtrosOpciones" [value]="f.codigoFiltro">{{f.nombreFiltro}}</option>
									</select>
									<div class="input-group-append">
										<button class="btn btn-success" (click)="agregarFiltro();"><i class="fas fa-plus"></i> Agregar</button>
									</div>
								</div>
							</div>
						</div>

						<div class="row mt-2">
							<div class="col-md-12 table-responsive">
								<div class="alert" *ngIf="filtros.length == 0"><i class="fas fa-exclamation-triangle"></i> No hay información para mostrar</div>
								<table class="table table-striped" *ngIf="filtros.length > 0">
									<thead>
										<tr class="bg-secondary">
											<th>CARACTERÍSTICA</th>
											<th>OPCIONES</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let f of filtros">
											<td>{{f.filtro}}</td>
											<td><button class="btn btn-danger btn-sm" title="eliminar" (click)="eliminarFiltro(f.idfiltro)"><i class="fas fa-times"></i></button></td>
										</tr>
									</tbody>
								</table>
							</div>
							
						</div>	
					</div>
				</div>
				<div class="card-footer">
				</div>
			</div>
		</div>
	</div>

</div>